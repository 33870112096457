import {MaterialType, MaterialStorage} from '@hconnect/common/types'

import {useSettingsQuery} from '../../../../modules/common/providers/ApiInterface'
import {useUrlParam} from '../../../../modules/routing'

const selector = (materialType: MaterialType) => (data: MaterialStorage[]) =>
  data.filter((storage) => storage.materialType === materialType)

export const useMaterialStorageListQuery = (materialType?: MaterialType) => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('materialStorage', [plantCode], {
    select: materialType ? selector(materialType) : undefined
  })
}
