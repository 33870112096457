import {NumericTextField} from '@hconnect/common/components/forms/NumericTextField'
import type {AssetPayload, AssetResponse, AssetType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {TextField, Stack} from '@mui/material'
import {FC} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {Switch} from '../../../common/components'
import {requiredValidator} from '../../../common/utils'
import {PLANT_ROUTES, getUrl, useUrlParam} from '../../../routing'
import {useAddAsset} from '../../hooks'

interface AssetFormProps {
  assetType: AssetType
}

export const NewAssetForm: FC<AssetFormProps> = ({assetType}) => {
  const navigate = useNavigate()
  const plantCode = useUrlParam('plantCode')

  const {mutateAsync: addAsset} = useAddAsset()
  const {t} = useTranslation()

  const initialValues: AssetPayload = {
    name: '',
    type: assetType
  }

  const {handleSubmit, control, reset} = useForm<AssetResponse>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: initialValues
  })

  const submit = handleSubmit(async ({name, type}) => {
    const createdAsset = await addAsset({plantCode, name, type}, {onError: () => reset()})
    return navigate(
      getUrl(PLANT_ROUTES.ASSETS.ASSET_TYPE.ID.ASSET_ID.path, {
        plantCode,
        assetType: createdAsset.type,
        assetId: String(createdAsset.id)
      })
    )
  })

  return (
    <Stack spacing={2} {...dataTestId('add_edit_asset_form')}>
      <Controller
        control={control}
        name="name"
        rules={requiredValidator(t)}
        render={({field: {ref, value, onChange}, fieldState: {error}}) => (
          <TextField
            label={t('assetsSettings.assetName')}
            sx={{width: ({spacing}) => spacing(50)}}
            value={value}
            placeholder={value}
            inputRef={ref}
            onChange={onChange}
            helperText={error?.message}
            error={Boolean(error?.message)}
            onBlur={() => void submit()}
            onKeyDown={({key}) => {
              if (key === 'Enter') void submit()
            }}
            {...dataTestId('asset_name_input')}
          />
        )}
      />
      {/* Minimum downtime read only default value for UX purposes to not have jumpy forms*/}
      <NumericTextField
        label={t('assetsSettings.minimumDowntime')}
        sx={{width: ({spacing}) => spacing(50)}}
        value={0}
        disabled
        inputProps={{step: '1', min: '0', max: '1000'}}
        {...dataTestId('asset_minimum_downtime')}
      />

      {/* isOptimized on asset level needed read only default value for UX purposes*/}
      <Switch
        value={false}
        label={t('assetsSettings.optimized')}
        disabled
        {...dataTestId('is_optimized_asset_switch')}
      />
      {/* shutdown availability will be set by BE to true on asset creation*/}
      <Switch
        value={true}
        label={t('assetsSettings.shutdownAvailability')}
        disabled
        {...dataTestId('is_shutdown_available_switch')}
      />
    </Stack>
  )
}
