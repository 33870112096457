import {dataTestId, useTranslation} from '@hconnect/uikit'
import {Check as CheckIcon} from '@mui/icons-material'
import {Box, Button, Typography} from '@mui/material'

import {SaveButtonState} from '../../enums'

export const DailyEntryHeader = ({
  selectedDate,
  onClick,
  saveButtonState
}: {
  selectedDate: string | undefined
  onClick?: () => void
  saveButtonState: SaveButtonState
}) => {
  const {t} = useTranslation()

  const color = saveButtonState === SaveButtonState.Saved ? 'success' : 'primary'
  const text =
    saveButtonState === SaveButtonState.Saved
      ? t('janusConfig.dailyEntry.saved')
      : t('janusConfig.dailyEntry.save')

  const testId = `daily-entry-save-button-${saveButtonState}`

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      {...dataTestId('selected-date-container')}
    >
      <Typography variant="h3">{selectedDate}</Typography>
      {saveButtonState !== SaveButtonState.Hidden && (
        <Button
          sx={{
            '&.Mui-disabled': {
              backgroundColor: 'rgba(1, 106, 212, 0.4)',
              color: 'white',
              '.MuiButton-startIcon': {
                color: 'red !important'
              },
              '.MuiButton-iconSizeMedium': {
                color: 'red !important'
              }
            }
          }}
          color={color}
          startIcon={<CheckIcon />}
          variant="contained"
          {...dataTestId(testId)}
          onClick={onClick}
          disabled={saveButtonState === SaveButtonState.Disabled}
        >
          {text}
        </Button>
      )}
    </Box>
  )
}
