import {dataTestId} from '@hconnect/uikit'
import {SortDirection, TableHead, TableRow} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {SamplingPointsSettingsTableHeaderCell} from './SamplingPointsSettingsTableHeaderCell/SamplingPointsSettingsTableHeaderCell'

interface SamplingPointsSettingsTableHeaderProps {
  orderDirection: SortDirection
  setOrderDirection: React.Dispatch<React.SetStateAction<SortDirection>>
}

export const SamplingPointsSettingsTableHeader: React.FC<
  SamplingPointsSettingsTableHeaderProps
> = ({orderDirection, setOrderDirection}) => {
  const {t} = useTranslation()
  return (
    <TableHead {...dataTestId('sampling_points_table_header')}>
      <TableRow>
        <SamplingPointsSettingsTableHeaderCell
          orderDirection={orderDirection}
          onClick={() =>
            setOrderDirection((prevOrderDirection) =>
              prevOrderDirection === 'asc' ? 'desc' : 'asc'
            )
          }
          {...dataTestId('sampling_points_table_header_code_column')}
        >
          {t('samplingPointsSettings.samplingPointCode')}
        </SamplingPointsSettingsTableHeaderCell>
        <SamplingPointsSettingsTableHeaderCell
          {...dataTestId('sampling_points_table_header_type_column')}
        >
          {t('samplingPointsSettings.samplingPointType')}
        </SamplingPointsSettingsTableHeaderCell>
        <SamplingPointsSettingsTableHeaderCell
          {...dataTestId('sampling_points_table_header_equipment_column')}
        >
          {t('samplingPointsSettings.samplingPointEquipment')}
        </SamplingPointsSettingsTableHeaderCell>
      </TableRow>
    </TableHead>
  )
}
