import type {PageProps} from '@hconnect/uikit/src/lib2'
import {Page, mergeSx} from '@hconnect/uikit/src/lib2'
import React from 'react'

type PageLayoutProps = Partial<
  Pick<
    PageProps,
    | 'title'
    | 'headerActionContent'
    | 'nameHeaderSx'
    | 'sxActionContent'
    | 'aboveTitle'
    | 'sx'
    | 'data-test-id'
    | 'children'
  >
>

/**
 * Wrapper around the uikit page component that takes care of some default props
 * @param children
 * @param props
 */
export const PageLayout = ({nameHeaderSx, children, ...props}: PageLayoutProps) => {
  return (
    <Page
      sx={{
        px: {
          xs: 2,
          lg: 4
        }
      }}
      boxed={false}
      nameHeaderSx={mergeSx({marginBottom: '12px'}, nameHeaderSx)}
      {...props}
    >
      {children}
    </Page>
  )
}
PageLayout.displayName = 'PageLayout'
