import {Grid} from '@mui/material'
import React from 'react'

interface LayoutSettingsProps {
  list: JSX.Element
  details: JSX.Element
  dataTestId?: string
}

export const LayoutSettings: React.FC<LayoutSettingsProps> = ({list, details, dataTestId}) => {
  return (
    <Grid container spacing={2} data-test-id={dataTestId}>
      <Grid item xs={12} sm={4}>
        {list}
      </Grid>
      <Grid item xs={12} sm={8}>
        {details}
      </Grid>
    </Grid>
  )
}
