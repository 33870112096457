import {Status} from '@hconnect/common/types'
import {Card} from '@hconnect/uikit/src/lib2'
import {Skeleton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../../permissions'
import {useGlobalMaterialsQuery} from '../../hooks'
import {SelectedMaterial} from '../../types'

import {MaterialForm} from './MaterialForm'

import {useAssetsQuery} from '@settings/modules/assets'
import {useScheduleQuery} from '@settings/modules/common/hooks'
import {useFeatureFlag} from '@settings/modules/common/hooks/useFeatureFlag'

interface MaterialCardProps {
  selectedMaterial: SelectedMaterial
}

export const MaterialCard: React.FC<MaterialCardProps> = ({selectedMaterial}) => {
  const isMaterialToGlobalMaterialMappingInputEnabled = useFeatureFlag(
    'materialToGlobalMaterialMappingInput'
  )
  const {t} = useTranslation()
  const canChangeMaterials = usePermission('CHANGE_MATERIALS')
  const {data: schedule} = useScheduleQuery()
  const {data: assets} = useAssetsQuery()
  let {data: globalMaterialOptions} = useGlobalMaterialsQuery(selectedMaterial.type)
  if (!isMaterialToGlobalMaterialMappingInputEnabled) {
    globalMaterialOptions = [{id: '0', label: 'mock'}]
  }

  return (
    <Card headerContent={t('materialsSettings.material')}>
      {assets && schedule && globalMaterialOptions ? (
        <MaterialForm
          selectedMaterial={selectedMaterial}
          key={selectedMaterial.status === Status.New ? 'new' : selectedMaterial.id}
          isReadOnly={!canChangeMaterials}
          schedule={schedule}
          assets={assets}
          globalMaterialOptions={globalMaterialOptions}
        />
      ) : (
        <Skeleton variant="rectangular" height={96} />
      )}
    </Card>
  )
}
