import {dataTestId} from '@hconnect/uikit'
import {
  Check as CheckIcon,
  ErrorOutlined as ErrorOutlinedIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon
} from '@mui/icons-material'
import {Box, Typography} from '@mui/material'
import Button from '@mui/material/Button'
import {styled} from '@mui/material/styles'
import {isWeekend} from 'date-fns'
import moment from 'moment'
import React from 'react'

import {Status} from '../../enums'
import {DayInfo} from '../../types'

const CustomButton = styled(Button)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minWidth: '20px',
  marginX: 0,
  color: 'white',
  overflow: 'hidden',
  border: 'none'
})

const disableDate = (date: string) => moment(date).isSameOrAfter(moment().subtract(1, 'day'))

const isKpiApproved = (date: string) => {
  const inputDate = moment(date)
  const yesterday = moment().subtract(1, 'day').startOf('day')
  if (inputDate.isBefore(yesterday)) {
    return true
  }
  return moment().hour() >= 10
}

export const CalendarDayItem = ({
  day,
  text,
  isSelected,
  onClick
}: {
  day: DayInfo
  isSelected: boolean
  onClick: (day: DayInfo) => void
  text: string
}) => {
  const disabled = disableDate(day.date)
  const showIcon = day.status !== Status.Empty
  const showSuccessIcon =
    day.status === Status.Approved || (day.status === Status.Saved && isKpiApproved(day.date))

  const showErrorIcon =
    day.status === Status.Error || day.status === Status.Ok || day.status === Status.PartiallySaved

  const Icon = showErrorIcon ? (
    <ErrorOutlinedIcon
      sx={{transform: 'scale(0.5)', color: '#E5534D'}}
      {...dataTestId('day-item-error-icon')}
    />
  ) : showSuccessIcon ? (
    <CheckIcon
      color="success"
      sx={{transform: 'scale(0.5)'}}
      {...dataTestId('day-item-success-icon')}
    />
  ) : (
    <RadioButtonUncheckedIcon
      color="info"
      sx={{transform: 'scale(0.5)'}}
      {...dataTestId('day-item-unchecked-icon')}
    />
  )

  return (
    <CustomButton
      variant="text"
      onClick={() => onClick(day)}
      value={day.date}
      disabled={disabled}
      aria-label={text}
      {...dataTestId(`day-calendar-item-${text}`)}
      sx={{
        margin: 0.3,
        backgroundColor: (theme) => (isSelected ? theme.palette.primary.main : 'transparent'),
        '&:hover': {
          backgroundColor: (theme) => theme.palette.primary.main
        },
        '&.Mui-disabled': {
          color: 'rgba(255,255,255,0.25)'
        },
        color: isWeekend(new Date(day.date)) ? 'rgba(255,255,255,0.60)' : 'white'
      }}
    >
      <Typography variant="body2">{text}</Typography>
      <Box position="absolute" sx={{marginTop: (theme) => theme.spacing(4)}}>
        {showIcon && Icon}
      </Box>
    </CustomButton>
  )
}
