import type {AssetResponse, OperationModeResponse} from '@hconnect/common/types'
import {AssetType} from '@hconnect/common/types'
import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../api/mutations'
import {
  getSettingsQueryData,
  invalidateSettingsQuery,
  setSettingsQueryData
} from '../../../common/providers/ApiInterface'
import type {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'

const modifyOperationModeInAsset = (
  updatedOperationMode: OperationModeResponse,
  assetToUpdate: AssetResponse
) => {
  assetToUpdate.operationModes = assetToUpdate.operationModes.map((operationMode) =>
    operationMode.id === updatedOperationMode.id ? updatedOperationMode : operationMode
  )
  return assetToUpdate
}

export const useEditOperationMode = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<
    OperationModeResponse,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.editOperationMode>[0]
  >(mutations.editOperationMode, {
    onError: (error) => {
      notifyIfErrorMessage(
        error.response?.data.detail ?? error.response?.data.message,
        raiseError,
        notify
      )
    },
    onSuccess: (editedOperationMode, variables) => {
      notify('success', t('success.editOperationMode'))
      const prevAssets = getSettingsQueryData('assets', [variables.plantCode])
      if (!prevAssets) throw new Error('Assets should be loaded to query before using this hook')
      const assetToUpdate = prevAssets.find((asset) => asset.id === variables.assetId)
      if (!assetToUpdate) throw new Error('BUG: updated asset should exist in asset query')
      const updatedAsset = modifyOperationModeInAsset(editedOperationMode, assetToUpdate)
      setSettingsQueryData(
        'assets',
        [variables.plantCode],
        prevAssets.map((asset) => (asset.id === updatedAsset.id ? updatedAsset : asset))
      )
      // invalidating switch matrix query if asset is cement type
      if (updatedAsset.type === AssetType.CementMill) {
        invalidateSettingsQuery('assetsTransitions', variables.plantCode)
      }
    }
  })
}
