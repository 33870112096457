import {useNotification} from '@hconnect/uikit/src/common'
import type {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../../api/mutations'
import {
  getSettingsQueryData,
  invalidateSettingsQuery,
  setSettingsQueryData
} from '../../../../common/providers/ApiInterface'
import {notifyIfErrorMessage} from '../../../../common/utils/errorHandling'
import type {Product, Material} from '../../../types'

const removeProductFromMaterial = <T extends Material>(material: T, productId: Product['id']) => {
  material.products = material.products.filter((product) => product.id !== productId)
  return material
}

export const useDeleteMaterialProductLink = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<
    void,
    AxiosError<{message: string}>,
    Parameters<typeof mutations.deleteMaterialProductLink>[0]
  >(mutations.deleteMaterialProductLink, {
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.message, raiseError, notify)
    },
    onSuccess: (data, variables) => {
      notify('success', t('success.deleteProduct'))
      const prevMaterials = getSettingsQueryData('materials', [variables.plantCode])
      const prevMaterialWithRecipe = getSettingsQueryData('materialWithRecipes', [
        variables.plantCode,
        variables.materialId
      ])
      if (prevMaterials && prevMaterialWithRecipe) {
        // since we have two queries which could contain removed products, we should update them both
        setSettingsQueryData(
          'materials',
          [variables.plantCode],
          prevMaterials.map((material) => {
            if (material.id === variables.materialId) {
              return removeProductFromMaterial(material, variables.productId)
            }
            return material
          })
        )
        setSettingsQueryData(
          'materialWithRecipes',
          [variables.plantCode, variables.materialId],
          removeProductFromMaterial(prevMaterialWithRecipe, variables.productId)
        )
      } else {
        invalidateSettingsQuery('materials', variables.plantCode)
        invalidateSettingsQuery('materialWithRecipes', variables.plantCode, variables.materialId)
      }
    }
  })
}
