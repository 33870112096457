import {SamplingPoint} from '../../types'

import {Api} from '@settings/api'

export const SAMPLING_POINTS_QUERY_KEY = 'samplingPoints'

export async function samplingPoints(plantCode: string): Promise<SamplingPoint[]> {
  const path = `/plants/${plantCode}/materials/sampling-points`
  const response = await Api.axiosInstance.get<SamplingPoint[]>(path)
  return response.data
}
