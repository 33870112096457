import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {SAMPLING_POINTS_QUERY_KEY} from '../../api/queries/getSamplingPoints'

import {mutations} from '@settings/api/mutations'
import {invalidateSettingsQuery} from '@settings/modules/common/providers'
import {ErrorResponse} from '@settings/modules/common/types'
import {notifyIfErrorMessage} from '@settings/modules/common/utils'

type updateSamplingPointParams = Parameters<typeof mutations.updateSamplingPoint>[0]

export function useUpdateSamplingPointMutation() {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<void, AxiosError<ErrorResponse>, updateSamplingPointParams>(
    mutations.updateSamplingPoint,
    {
      // TODO: maybe we need to invalidate queries only once in a onSubmit() of save button?
      onSuccess: (_, variables) => {
        notify('success', t('samplingPointsSettings.editSamplingPointSuccess'))
        invalidateSettingsQuery(SAMPLING_POINTS_QUERY_KEY, variables.plantCode)
      },
      onError: (error) => {
        const errorDetails = error?.response?.data.detail ?? error.message ?? error.response?.status
        notifyIfErrorMessage(
          t('samplingPointsSettings.mutations.updateSamplingPointError', {
            details: errorDetails
          }),
          raiseError,
          notify
        )
      }
    }
  )
}
