import {AutocompleteOption} from '../../common/types'

import {UpmEntity} from './common'

export type ThresholdStatus = 'warning' | 'alarm' | '' | null

export type Unit = {
  id: string
  name: string
  timeContext?: string
}

export enum SignalSource {
  PXTREND = 'PxTrend',
  DALOG = 'Dalog',
  MACHINE_SENTRY = 'MachineSentry',
  SCHAEFFLER = 'Schaeffler'
}

export enum SignalDataType {
  RAW = 'Raw',
  AGGREGATED = 'Aggregated'
}

export type SignalDetail = {
  id: string
  typeId: string
  unitId: string
  equipmentId: string
  localName: string | null
  source: SignalSource | null
  dataType: SignalDataType | null
  name: string | null
  description: string | null
  thresholds: {
    highHigh: number | string | null
    high: number | string | null
    low: number | string | null
    lowLow: number | string | null
    highHighStatus: ThresholdStatus
    highStatus: ThresholdStatus
    lowStatus: ThresholdStatus
    lowLowStatus: ThresholdStatus
  }
  customThresholds: {
    high: number | string | null
    low: number | string | null
    highStatus: ThresholdStatus
    lowStatus: ThresholdStatus
  }
  modelConfidence: {
    value: number | string | null
    status: ThresholdStatus
  }
  timeToBreach: {
    highHigh: number | string | null
    high: number | string | null
    low: number | string | null
    lowLow: number | string | null
  }
}

export type SignalListData = SignalDetail & {
  area: UpmEntity
  department: UpmEntity
  productionLine: UpmEntity
  equipment: UpmEntity
}

export type SignalFormData = Omit<SignalDetail, 'unitId' | 'equipmentId' | 'typeId' | 'id'> & {
  unit: AutocompleteOption | null
  equipment: (AutocompleteOption & {path: string}) | null
  type: AutocompleteOption | null
}
