import {dataTestId} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {InfoOutlined} from '@mui/icons-material'
import {isEmpty, isUndefined} from 'lodash'
import {useTranslation} from 'react-i18next'

import {MeasurementTypesCard} from '../components/MeasurementTypesCard'
import {useExtendedMeasurementTypesCategories} from '../hooks/useExtendedMeasurementTypesCategories'
import {useMeasurementTypesCategoriesListItems} from '../hooks/useMeasurementTypesCategoriesListItems'

import {
  LayoutSettings,
  PageLayout,
  SettingsHelmet,
  SettingsList,
  Skeleton
} from '@settings/modules/common/components'
import {RouteName} from '@settings/modules/routing'

export function PageMeasurementTypesSettings(): JSX.Element {
  const {t} = useTranslation()

  const {
    extendedMeasurementTypesCategories,
    unmappedLimsMeasurementTypes,
    isPageLoading,
    limsMeasurementTypes
  } = useExtendedMeasurementTypesCategories()

  const {measurementTypesCategoriesListItems, selectedMeasurementTypesCategory} =
    useMeasurementTypesCategoriesListItems(extendedMeasurementTypesCategories)

  return (
    <PageLayout
      title={t('measurementsSettings.qualityMeasurements')}
      {...dataTestId(`page_${RouteName.MEASUREMENTS}`)}
    >
      <SettingsHelmet title={t('measurementsSettings.pageTitle')} />
      {isPageLoading ? (
        <Skeleton height="35vh" />
      ) : isEmpty(limsMeasurementTypes) ? (
        <CardBox sx={{textAlign: 'center'}}>
          <InfoOutlined
            sx={{
              mr: 1,
              verticalAlign: 'middle'
            }}
          />
          {t('measurementsSettings.noLimsMeasurementTypes')}
        </CardBox>
      ) : (
        <LayoutSettings
          list={
            <SettingsList
              items={measurementTypesCategoriesListItems}
              testId="measurement_types_categories_list"
            />
          }
          details={
            !isUndefined(selectedMeasurementTypesCategory) ? (
              <MeasurementTypesCard
                selectedMeasurementTypesCategory={selectedMeasurementTypesCategory}
                unmappedLimsMeasurementTypes={unmappedLimsMeasurementTypes}
              />
            ) : (
              <></>
            )
          }
        />
      )}
    </PageLayout>
  )
}
