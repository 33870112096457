import React from 'react'
import {Outlet} from 'react-router-dom'

import {usePermissionsQuery} from '../../permissions/hooks'
import {SettingsToursProvider} from '../providers/SettingsToursProvider'

import {DefaultShell} from './DefaultShell'
import {PageSkeleton} from './skeletons/PageSkeleton'

export const PagePlant: React.VFC = () => {
  const {data: permissions} = usePermissionsQuery()

  return permissions ? (
    <SettingsToursProvider>
      <DefaultShell>
        <Outlet />
      </DefaultShell>
    </SettingsToursProvider>
  ) : (
    <PageSkeleton />
  )
}
