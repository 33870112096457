import moment from 'moment'

export const calculateWeekRange = (date?: string) => {
  const inputDate = moment(date)
  const startOfWeek = inputDate.clone().startOf('isoWeek')
  const endOfWeek = inputDate.clone().endOf('isoWeek')

  const startFormat = startOfWeek.format('DD.MM')
  const endFormat = endOfWeek.format('DD.MM')
  const startYearFormat = startOfWeek.format('DD.MM.YYYY')
  const endYearFormat = endOfWeek.format('DD.MM.YYYY')

  const result =
    startOfWeek.year() === endOfWeek.year()
      ? `${startFormat} - ${endFormat}.${startOfWeek.year()}`
      : `${startYearFormat} - ${endYearFormat}`

  return result
}
