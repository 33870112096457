import {dataTestId} from '@hconnect/uikit'
import {Box, Typography} from '@mui/material'
import moment from 'moment-timezone'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useLatestData} from '../../../../common/hooks/useLatestData'
import {AssetStandardOperationTimeOccurences} from '../../../types'

type NextOccurencesPreviewProps = {
  occurrences: AssetStandardOperationTimeOccurences
  testId?: string
}

export const NextScheduledItemsOccurencesPreview: React.FC<NextOccurencesPreviewProps> = ({
  occurrences,
  testId = 'next-occurrences-preview'
}) => {
  const {t} = useTranslation()
  const {timezone_id} = useLatestData()

  const {hasMore, scheduleItems} = occurrences

  const occurenceTimes = useMemo(
    () => scheduleItems.map((item) => moment.tz(item.start, timezone_id)) || [],
    [scheduleItems, timezone_id]
  )

  if (occurenceTimes.length === 0) {
    return (
      <Typography color="error" variant="caption" {...dataTestId(testId)}>
        {t('assetsSettings.standardOperationTimes.noUpcomingOccurrences')}
      </Typography>
    )
  }

  const visibleOccurences = occurenceTimes.map((occurence) => occurence.format('LLL'))
  const displayItems = [...visibleOccurences, hasMore ? '...' : undefined].filter((e) => e)
  return (
    <Typography variant="caption" {...dataTestId(testId)}>
      {t('assetsSettings.standardOperationTimes.nextOccurrences')}
      {displayItems.map((e, i) => (
        <React.Fragment key={i}>
          {i === 0 ? ' ' : ', '}
          <Box sx={{display: 'inline-block'}}>{e}</Box>
        </React.Fragment>
      ))}
    </Typography>
  )
}
