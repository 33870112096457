import {useQuery} from 'react-query'

import {Api} from '../../../../api'
import {DepartmentType} from '../../../upm/types/common'

export const QueryKey = 'department-types'

export const useGetDepartmentTypes = () => {
  return useQuery(
    [QueryKey],
    async () => {
      const path = 'upm/department-type'
      return (await Api.axiosInstance.get<DepartmentType[]>(path)).data
    },
    {
      staleTime: Infinity,
      select: (departmentTypes) =>
        // Hardcoded filter for now, just to show some options
        departmentTypes.filter((type) => type.name === 'Kiln' || type.name.endsWith('Mill'))
    }
  )
}
