import {MaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {LayoutSettings, PageLayout, SettingsHelmet, SettingsSkeleton} from '../../common/components'
import {useLatestQuery} from '../../common/hooks/queries/useLatestQuery'
import {RouteName, useSearchParam} from '../../routing'
import {useMaterialsQuery} from '../hooks'

import {MaterialList} from './list'
import {MaterialDetails} from './MaterialDetails'
import {SelectedMaterialProvider} from './SelectedMaterialProvider'

export const PageMaterialSettings: React.VFC = () => {
  const {t} = useTranslation()
  const {data: materials} = useMaterialsQuery()
  const {data: latestData} = useLatestQuery()

  const [currentMaterialType, setInitialMaterialType] = useSearchParam('materialType', false)

  useEffect(() => {
    if (currentMaterialType) return
    setInitialMaterialType(MaterialType.Cement)
  }, [setInitialMaterialType, currentMaterialType])

  if (!materials || !currentMaterialType || !latestData) {
    return <SettingsSkeleton />
  }

  return (
    <PageLayout
      title={t('materialsSettings.materials')}
      {...dataTestId(`page_${RouteName.MATERIALS_SETTINGS}`)}
    >
      <SettingsHelmet title={t('materialsSettings.pageTitle')} />
      <SelectedMaterialProvider materials={materials}>
        <LayoutSettings list={<MaterialList />} details={<MaterialDetails />} />
      </SelectedMaterialProvider>
    </PageLayout>
  )
}
