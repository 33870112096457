import {AssetType} from '@hconnect/common/types'
import {Navigate} from 'react-router-dom'

import {useAssetsQuery} from '../hooks'

import {PLANT_ROUTES, getUrl, useUrlParam} from '@settings/modules/routing'

export const DefaultAssetIdRedirect: React.FC = () => {
  const plantCode = useUrlParam('plantCode')
  const assetType = useUrlParam('assetType')

  const {data: assets} = useAssetsQuery({sorted: true, types: [assetType as AssetType]})

  if (!assets) {
    throw new Error('BUG: Assets should be loaded before mounting this component')
  }

  const firstAsset = assets.at(0)

  if (!firstAsset) {
    // TODO HCP-75935 create no assets component
    return <>No asset found for this asset type</>
  }

  return (
    <Navigate
      to={getUrl(PLANT_ROUTES.ASSETS.ASSET_TYPE.ID.ASSET_ID.path, {
        plantCode,
        assetType,
        assetId: String(firstAsset.id)
      })}
      replace
    />
  )
}
