import type {AssetResponse, OperationModeResponse} from '@hconnect/common/types'
import type {AlertProps} from '@hconnect/uikit/src/lib2'
import {compact} from 'lodash'
import React from 'react'
import {Trans} from 'react-i18next'

import {InfoGroup} from '../../../common/components/infoGroup'
import type {ScheduleItem} from '../../../common/types'

interface AssetDeleteInfoProps {
  scheduleItems: ScheduleItem[]
  asset: AssetResponse
  operationModes: OperationModeResponse[]
}

function cutStringTo40Chars(str: string) {
  return str.length <= 40 ? str : `${str.substring(0, 37)}...`
}

export const AssetDeleteInfo: React.FC<AssetDeleteInfoProps> = ({
  scheduleItems,
  asset,
  operationModes
}) => {
  const operationModesStr = cutStringTo40Chars(operationModes.map((mode) => mode.name).join(', '))

  const infoPanels: AlertProps[] = compact([
    operationModes.length > 0 && {
      accentColor: 'warning',
      content: (
        <Trans
          i18nKey="assetsSettings.deleteAssetAssignedOperationModes"
          values={{
            operation_mode_count: operationModes.length,
            operation_modes: operationModesStr
          }}
        />
      )
    },
    scheduleItems.length > 0 && {
      accentColor: 'warning',
      content: (
        <Trans
          i18nKey="assetsSettings.deleteOperationModesAssignedScheduleItems"
          values={{
            number: scheduleItems.length
          }}
        />
      )
    }
  ])

  return (
    <InfoGroup
      description={
        <Trans i18nKey="assetsSettings.deleteAssetConfirmation" values={{name: asset.name}} />
      }
      panels={infoPanels}
    />
  )
}
