import type {AssetResponse} from '@hconnect/common/types'

import {useSettingsQuery} from '../../../common/providers'
import {useUrlParam} from '../../../routing'

export const useAssetsByIdQuery = () => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('assets', [plantCode], {
    select: (assets) =>
      assets.reduce<Record<string, AssetResponse>>(
        (assetsById, asset) => ({...assetsById, [asset.id]: asset}),
        {}
      )
  })
}
