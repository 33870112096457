import {dataTestId} from '@hconnect/uikit'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Outlet} from 'react-router-dom'

import {PageLayout, SettingsSkeleton, SettingsHelmet} from '../../common/components'
import {useLatestQuery} from '../../common/hooks/queries/useLatestQuery'
import {RouteName} from '../../routing'
import {useAssetsQuery} from '../hooks'

export const PageAssetsSettings: React.FC = () => {
  const {t} = useTranslation()
  const {data: assets} = useAssetsQuery()
  const {data: latestData} = useLatestQuery()

  if (!assets || !latestData) {
    return <SettingsSkeleton />
  }

  return (
    <PageLayout
      title={t('assetsSettings.assets')}
      {...dataTestId(`page_${RouteName.ASSETS_SETTINGS}`)}
    >
      <SettingsHelmet title={t('assetsSettings.pageTitle')} />
      <Outlet />
    </PageLayout>
  )
}
