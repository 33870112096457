import {Stack} from '@mui/system'
import React from 'react'

import {DatamartCard, EmailParserCard} from './dataConnection'
import {useSelectedStorage} from './SelectedStorageProvider'
import {StorageCard} from './StorageCard'

export const StorageDetails = () => {
  const {selectedStorage} = useSelectedStorage()
  if (!selectedStorage) {
    return null
  }

  return (
    <Stack spacing={2} id="storage-details">
      <StorageCard />
      <DatamartCard />
      <EmailParserCard />
    </Stack>
  )
}
