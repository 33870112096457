import {CardBox} from '@hconnect/uikit/src/lib2'
import {
  MenuItem,
  Stack,
  TextField,
  ListItemText,
  List,
  ListItemButton,
  Divider,
  Alert,
  Skeleton
} from '@mui/material'
import React, {useMemo, useState} from 'react'
import {useSearchParams} from 'react-router-dom'

import {findEntityPath} from '../../../common/helpers'
import {useGetPlantStructure} from '../../../common/hooks/useGetPlantStructure'

import {useGetDepartments} from './useGetDepartments'
import {useGetDepartmentTypes} from './useGetDepartmentTypes'

import {
  getEntityPathString,
  getAllEntitiesOfType
} from '@settings/modules/upm/helpers/PlantStructure.helpers'

export const AssetList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedTypeId, setSelectedTypeId] = useState<string>(searchParams.get('assetType') || '')
  const selectedAssetId = searchParams.get('id')

  const {data: departmentTypes, isLoading: typesLoading} = useGetDepartmentTypes()
  const {data: plantStructure, isLoading: structureLoading} = useGetPlantStructure()

  const allDepartments = useMemo(
    () => getAllEntitiesOfType(plantStructure, 'Department'),
    [plantStructure]
  )

  // we need to fetch also details to get the type of department - structure endpoint should be extended
  // in the future so we dont have to trigger so many calls, especially when we have a lot of departments
  const departmentDetailQueries = useGetDepartments(
    allDepartments.map((department) => department.id)
  )

  const relevantDepartmentIds = useMemo(
    () =>
      departmentDetailQueries
        .filter((queryResult) => queryResult.data?.typeId === selectedTypeId)
        .map((queryResult) => queryResult.data?.id),
    [departmentDetailQueries, selectedTypeId]
  )

  // we have to filter original departments again because detail does not contain the name
  const filteredDepartments = useMemo(
    () => allDepartments.filter((department) => relevantDepartmentIds.includes(department.id)),
    [allDepartments, relevantDepartmentIds]
  )

  const departmentQueriesLoading = departmentDetailQueries.some((query) => query.isLoading)
  const isLoading = typesLoading || structureLoading || departmentQueriesLoading

  return (
    <CardBox display="flex" flexDirection="column" px={0} minWidth="350px" height="fit-content">
      <Stack direction="column" px={2}>
        {isLoading && <Skeleton variant="rounded" height={48} width={'100%'} />}
        {!isLoading && (
          <TextField
            select
            label="Asset Type"
            value={selectedTypeId}
            onChange={(e) => setSelectedTypeId(e.target.value)}
            variant="filled"
            sx={{maxWidth: '350px'}}
            SelectProps={{
              sx: {
                maxHeight: '48px',
                background: '#FFFFFF',
                borderRadius: '4px',
                '&.Mui-focused': {
                  background: '#fbfbfc'
                }
              }
            }}
          >
            {departmentTypes?.map((departmentType) => (
              <MenuItem key={departmentType.id} value={departmentType.id}>
                {departmentType.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Stack>
      <List>
        {filteredDepartments.length === 0 && !isLoading && selectedTypeId && (
          <ListItemButton sx={{pointerEvents: 'none'}}>
            <ListItemText primary={<Alert severity="error">No matching assets found</Alert>} />
          </ListItemButton>
        )}
        {plantStructure &&
          filteredDepartments.map((department) => {
            const {path} = findEntityPath(plantStructure, department.id, 'Department')
            return (
              <React.Fragment key={department.id}>
                <ListItemButton
                  sx={{gap: 0}}
                  onClick={() => setSearchParams({id: department.id, assetType: selectedTypeId})}
                  selected={selectedAssetId === department.id}
                >
                  <ListItemText
                    primary={department.name}
                    secondary={getEntityPathString(path.slice(0, -1))}
                  />
                </ListItemButton>
                <Divider variant="middle" />
              </React.Fragment>
            )
          })}
      </List>
    </CardBox>
  )
}
