import {useGetEquipment} from '../api/useGetEquipment'
import {useGetSignalTypes} from '../api/useGetSignalTypes'
import {useGetUnits} from '../api/useGetUnits'

export const useSignalOptions = () => {
  const {data: units, isLoading: unitsLoading} = useGetUnits()
  const {data: equipment, isLoading: equipmentLoading} = useGetEquipment()
  const {data: signalTypes, isLoading: signalTypesLoading} = useGetSignalTypes()

  const optionsLoading = unitsLoading || equipmentLoading || signalTypesLoading

  return {units, equipment, signalTypes, optionsLoading}
}
