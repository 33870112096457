import {dataTestId} from '@hconnect/uikit'
import {Check} from '@mui/icons-material'
import {Box, Button, Stack} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../../permissions'
import {
  LimsSamplingPoint,
  AutocompleteOption,
  SamplingPointTypesAutocompleteOption
} from '../../types'
import {SamplingPointsSettingsTable} from '../SamplingPointsSettingsTable/SamplingPointsSettingsTable'

interface SamplingPointsFormsProps {
  limsSamplingPoints: LimsSamplingPoint[]
  samplingPointTypeOptions: SamplingPointTypesAutocompleteOption[]
  assetOptions: AutocompleteOption[]
  storageOptions: AutocompleteOption[]
}

export const SamplingPointsSettingsContent: React.FC<SamplingPointsFormsProps> = ({
  limsSamplingPoints,
  samplingPointTypeOptions,
  assetOptions,
  storageOptions
}) => {
  const {t} = useTranslation()
  const canChangeSamplingPoints = usePermission('CHANGE_MATERIALS')
  const [areSamplingPointsBeingProcessed, setAreSamplingPointsBeingProcessed] = useState(false)

  const handleSaveButton = () => {
    setAreSamplingPointsBeingProcessed(true)
    let processedForms = 0
    limsSamplingPoints.map((limsSamplingPoint) => {
      if (limsSamplingPoint.limsSamplingPointRef.current)
        limsSamplingPoint.limsSamplingPointRef.current
          .mapLimsSamplingPoint()
          .then(() => processedForms++)
          .catch(() => processedForms++)
          .finally(() => {
            if (processedForms === limsSamplingPoints.length)
              setAreSamplingPointsBeingProcessed(false)
          })
    })
  }
  return (
    <>
      <Stack
        direction="row"
        sx={{
          p: 1,
          pb: 3,
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box sx={{fontWeight: 600, fontSize: 20}} {...dataTestId('sampling_points_settings_title')}>
          {t('samplingPointsSettings.samplingPoints')}
        </Box>
        <Button
          color="primary"
          variant="contained"
          startIcon={<Check />}
          disabled={areSamplingPointsBeingProcessed || !canChangeSamplingPoints}
          onClick={handleSaveButton}
          {...dataTestId('sampling_points_settings_save_button')}
        >
          {t('common.save')}
        </Button>
      </Stack>
      <Box maxWidth={865}>
        <SamplingPointsSettingsTable
          limsSamplingPoints={limsSamplingPoints}
          samplingPointTypeOptions={samplingPointTypeOptions}
          assetOptions={assetOptions}
          storageOptions={storageOptions}
        />
      </Box>
    </>
  )
}
