import {PathNode} from '../../common/helpers/plant'
import {StructureNode, UpmEntityType} from '../../common/types'

export const getEntityPathString = (path: PathNode[]): string => {
  return path.map((node) => node.name).join(' > ')
}

export const getAllEntitiesOfType = (
  plantStructure: StructureNode | null | undefined,
  targetType: UpmEntityType
): StructureNode[] => {
  if (!plantStructure) {
    return []
  }
  const result: Array<StructureNode> = []
  const visit = (node: StructureNode) => {
    if (node.entityType === targetType) {
      result.push(node)
    } else if (node.children) {
      node.children.forEach(visit)
    }
  }
  plantStructure?.children?.forEach(visit)
  return result
}
