import {AssetType} from '@hconnect/common/types'
import React, {useLayoutEffect} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'

import {LayoutSettings, SettingsSkeleton} from '../../common/components'
import {useLatestQuery} from '../../common/hooks/queries/useLatestQuery'
import {useAssetsQuery} from '../hooks'

import {AssetsList} from './list'

import {PLANT_ROUTES, getUrl, useUrlParam} from '@settings/modules/routing'

export const PageAssetListPerType: React.FC = () => {
  const {data: assets} = useAssetsQuery()
  const {data: latestData} = useLatestQuery()
  const plantCode = useUrlParam('plantCode')
  const assetType = useUrlParam('assetType')
  const navigate = useNavigate()

  const isAssetTypeValid = Object.values(AssetType).includes(assetType as AssetType)

  // effect to redirect to default asset type if the asset type is not validq
  useLayoutEffect(() => {
    if (!isAssetTypeValid) {
      navigate(
        getUrl(PLANT_ROUTES.ASSETS.ASSET_TYPE.path, {plantCode, assetType: AssetType.CementMill})
      )
    }
  }, [isAssetTypeValid, navigate, plantCode, assetType])

  if (!assets || !latestData || !isAssetTypeValid) {
    return <SettingsSkeleton />
  }

  return <LayoutSettings list={<AssetsList />} details={<Outlet />} />
}
