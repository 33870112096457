import {EditOutlined} from '@mui/icons-material'
import {IconButton, IconButtonProps} from '@mui/material'
import React from 'react'

/**
 * Componend intended to be used as a delete button in settings
 * when saving onBlur is enabled,  it prioritizes onClick event
 * @param IconButtonProps
 */

export const EditButton = ({...props}: IconButtonProps) => {
  return (
    <IconButton
      color="primary"
      // postponing onBlur event, so it happens after onClick
      onMouseDown={(e) => e.preventDefault()}
      {...props}
    >
      <EditOutlined />
    </IconButton>
  )
}
