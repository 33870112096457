import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../../api/mutations'
import {getSettingsQueryData} from '../../../../common/providers/ApiInterface'
import {settingsQueryClient} from '../../../../common/providers/QueryProvider'
import {notifyIfErrorMessage} from '../../../../common/utils/errorHandling'
import {MaterialWithRecipes} from '../../../types'

export const useEditMaterialRecipeComponent = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<
    void,
    AxiosError<{message: string}>,
    Parameters<typeof mutations.editMaterialRecipeComponent>[0]
  >(mutations.editMaterialRecipeComponent, {
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.message, raiseError, notify)
    },
    onSuccess: (data, variables) => {
      notify('success', t('success.editRecipeComponent'))
      const prevMaterialWithRecipes = getSettingsQueryData('materialWithRecipes', [
        variables.plantCode,
        variables.materialId
      ])
      if (prevMaterialWithRecipes) {
        const newMaterialWithRecipe: MaterialWithRecipes = {
          ...prevMaterialWithRecipes,
          recipes: prevMaterialWithRecipes.recipes.map((recipe) => {
            if (recipe.id === variables.recipeId) {
              recipe.components = recipe.components.map((component) => {
                if (component.id === variables.recipeComponent.id) {
                  return variables.recipeComponent
                }
                return component
              })
            }
            return recipe
          })
        }
        void settingsQueryClient.setQueryData(
          ['materialWithRecipes', variables.plantCode, variables.materialId],
          newMaterialWithRecipe
        )
      }
    }
  })
}
