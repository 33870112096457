import {
  KeyboardAltOutlined as KeyboardAltOutlinedIcon,
  Check as CheckIcon,
  ErrorOutline as ErrorOutlineIcon
} from '@mui/icons-material'
import {Box, Typography} from '@mui/material'

import {KpiType, Unit} from '../../types'

export const NameColumnView = ({
  name,
  value,
  kpiType,
  unit
}: {
  name?: string
  value: string | null
  kpiType: KpiType
  unit: Unit
}) => {
  const isError = kpiType === KpiType.Calculated && !value

  const Icon =
    kpiType === KpiType.Manual ? (
      <KeyboardAltOutlinedIcon />
    ) : isError ? (
      <ErrorOutlineIcon color="error" />
    ) : (
      <CheckIcon color="success" />
    )

  return (
    <Box display="flex" flexDirection="row" gap={1}>
      {Icon}
      <Typography variant="body1">{`${name} (${unit.unitName})`}</Typography>
    </Box>
  )
}
