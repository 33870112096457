import {SamplingPoint} from '../../types'

import {Api} from '@settings/api'

export const CREATE_SAMPLING_POINT_MUTATION_KEY = 'createSamplingPoint'

type CreateSamplingPointParameters = {plantCode: string} & Omit<SamplingPoint, 'id'>

export async function createSamplingPoint({
  plantCode,
  type,
  assetId,
  storageId,
  limsSamplingPointId
}: CreateSamplingPointParameters): Promise<void> {
  const path = `/plants/${plantCode}/materials/sampling-points`
  await Api.axiosInstance.post<void>(path, {type, assetId, storageId, limsSamplingPointId})
}
