import {isEmpty} from 'lodash'

import {ExtendedGlobalMeasurementTypesCategory} from '../types/GlobalMeasurementTypesCategory'

export function createMeasurementTypesCategoryTitle(
  category: ExtendedGlobalMeasurementTypesCategory
) {
  const numOfMappedGlobalMeasurementTypes = category.globalMeasurementTypes.filter(
    (globalType) => !isEmpty(globalType.limsMeasurementTypes)
  ).length
  const numOfAllGlobalMeasurementTypes = category.globalMeasurementTypes.length
  return `${category.name} (${numOfMappedGlobalMeasurementTypes}/${numOfAllGlobalMeasurementTypes})`
}
