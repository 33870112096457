import {LimsSamplingPoint} from '../../types'

import {Api} from '@settings/api'

export const LIMS_SAMPLING_POINTS_QUERY_KEY = 'limsSamplingPoints'

export async function limsSamplingPoints(plantCode: string): Promise<LimsSamplingPoint[]> {
  const path = `/plants/${plantCode}/materials/lims/sampling-points`
  const response = await Api.axiosInstance.get<LimsSamplingPoint[]>(path)
  return response.data
}
