import {isoWeekDays} from '@hconnect/uikit/src/common'
import type {IsoWeekDay} from '@hconnect/uikit/src/common'
import moment from 'moment-timezone'
import {formatDayTimeSpan} from './standardOperationTimes'

export const getNextScheduledRun = (
  days: IsoWeekDay[],
  timeRange: [start: string, end: string],
  language: string
) => {
  const [start, end] = timeRange
  const today = moment.utc().startOf('day')
  const newPlanDate = today.clone().add(7 + 1, 'days')
  const nextScheduledRunDate = newPlanDate.clone()
  if (days.length === 0 || !start || !end) return
  while (!days.includes(isoWeekDays[nextScheduledRunDate.isoWeekday() - 1])) {
    nextScheduledRunDate.add(1, 'days')
  }

  return `${nextScheduledRunDate.locale(language).format('ddd DD.MM.YYYY')}, ${formatDayTimeSpan(
    start
  )} - ${formatDayTimeSpan(end)}`
}
