import {Shell, ShellFooter, TourBanner} from '@hconnect/uikit/src/lib2'
import moment from 'moment-timezone'
import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {matchPath, useLocation} from 'react-router-dom'

import {ROUTES} from '../../routing'

import {SettingsHeader} from './SettingsHeader'

export const DefaultShell = ({children}) => {
  const {t} = useTranslation()
  const location = useLocation()
  const pathName = location.pathname
  const currentYear = moment().tz(moment.tz.guess()).format('YYYY')

  const match = matchPath(`${ROUTES.BASE.PLANT_SELECTOR.PLANT.path}/*`, pathName)
  const {plantCode} = match?.params ?? {}
  return (
    <Shell
      header={plantCode && <SettingsHeader plantCode={plantCode} />}
      footer={
        <ShellFooter
          label={t('footer.poweredBy')}
          copyrightNote={`\u00a9 ${currentYear} HeidelbergMaterials. All rights reserved.`}
        />
      }
      isResponsive
      boxed={false}
    >
      <TourBanner sx={{mt: 3, mx: {xs: 2, lg: 4}}} />
      {children}
    </Shell>
  )
}
