import {formatTestId} from '@hconnect/uikit'
import {useMemo} from 'react'

import {ExtendedGlobalMeasurementTypesCategory} from '../types/GlobalMeasurementTypesCategory'
import {createMeasurementTypesCategoryTitle} from '../utils/createMeasurementTypesCategoryTitle'

import {SettingsListItem} from '@settings/modules/common/components'
import {useSearchParam} from '@settings/modules/routing'

export function useMeasurementTypesCategoriesListItems(
  extendedMeasurementTypesCategories: ExtendedGlobalMeasurementTypesCategory[]
) {
  const [selectedMeasurementTypesCategoryName, setSelectedMeasurementTypesCategoryName] =
    useSearchParam('measurementTypesCategory', false)

  const selectedMeasurementTypesCategory = useMemo(
    () =>
      extendedMeasurementTypesCategories.find(
        ({name}) => name === selectedMeasurementTypesCategoryName
      ),
    [extendedMeasurementTypesCategories, selectedMeasurementTypesCategoryName]
  )

  const measurementTypesCategoriesListItems = useMemo<SettingsListItem[]>(
    () =>
      (extendedMeasurementTypesCategories || []).map((category) => ({
        id: category.id.toString(),
        text: createMeasurementTypesCategoryTitle(category),
        caption: category.description,
        isSelected: selectedMeasurementTypesCategoryName === category.name,
        testId: formatTestId(`measurement_types_category_${category.name}`),
        onClick: () => setSelectedMeasurementTypesCategoryName(category.name)
      })),
    [
      extendedMeasurementTypesCategories,
      selectedMeasurementTypesCategoryName,
      setSelectedMeasurementTypesCategoryName
    ]
  )

  return {
    measurementTypesCategoriesListItems,
    selectedMeasurementTypesCategory
  }
}
