import {AssetType, OperationModeType} from '@hconnect/common/types'
import type {
  AssetResponse,
  OperationModeResponse,
  OperationModeResponseProducing
} from '@hconnect/common/types'

import type {ScheduleItem} from '../../common/types'
import type {AssetStandardOperationTime} from '../types'

export const groupAndSortAssets = (assets: AssetResponse[]): Record<AssetType, AssetResponse[]> => {
  const assetsByType: Record<AssetType, AssetResponse[]> = {
    [AssetType.CementMill]: [],
    [AssetType.RawMill]: [],
    [AssetType.RotaryKiln]: [],
    [AssetType.CoalMill]: [],
    [AssetType.Crusher]: [],
    [AssetType.Other]: [],
    [AssetType.BaseLoad]: []
  }
  assets.forEach((asset) => {
    assetsByType[asset.type].push(asset)
  })
  Object.entries(assetsByType).forEach(([type, assets]) => {
    assetsByType[type] = assets.sort((a, b) => a.name.localeCompare(b.name))
  })
  return assetsByType
}

export const sortAssets = (assets: AssetResponse[]): AssetResponse[] =>
  Object.values(groupAndSortAssets(assets)).flatMap((assets) => assets)

export const getOperationModeIdToAssetIdMap = (assets: AssetResponse[]) =>
  assets.reduce<Record<string, number>>((operationModeToAssetsMap, item) => {
    const operationModesToAsset = item.operationModes.reduce<Record<string, number>>(
      (acc, operationMode) => ({...acc, [operationMode.id]: item.id}),
      {}
    )
    return {
      ...operationModeToAssetsMap,
      ...operationModesToAsset
    }
  }, {})

export const guardProducingMode = (
  operationMode: OperationModeResponse
): operationMode is OperationModeResponseProducing =>
  !!operationMode.recipeId && !!operationMode.throughput
export const getOperationModesById = <
  T extends boolean,
  R extends T extends true ? OperationModeResponseProducing : OperationModeResponse
>(
  assets: AssetResponse[],
  onlyProducingModes: T
): Record<string, R> =>
  assets
    .flatMap((asset) => asset.operationModes)
    .filter((operationMode) => (onlyProducingModes ? guardProducingMode(operationMode) : true))
    .reduce((byId, operationMode) => ({...byId, [operationMode.id]: operationMode}), {})

export const getAssetsById = (assets: AssetResponse[]) =>
  assets.reduce<Record<string, AssetResponse>>(
    (assetsById, asset) => ({...assetsById, [asset.id]: asset}),
    {}
  )

export const getBaseLoadPowerConsumptionFromAssets = (assets: AssetResponse[]) => {
  const baseLoadAssets = assets.filter((asset) => asset.type === AssetType.BaseLoad)
  const baseloadOperationModes = baseLoadAssets[0].operationModes.filter(
    (operationMode) => operationMode.type === OperationModeType.Production
  )
  return baseloadOperationModes[0].powerConsumption
}

const basicBaseLoadError = 'Please check the base load assets in the Assets configuration.'

/**
 * function to validate if base load asset is added correctly from BE
 */

export const validateBaseLoadInAssets = (assets: AssetResponse[]) => {
  const baseLoadAssets = assets.filter((asset) => asset.type === AssetType.BaseLoad)
  if (baseLoadAssets.length === 0)
    throw new Error(`Plant has no base load specified. ${basicBaseLoadError}`)
  if (baseLoadAssets.length > 1)
    throw new Error(`Plant has more than 1 base load asset specified. ${basicBaseLoadError}`)

  const baseloadOperationModes = baseLoadAssets[0].operationModes.filter(
    (operationMode) => operationMode.type === OperationModeType.Production
  )
  if (baseloadOperationModes.length === 0)
    throw new Error(
      `Base load asset has no valid production operation modes with a base load value. ${basicBaseLoadError}`
    )
  if (baseloadOperationModes.length > 1)
    throw new Error(
      `Base load asset has more than one production operation mode. ${basicBaseLoadError}`
    )
}

/**
 * The function to find standard operation time array with a specific operation mode id
 */
export const getStandardOperationTimes = (
  operationModeId: number,
  standardOperationTimes?: AssetStandardOperationTime[]
) => {
  return (standardOperationTimes ?? []).filter(
    (operationTime) => operationTime.operationModeId === operationModeId
  )
}

/**
 * Function used to select schedule items with production operation mode for silo levels calculations
 * It filters out schedule items with transition times and maintenance modes
 */

export const getScheduleItemsWithProductionModes = (
  items: ScheduleItem[] | undefined,
  operationModesById: Record<string, OperationModeResponse> | undefined
): ScheduleItem[] =>
  items && operationModesById
    ? items
        .filter((item) => item.isTransitionTime === false)
        .filter((item) => {
          const operationMode = operationModesById[item.assetOperationModeId]
          if (!operationMode) {
            throw new Error(
              `BUG: Operation mode with id ${item.assetOperationModeId} doesn't exist in operationModesById dictionary`
            )
          }
          return guardProducingMode(operationModesById[item.assetOperationModeId])
        })
    : []
