import type {AssetResponse, AssetTransition, AssetTransitionDTO} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {TextField, Stack} from '@mui/material'
import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {Checkbox} from '../../../common/components'
import {minValidator, requiredValidator, submitOnBlurAndEnterProps} from '../../../common/utils'
import {useUrlParam} from '../../../routing'
import {useEditAssetTransition} from '../../hooks'

interface TransitionFormProps {
  asset: AssetResponse
  label: string
  isReadOnly: boolean
  transition: AssetTransition
}

export const TransitionForm: React.FC<TransitionFormProps> = ({
  asset,
  label,
  transition,
  isReadOnly
}) => {
  const plantCode = useUrlParam('plantCode')
  const {t} = useTranslation()

  const {mutate: editAssetTransition, isLoading: isEditingTransition} = useEditAssetTransition()

  const {
    handleSubmit,
    control,
    reset,
    formState: {isDirty, dirtyFields},
    watch
  } = useForm<AssetTransition>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: transition
  })

  const onSubmit = handleSubmit((transition) => {
    const {id, time, isPossible} = transition
    if (!isDirty) return
    const keys = Object.keys(dirtyFields).map((key) => key as keyof AssetTransitionDTO)
    for (const key of keys) {
      editAssetTransition(
        {
          plantCode,
          assetId: asset.id,
          id,
          key,
          dto: {isPossible, time}
        },
        {onError: () => reset(), onSuccess: (transition) => reset(transition)}
      )
    }
  })

  const currentIsPossible = watch('isPossible')

  return (
    <Stack direction="row" {...dataTestId('transition_form')}>
      <Controller
        control={control}
        name="isPossible"
        render={({field: {onChange, value}}) => (
          <Checkbox
            value={value}
            onChange={onChange}
            onBlur={() => void onSubmit()}
            disabled={isEditingTransition || isReadOnly}
            {...dataTestId('transition_possibility_checkbox')}
          />
        )}
      />
      <Controller
        control={control}
        name="time"
        rules={{...requiredValidator(t), ...minValidator(t, 0)}}
        render={({field: {ref, value, onChange}, fieldState: {error}}) => (
          <TextField
            fullWidth
            label={label}
            inputRef={ref}
            type={currentIsPossible ? 'number' : 'text'}
            InputLabelProps={{shrink: true}}
            value={currentIsPossible ? String(value) : t('assetsSettings.notAllowed')}
            onChange={({target: {value: localValue}}) => {
              if (currentIsPossible) {
                onChange(localValue === '' ? '' : parseInt(localValue))
              }
            }}
            error={Boolean(error?.message)}
            helperText={error?.message}
            disabled={isReadOnly}
            {...dataTestId('transition_form_input')}
            {...submitOnBlurAndEnterProps(onSubmit)}
          />
        )}
      />
    </Stack>
  )
}
