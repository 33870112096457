import {dataTestId} from '@hconnect/uikit'
import {Card, customThemeConstants} from '@hconnect/uikit/src/lib2'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {List, ListItemButton, ListItemIcon, ListItemText} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {ProcessStage} from '../types/kpi.types'

const CARD_PADDING_X = 3

export const PrePopulatedProcessStages: FC<{
  data: ProcessStage[]
  selectedProcessStage: number
  setSelectedProcessStage: (index: number) => void
}> = (props) => {
  const {t} = useTranslation()
  return (
    <Card
      headerContent={`${t('kpiCalculationSettings.label.all')} (${props.data.length})`}
      sx={{px: 0}}
      cardTitleSx={{px: CARD_PADDING_X}}
      {...dataTestId('process-stage-list-container')}
    >
      <List component="nav">
        {props.data.map((processStage: ProcessStage, index: number) => {
          const {stageCode, stageName} = processStage
          return (
            <ListItemButton
              onClick={() => {
                props.setSelectedProcessStage(index)
              }}
              key={stageCode}
              sx={{
                ...(props.selectedProcessStage === index && {
                  backgroundColor: customThemeConstants().palette.contentSelected
                }),
                '&:hover': {
                  backgroundColor: customThemeConstants().palette.contentHover
                }
              }}
              {...dataTestId(`kpi-process-stage-${index}`)}
            >
              <ListItemText primary={stageName} />
              <ListItemIcon>
                <ChevronRightIcon />
              </ListItemIcon>
            </ListItemButton>
          )
        })}
      </List>
    </Card>
  )
}
