import {dataTestId} from '@hconnect/uikit'
import {Box} from '@mui/material'
import moment from 'moment'
import React, {useEffect, useState} from 'react'

import {CalendarInfo, DayInfo} from '../../types'

import {CalendarDayItem} from './CalendarDayItem'

const datesAreTheSame = (date: string, selectedDate: string) =>
  moment(date).isSame(moment(selectedDate), 'day')

export const DailyCalendar = ({
  setSelectedDate,
  calendarInfo,
  selectedDate
}: {
  selectedDate: string | undefined
  setSelectedDate: (date: string | undefined) => void
  calendarInfo: CalendarInfo
}) => {
  const [selected, setSelected] = useState<DayInfo>(calendarInfo.days[0])

  const handleSelectionChange = (day: DayInfo) => {
    setSelected(day)
    setSelectedDate(day.date)
  }

  useEffect(() => {
    if (!calendarInfo || !selectedDate) {
      return
    }

    const selectedDay = calendarInfo.days.find((day) => datesAreTheSame(day.date, selectedDate))

    if (selectedDay) {
      setSelected(selectedDay)
    }
  }, [calendarInfo, selectedDate])

  const data = calendarInfo.days

  return (
    <Box
      sx={{
        backgroundColor: '#01356A',
        display: 'flex',
        overflowX: 'auto',
        borderRadius: 1,
        flexDirection: 'row'
      }}
      {...dataTestId('daily-calendar-container')}
    >
      {data.map((day) => {
        const text = moment(day.date).format('D')
        return (
          <CalendarDayItem
            onClick={handleSelectionChange}
            isSelected={day.date === selected.date}
            text={text}
            day={day}
            key={day.date}
          />
        )
      })}
    </Box>
  )
}
