import {Api} from '@settings/api'

export const DELETE_SAMPLING_POINT_MUTATION_KEY = 'deleteSamplingPoint'

interface DeleteSamplingPointParameters {
  plantCode: string
  samplingPointId: number
}

export async function deleteSamplingPoint({
  plantCode,
  samplingPointId
}: DeleteSamplingPointParameters): Promise<void> {
  const path = `/plants/${plantCode}/materials/sampling-points/${samplingPointId}`
  await Api.axiosInstance.delete<void>(path)
}
