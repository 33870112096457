import {dataTestId as dataTestIdFn} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  SxProps,
  TextField,
  Theme,
  Typography
} from '@mui/material'
import {debounce} from 'lodash'
import React, {FC, Fragment, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

type Variable = {
  name: string
  description: string
}

type VariableCardProps = {
  label: string
  variables: Variable[]
  nameSx?: SxProps<Theme>
  'data-test-id'?: string
}

const CARD_PADDING_X = 3
const PAGE_SIZE = 200

const LiTag: FC<{name: string; showDivider?: boolean; children: React.ReactNode}> = ({
  name,
  showDivider,
  children
}) => {
  return (
    <>
      <ListItem
        button
        alignItems="center"
        sx={{px: CARD_PADDING_X, minHeight: ({spacing}) => spacing(7)}}
        {...dataTestIdFn(name)}
      >
        {children}
      </ListItem>

      {showDivider && <Divider sx={{mx: CARD_PADDING_X}} variant="fullWidth" component="li" />}
    </>
  )
}

export const VariableCard: FC<VariableCardProps> = ({
  label,
  variables,
  nameSx,
  'data-test-id': dataTestId
}) => {
  const {t} = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setDataItems] = useState<Variable[]>(variables)
  const [visibleItems, setVisibleItems] = useState<Variable[]>(data.slice(0, PAGE_SIZE))

  const onScroll = useCallback(
    ({target}) => {
      if (
        Math.floor(target.scrollHeight - target.scrollTop) <= target.clientHeight &&
        visibleItems.length < data.length
      ) {
        setVisibleItems(data.slice(0, visibleItems.length + PAGE_SIZE))
      }
    },
    [data, visibleItems.length]
  )

  const trackScrolling = debounce(onScroll, 200)

  const filterVariables = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value.toLowerCase()
      const filteredItems =
        value.length > 0
          ? variables.filter(({name, description}) => {
              return name.toLowerCase().includes(value) || description.toLowerCase().includes(value)
            })
          : variables
      setDataItems(filteredItems)
      setVisibleItems(filteredItems)
      setIsLoading(false)
    },
    [variables]
  )

  const searchOnChange = debounce(filterVariables, 200)

  return (
    <Card
      sx={{px: 0, pt: 0, pb: 0, height: '550px', overflowY: 'scroll', overflowX: 'auto'}}
      bodySx={{pt: {xs: 0, md: 0}}}
      onScroll={trackScrolling}
      data-test-id={dataTestId}
      headerContent={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: CARD_PADDING_X,
            bgcolor: 'common.white',
            zIndex: 1,
            position: 'sticky',
            top: 0,
            boxShadow: 5
          }}
        >
          <CardTitle sx={{mb: 0}}>{label}</CardTitle>
          <TextField
            {...dataTestIdFn(`search_${dataTestId}`)}
            label={t('kpiCalculationSettings.action.search')}
            placeholder={t('kpiCalculationSettings.label.pleaseStartTyping')}
            variant="filled"
            onChange={(e) => {
              setIsLoading(true)
              searchOnChange(e)
            }}
          />
        </Box>
      }
    >
      <List>
        {isLoading ? (
          <LiTag name={'is-loading'}>{t('kpiCalculationSettings.label.isLoading')}</LiTag>
        ) : visibleItems.length > 0 ? (
          visibleItems.map(({name, description}, index) => (
            <Fragment key={name}>
              <LiTag name={name} showDivider={index < data.length - 1}>
                <ListItemText
                  primary={name}
                  secondary={<Typography variant="caption">{description}</Typography>}
                  sx={{my: 0, display: 'flex', flexDirection: 'column', ...nameSx}}
                />
              </LiTag>
            </Fragment>
          ))
        ) : (
          <LiTag name={'no-data'}>{t('kpiCalculationSettings.label.noData')}</LiTag>
        )}
      </List>
    </Card>
  )
}
