import type {Env} from '@hconnect/common/hproduce'
import {hproduce} from '@hconnect/common/hproduce/config/hproduce'
import {settings} from '@hconnect/common/hproduce/config/settings'
import {useLayoutEffect} from 'react'

const env = process.env.REACT_APP_STAGE as Env

const HProduceEntryPointRedirectUrl = `${hproduce.url[env]}/${settings.name}`

export const EntryPointRedirect = () => {
  useLayoutEffect(() => {
    window.location.replace(HProduceEntryPointRedirectUrl)
  }, [])

  return null
}
