import {
  KpiThreadholdColors,
  KpiThresholdIcon
} from '@hconnect/common/components/kpiPerformance/KpiThresholdIcon'
import {KpiRangeBreachSeverity} from '@hconnect/common/types'
import {Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Threshold, ThresholdPreviewType} from '../../kpi-calculation/types'
import {generateThresholdPreview} from '../../kpi-calculation/utils/kpiThresholds.utils'

import {Pill} from './Pill'

type ThresholdPreviewProps = {
  thresholds: Threshold[]
}

export const ThresholdsPreview: React.FC<ThresholdPreviewProps> = ({thresholds}) => {
  const previews = generateThresholdPreview(thresholds)

  return (
    <Stack spacing={1} direction="row" px={3} useFlexGap flexWrap="wrap">
      {previews.length === 0 ? (
        <NoThresholdsPreview />
      ) : (
        previews.map((preview, index) => {
          switch (preview.type) {
            case ThresholdPreviewType.MoreThan:
              return (
                <MoreThanChip
                  key={index}
                  severity={preview.severity}
                  value={preview.value}
                  unit={preview.unit}
                />
              )
            case ThresholdPreviewType.LessThan:
              return (
                <LessThanChip
                  key={index}
                  severity={preview.severity}
                  value={preview.value}
                  unit={preview.unit}
                />
              )
            case ThresholdPreviewType.Between:
              return (
                <BetweenChip
                  key={index}
                  severity={preview.severity}
                  from={preview.from}
                  to={preview.to}
                  unit={preview.unit}
                />
              )
            default:
              return null
          }
        })
      )}
    </Stack>
  )
}

const LessThanChip: React.FC<{severity: KpiRangeBreachSeverity; value: number; unit?: string}> = ({
  severity,
  value,
  unit = ''
}) => <ThresholdRangePill severity={severity} label={`< ${value} ${unit}`} />

const MoreThanChip: React.FC<{severity: KpiRangeBreachSeverity; value: number; unit?: string}> = ({
  severity,
  value,
  unit = ''
}) => <ThresholdRangePill severity={severity} label={`> ${value} ${unit}`} />

const BetweenChip: React.FC<{
  severity: KpiRangeBreachSeverity
  from: number
  to: number
  unit?: string
}> = ({severity, from, to, unit = ''}) => (
  <ThresholdRangePill severity={severity} label={`${from} ${unit} - ${to} ${unit}`} />
)

const ThresholdRangePill: React.FC<{severity: KpiRangeBreachSeverity; label: string}> = ({
  severity,
  label
}) => (
  <Pill
    icon={<KpiThresholdIcon status={severity} />}
    color={KpiThreadholdColors[severity]}
    label={
      <Typography fontSize={16} lineHeight={24} color={KpiThreadholdColors[severity]}>
        {label}
      </Typography>
    }
  />
)

const NoThresholdsPreview: React.FC = () => {
  const {t} = useTranslation()
  return <Pill color="info" label={t('thresholdTable.label.noThresholdsDefined')} />
}
