import {AssetType} from '@hconnect/common/types'
import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'

import {useAssetsQuery} from '../hooks'

import {ROUTES, getUrl, useUrlParam} from '@settings/modules/routing'

export const NewBaseLoadGuard: React.FC = () => {
  const assetType = useUrlParam('assetType')
  const {data: baseloadAssets} = useAssetsQuery({types: [AssetType.BaseLoad]})
  if (!baseloadAssets) {
    throw new Error('BUG: assets should be loaded before rendering this page')
  }
  const isBaseLoadRoute = (assetType as AssetType) === AssetType.BaseLoad
  const isBaseloadExist = baseloadAssets?.length > 0
  // there shold be only one baseload asset in the plant
  if (isBaseloadExist && isBaseLoadRoute) {
    return <Navigate to={getUrl(ROUTES.BASE.NOT_FOUND.path, {})} replace />
  }

  return <Outlet />
}
