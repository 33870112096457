import {dataTestId} from '@hconnect/uikit'
import {NumberLetterSequenceIndicator} from '@hconnect/uikit/src/lib2'
import {DeleteOutlined} from '@mui/icons-material'
import {Box, TextField, Typography} from '@mui/material'
import React, {useCallback} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {MaterialApiKeys} from '../../../../api/mutations'
import {DeleteButton} from '../../../common/components'
import {useConfirmDialog} from '../../../common/providers'
import {submitOnBlurAndEnterProps} from '../../../common/utils'
import {useUrlParam} from '../../../routing'
import {useEditMaterial} from '../../hooks'
import {Material} from '../../types'

interface PxTrendFormProps {
  isReadOnly: boolean
  material: Material
  pxTrendCounter: string
  setHasNewPxTrendCounter: (id?: boolean) => void
  index: number
}

export const PxTrendForm: React.FC<PxTrendFormProps> = ({
  index,
  isReadOnly,
  material,
  pxTrendCounter: initialPxTrendCounter,
  setHasNewPxTrendCounter
}) => {
  const plantCode = useUrlParam('plantCode')

  const {t} = useTranslation()

  const {mutate: editMaterial} = useEditMaterial()

  const {
    handleSubmit: handleSubmitForm,
    control,
    reset,
    formState: {isDirty}
  } = useForm<{pxTrendCounter?: string}>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {pxTrendCounter: initialPxTrendCounter}
  })

  const {openDialog} = useConfirmDialog()

  const getUpdatedMaterialData = useCallback(
    (initialPxTrendCounter: string, pxTrendCounter: string): Material => {
      const {pxTrendCounters = []} = material
      // existing pxTrend counter was deleted
      if (!pxTrendCounter && initialPxTrendCounter) {
        return {
          ...material,
          pxTrendCounters: pxTrendCounters.filter((counter) => counter !== initialPxTrendCounter)
        }
      }
      // new pxTrend counter was added
      if (initialPxTrendCounter === '' && pxTrendCounter) {
        return {
          ...material,
          pxTrendCounters: [...pxTrendCounters, pxTrendCounter]
        }
      }

      // existing pxTrend counter was updated
      return {
        ...material,
        pxTrendCounters: pxTrendCounters.map((counter) =>
          counter === initialPxTrendCounter ? pxTrendCounter : counter
        )
      }
    },
    [material]
  )

  const submit = (newPxTrendCounter: string) => {
    // new pxTrend input field was deleted, no need to notify the api
    if (!newPxTrendCounter && !initialPxTrendCounter) {
      setHasNewPxTrendCounter(false)
      reset({pxTrendCounter: newPxTrendCounter})
      return
    }

    // pxTrend input field updated
    const updatedMaterial = getUpdatedMaterialData(initialPxTrendCounter, newPxTrendCounter)
    editMaterial(
      {
        key: MaterialApiKeys.pxTrendCounters,
        plantCode,
        materialId: updatedMaterial.id,
        name: updatedMaterial.name,
        source: updatedMaterial.source,
        type: updatedMaterial.type,
        pxTrendCounters: updatedMaterial.pxTrendCounters
      },
      {
        onError: () => reset(),
        onSuccess: () => {
          setHasNewPxTrendCounter(false)
          reset({pxTrendCounter: newPxTrendCounter})
        }
      }
    )
  }

  const handleSubmit = handleSubmitForm(({pxTrendCounter}) => {
    if (!isDirty || !pxTrendCounter || initialPxTrendCounter === pxTrendCounter) return
    submit(pxTrendCounter)
  })

  const handleDelete = () => {
    if (!initialPxTrendCounter) {
      setHasNewPxTrendCounter(false)
      return
    }
    openDialog({
      title: t('materialsSettings.deletePxTrendCounterTitle'),
      mainAction: {
        text: t('common.delete'),
        color: 'error',
        icon: <DeleteOutlined />,
        onAction: () => {
          submit('')
        }
      },
      additionalContent: (
        <Typography>
          {t('materialsSettings.deletePxTrendCounterConfirmation', {
            pxTrendCounter: initialPxTrendCounter
          })}
        </Typography>
      )
    })
  }

  return (
    <Box
      {...dataTestId(`px_trend_form_${index}`)}
      sx={{p: (theme) => theme.spacing(1), display: 'flex', alignItems: 'center'}}
    >
      <NumberLetterSequenceIndicator numberIndex={index} />
      <Controller
        control={control}
        name="pxTrendCounter"
        render={({field: {ref, value, onChange}, fieldState: {error}}) => (
          <TextField
            label={t('materialsSettings.counter')}
            sx={{ml: 2, width: ({spacing}) => spacing(50)}}
            value={value}
            placeholder={value}
            inputRef={ref}
            onChange={onChange}
            helperText={error?.message}
            error={Boolean(error?.message)}
            disabled={isReadOnly}
            {...submitOnBlurAndEnterProps(handleSubmit)}
          />
        )}
      />
      <DeleteButton
        sx={{ml: 2}}
        onClick={() => void handleDelete()}
        disabled={isReadOnly}
        {...dataTestId('delete_recipe_button')}
      />
    </Box>
  )
}
