import {NumericTextField} from '@hconnect/common/components/forms/NumericTextField'
import {dataTestId} from '@hconnect/uikit'
import {NumberLetterSequenceIndicator} from '@hconnect/uikit/src/lib2'
import {DeleteOutlined} from '@mui/icons-material'
import {Box, TextField, MenuItem} from '@mui/material'
import React from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {DeleteButton} from '../../../common/components'
import {useConfirmDialog} from '../../../common/providers'
import {minValidator, requiredValidator} from '../../../common/utils'
import {useUrlParam} from '../../../routing'
import {
  useAddMaterialRecipeComponent,
  useEditMaterialRecipeComponent,
  useDeleteMaterialRecipeComponent
} from '../../hooks'
import type {Recipe, RecipeComponent, Material} from '../../types'

interface RecipeComponentFormProps {
  index: number
  recipe: Recipe
  label: string
  component: Omit<RecipeComponent, 'id'> & {id: number | undefined}
  availableMaterials: Material[]
  canRemove: boolean
  canEditFraction: boolean
  canEditMaterial: boolean
  isReadOnly: boolean
  setNewRecipeComponent?: (component: undefined) => void
}

function getShift(numberStr: string) {
  const parts = numberStr.split('.')
  return parts.length < 2 ? 1 : Math.pow(10, parts[1].length)
}

function percentageToFraction(percentage: string) {
  const shift = getShift(percentage)
  const fraction = Math.round(parseFloat(percentage) * shift) / (100 * shift)
  return isNaN(fraction) ? '' : fraction
}

function fractionToPercentage(fraction: number | '') {
  if (fraction === '') return fraction
  const shift = getShift(fraction.toString())
  return (Math.round(fraction * shift) * 100) / shift
}

export const RecipeComponentForm: React.FC<RecipeComponentFormProps> = ({
  index,
  recipe,
  component,
  label,
  availableMaterials,
  isReadOnly,
  canRemove,
  canEditMaterial,
  canEditFraction,
  setNewRecipeComponent
}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')

  const {openDialog} = useConfirmDialog()

  const {mutateAsync: addRecipeComponent} = useAddMaterialRecipeComponent()
  const {mutate: editRecipeComponent} = useEditMaterialRecipeComponent()
  const {mutate: deleteRecipeComponent} = useDeleteMaterialRecipeComponent()

  const {
    handleSubmit,
    control,
    reset,
    formState: {isDirty, dirtyFields}
  } = useForm<Omit<RecipeComponent, 'id'> & {id: number | undefined}>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: component
  })

  const handleDeleteRecipeComponent = () => {
    if (component.id) {
      openDialog({
        title: t('materialsSettings.deleteRecipeComponent'),
        description: t('materialsSettings.deleteRecipeComponentConfirmation', {
          name: component.material.name
        }),
        mainAction: {
          color: 'error',
          text: t('common.delete'),
          icon: <DeleteOutlined />,
          onAction: () => {
            deleteRecipeComponent({
              plantCode,
              materialId: recipe.mainMaterialId,
              recipeId: recipe.id,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              recipeComponentId: component.id!
            })
          }
        }
      })
    } else {
      setNewRecipeComponent?.(undefined)
    }
  }
  const submit = handleSubmit(async (values) => {
    if (values.id !== undefined) {
      const keys = Object.keys(dirtyFields).map(
        (key) => key as keyof Omit<RecipeComponent, 'id' | 'type'>
      )
      const recipeComponent = {
        id: values.id,
        fraction: values.fraction,
        type: values.type,
        material: values.material
      }
      keys.forEach((key) => {
        editRecipeComponent(
          {
            key,
            plantCode,
            materialId: recipe.mainMaterialId,
            recipeId: recipe.id,
            recipeComponent
          },
          {
            onError: () => reset(),
            onSuccess: () => reset(recipeComponent)
          }
        )
      })
    } else {
      await addRecipeComponent(
        {
          plantCode,
          recipeId: recipe.id,
          materialId: recipe.mainMaterialId,
          componentDTO: {
            type: values.type,
            materialId: values.material.id,
            fraction: values.fraction
          }
        },
        {onError: () => reset(), onSuccess: () => setNewRecipeComponent?.(undefined)}
      )
    }
  })

  const handleSubmitOnEnter = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') isDirty && void submit()
  }

  const handleSumbitOnBlur = () => {
    if (component?.id) {
      // if component already exist on BE submit only when something changed
      isDirty && void submit()
    } else {
      void submit()
    }
  }

  return (
    <Box
      sx={{display: 'flex', alignItems: 'center', mt: 2, ml: 4}}
      {...dataTestId('recipe_component_form')}
    >
      <NumberLetterSequenceIndicator letterIndex={index} />
      <Box sx={{width: (theme) => theme.spacing(34), display: 'flex', ml: 2}}>
        <Controller
          name="material.id"
          control={control}
          rules={requiredValidator(t)}
          render={({field: {ref, value, onChange}}) => (
            <TextField
              variant="outlined"
              fullWidth
              select
              label={label}
              inputRef={ref}
              value={value}
              onChange={onChange}
              onBlur={handleSumbitOnBlur}
              disabled={isReadOnly || !canEditMaterial}
              {...dataTestId('material_select')}
            >
              {availableMaterials.map((material) => (
                <MenuItem
                  key={material.id}
                  value={material.id}
                  {...dataTestId('material_select_item')}
                >
                  {material.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Box>
      <Box sx={{display: 'flex', ml: 2}}>
        <Controller
          name="fraction"
          rules={{...requiredValidator(t), ...minValidator(t, 0), validate: (v) => !isNaN(v)}}
          control={control}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <NumericTextField
              sx={{
                ml: 0.2,
                width: (theme) => theme.spacing(12),
                'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                  WebkitAppearance: 'none'
                },
                'input[type=number]': {
                  MozAppearance: 'textfield'
                }
              }}
              variant="outlined"
              label="%"
              inputProps={{step: 'any', min: '0'}}
              error={Boolean(error)}
              value={fractionToPercentage(value)}
              onChange={({target: {value}}) => {
                onChange(percentageToFraction(value))
              }}
              disabled={isReadOnly || !canEditFraction}
              inputRef={ref}
              onBlur={handleSumbitOnBlur}
              onKeyDown={handleSubmitOnEnter}
              {...dataTestId('fraction_input')}
            />
          )}
        />
      </Box>
      {canRemove && (
        <DeleteButton
          sx={{ml: 2, p: 2}}
          onClick={handleDeleteRecipeComponent}
          disabled={isReadOnly}
          {...dataTestId('delete_recipe_component_button')}
        />
      )}
    </Box>
  )
}
