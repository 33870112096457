import {
  AssetStandardOperationTime,
  AssetStandardOperationTimeData,
  AssetsStandardOperationTimes,
  AssetsStandardOperationTimesData
} from '../../types'
import {StandardOperationParserParams, parseICalString} from '../../utils'

export const parseDefaultOperationTimeDto = (
  params: StandardOperationParserParams,
  data: AssetStandardOperationTime
): AssetStandardOperationTimeData => {
  return {
    ...data,
    calendarEvent: parseICalString(params, data.calendarEvent)
  }
}

export const getDefaultOperationTimesDtoParser =
  (params: StandardOperationParserParams) =>
  (data: AssetsStandardOperationTimes): AssetsStandardOperationTimesData => {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        value.map(parseDefaultOperationTimeDto.bind(null, params))
      ])
    )
  }
