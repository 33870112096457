import type {AssetResponse} from '@hconnect/common/types'
import React from 'react'
import {Trans} from 'react-i18next'

import {InfoGroup} from '../../../common/components'
import type {Material} from '../../types'

interface MaterialMoveInfoProps {
  material: Material
  assets: AssetResponse[]
}

function cutStringTo40Chars(str: string) {
  return str.length <= 40 ? str : `${str.substring(0, 37)}...`
}

export const MaterialMoveInfo: React.FC<MaterialMoveInfoProps> = ({material, assets}) => {
  const assetNames = cutStringTo40Chars(assets.map((a) => a.name).join(', '))
  return (
    <>
      <InfoGroup
        description={
          <Trans
            i18nKey="materialsSettings.moveMaterialNotPossibleDescription"
            values={{name: material.name}}
          />
        }
        panels={[
          {
            accentColor: 'warning',
            content: (
              <Trans
                i18nKey="materialsSettings.moveMaterialNotPossibleAssetsDescription"
                values={{
                  asset_count: assets.length,
                  assets_names: assetNames
                }}
              />
            )
          }
        ]}
      />
    </>
  )
}
