import {dataTestId as spreadDataTestId} from '@hconnect/uikit'
import {ToggleButton, ToggleButtonGroup} from '@mui/material'
import React from 'react'

export type SegmentedButtonOption<T> = {
  value: T
  title: string
  dataTestId?: string
  icon?: JSX.Element
  // unique id for guided tour purposes
  id?: string
}

type MaterialSourceToggleGroupProps<T> = {
  value: T
  onChange: (value: T) => void
  options: SegmentedButtonOption<T>[]
  isDisabled?: boolean
  'data-test-id'?: string
}

export const SegmentedButton = <T extends string>({
  value,
  onChange,
  options,
  isDisabled,
  ['data-test-id']: dataTestId
}: MaterialSourceToggleGroupProps<T>): JSX.Element => {
  return (
    <ToggleButtonGroup
      value={value}
      onChange={(_e, value: T | null) => {
        // preventing an unselecting button
        if (value) {
          onChange(value)
        }
      }}
      color="primary"
      exclusive
      sx={{
        ml: 2,
        '& svg': {
          mr: 1
        }
      }}
      disabled={isDisabled}
      {...spreadDataTestId(dataTestId ?? 'toggle_group')}
    >
      {options.map((option, index) => (
        <ToggleButton
          id={option.id}
          key={index}
          value={option.value}
          {...spreadDataTestId(option.dataTestId ?? `option_${index}`)}
        >
          {option.icon && (
            <>
              {value === option.value
                ? option.icon
                : React.cloneElement(option.icon, {sx: {color: 'action.disabled'}})}
            </>
          )}
          {option.title}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
