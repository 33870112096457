import {dataTestId} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {Close, DeleteOutlined} from '@mui/icons-material'
import {Box, Button, IconButton} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'

import {Kpi} from '../../types'

import {KpiForm} from './KpiForm'

type KpiCardProps = {
  kpi: Kpi
}

export const KpiCard: FC<KpiCardProps> = ({kpi}) => {
  const {t} = useTranslation()
  const [, setSearchParam] = useSearchParams()

  const {tagname, displayName, kpiDefinitionId, unit, frequency, plantEntryPointVisibility} = kpi

  return (
    <Card
      headerContent={
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <CardTitle sx={{mb: 0}}>{t('kpiCalculationSettings.label.kpi')}</CardTitle>
          <Box sx={{display: 'flex', gap: 1.5}}>
            <Button
              variant="text"
              color="error"
              startIcon={<DeleteOutlined />}
              {...dataTestId('kpi-section-remove-button')}
            >
              {t('kpiCalculationSettings.action.delete')}
            </Button>
            <IconButton
              sx={{p: 1, width: 42}}
              onClick={() => setSearchParam({})}
              {...dataTestId('kpi-section-close-button')}
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      }
      {...dataTestId('kpi-card')}
    >
      <KpiForm
        frequency={frequency}
        kpiUnit={unit}
        kpiTagname={tagname}
        kpiDisplayName={displayName}
        kpiId={kpiDefinitionId}
        plantEntryPointVisibility={plantEntryPointVisibility}
      />
    </Card>
  )
}
