import {SamplingPointType} from '../../types'

import {Api} from '@settings/api'

export const SAMPLING_POINT_TYPES_QUERY_KEY = 'samplingPointTypes'

export async function samplingPointTypes(): Promise<SamplingPointType[]> {
  const path = '/plants/materials/sampling-points/types'
  const response = await Api.axiosInstance.get<SamplingPointType[]>(path)
  return response.data
}
