import {Skeleton} from '@mui/material'
import React from 'react'

import {PageLayout} from '../PageLayout'

export const PageSkeleton = ({children}: {children?: React.ReactNode}) => {
  return (
    <PageLayout
      title={
        <Skeleton
          variant="rectangular"
          sx={{
            width: (theme) => theme.spacing(50),
            backgroundColor: ({palette}) => palette.background.paper
          }}
        />
      }
    >
      {children ?? (
        <Skeleton
          variant="rectangular"
          sx={{
            height: '65vh',
            backgroundColor: ({palette}) => palette.background.paper
          }}
        />
      )}
    </PageLayout>
  )
}
