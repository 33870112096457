import {dataTestId} from '@hconnect/uikit'
import {TableBody, TableRow, TableCell} from '@mui/material'
import React from 'react'

import {
  LimsSamplingPoint,
  AutocompleteOption,
  SamplingPointTypesAutocompleteOption
} from '../../../types'

import {SamplingPointTypeEquipmentForm} from './SamplingPointTypeEquipmentForm/SamplingPointTypeEquipmentForm'

import {TextWithCaption} from '@settings/modules/common/components/TextWithCaption/TextWithCaption'

export interface SamplingPointsSettingsTableBodyProps {
  limsSamplingPoints: LimsSamplingPoint[]
  samplingPointTypeOptions: SamplingPointTypesAutocompleteOption[]
  assetOptions: AutocompleteOption[]
  storageOptions: AutocompleteOption[]
}

export const SamplingPointsSettingsTableBody: React.FC<SamplingPointsSettingsTableBodyProps> = ({
  limsSamplingPoints,
  samplingPointTypeOptions,
  assetOptions,
  storageOptions
}) => {
  return (
    <TableBody {...dataTestId('sampling_points_table_body')}>
      {limsSamplingPoints.map((limsSamplingPoint) => {
        return (
          <TableRow key={limsSamplingPoint.id} {...dataTestId('sampling_points_table_row')}>
            <TableCell>
              <TextWithCaption
                text={limsSamplingPoint.code}
                caption={limsSamplingPoint.description}
              />
            </TableCell>
            <SamplingPointTypeEquipmentForm
              ref={limsSamplingPoint.limsSamplingPointRef}
              limsSamplingPoint={limsSamplingPoint}
              samplingPointTypeOptions={samplingPointTypeOptions}
              assetOptions={assetOptions}
              storageOptions={storageOptions}
            />
          </TableRow>
        )
      })}
    </TableBody>
  )
}
