import {Autocomplete, TextField} from '@mui/material'
import {isUndefined} from 'lodash'
import React from 'react'

import {AutocompleteOption, SamplingPointTypesAutocompleteOption} from '../../../../types'

interface SamplingPointFormAutocompleteProps {
  value: AutocompleteOption | SamplingPointTypesAutocompleteOption | null
  options: AutocompleteOption[] | SamplingPointTypesAutocompleteOption[]
  label: string
  inputRef: React.Ref<HTMLInputElement>
  error?: string
  disabled?: boolean
  onChange: (data: AutocompleteOption | SamplingPointTypesAutocompleteOption | null) => void
  'data-test-id'?: string
}

export const SamplingPointFormAutocomplete: React.FC<SamplingPointFormAutocompleteProps> = ({
  value,
  options,
  label,
  inputRef,
  error,
  disabled = false,
  onChange,
  'data-test-id': providedDataTestId = 'sampling_point_form_autocomplete'
}) => {
  return (
    <Autocomplete
      sx={{width: (theme) => theme.spacing(29), height: (theme) => theme.spacing(8)}}
      options={options}
      value={value}
      disabled={disabled}
      onChange={(_, data) => onChange(data)}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            sx={{
              '& .MuiFilledInput-root': {
                backgroundColor: 'white',
                fontWeight: 500
              }
            }}
            inputRef={inputRef}
            variant="filled"
            label={label}
            helperText={error}
            error={!isUndefined(error)}
          />
        )
      }}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      data-test-id={providedDataTestId}
    />
  )
}
