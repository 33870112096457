import {MaterialSource} from '@hconnect/common/types'

import {useSettingsQuery} from '../../../common/providers/ApiInterface'
import {useUrlParam} from '../../../routing'
import {getProductsPerMaterialSource} from '../../helpers/getProductsPerMaterialSource'

export const useMaterialsProductsQuery = (materialSource?: MaterialSource) => {
  const plantCode = useUrlParam('plantCode')

  return useSettingsQuery('materialsProducts', [plantCode], {
    select: (data) => {
      if (!materialSource) {
        return data
      }
      return getProductsPerMaterialSource(data, materialSource)
    }
  })
}
