import {MaterialSource, MaterialType, Status} from '@hconnect/common/types'
import type {AssetResponse} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {DeleteOutlined, DriveFileMoveOutlined, Add, DeleteOutline} from '@mui/icons-material'
import {MenuItem, TextField, IconButton, Box} from '@mui/material'
import {TFunction} from 'i18next'
import React, {useRef, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {MaterialApiKeys} from '../../../../api/mutations'
import {
  filterProductionAssetsWithAnyRecipeFromList,
  useAssetsQuery,
  findAllOperationModes
} from '../../../assets'
import {SettingsList} from '../../../common/components'
import type {SettingsListItem, MenuAction} from '../../../common/components'
import {useScheduleQuery} from '../../../common/hooks'
import {useConfirmDialog} from '../../../common/providers'
import type {Schedule} from '../../../common/types'
import {usePermission} from '../../../permissions'
import {useUrlParam, useSearchParam} from '../../../routing'
import {
  useMaterialWithRecipesQuery,
  useMaterialsQuery,
  useDeleteMaterial,
  useEditMaterial
} from '../../hooks'
import type {Material, MaterialWithRecipes} from '../../types'
import {useSelectedMaterial} from '../SelectedMaterialProvider'

import {MaterialDeleteInfo} from './MaterialDeleteInfo'
import {MaterialMoveInfo} from './MaterialMoveInfo'

const getMaterialTypesList = (t: TFunction, excludeType?: MaterialType) => {
  return Object.values(MaterialType)
    .filter((type) => type !== excludeType)
    .map((type) => (
      <MenuItem key={type} value={type} {...dataTestId(`${type}_select_item`)}>
        {t(`materialsSettings.materialTypes.${type}`)}
      </MenuItem>
    ))
}

export const MaterialList: React.VFC = () => {
  const plantCode = useUrlParam('plantCode')
  const [materialType, setMaterialType] = useSearchParam('materialType')

  const {openDialog} = useConfirmDialog()
  const {t} = useTranslation()
  const {selectedMaterial, setSelectedMaterial} = useSelectedMaterial()

  const isNewMaterial = selectedMaterial && selectedMaterial.status === Status.New
  const {data: materials} = useMaterialsQuery()
  const {mutate: deleteMaterial, isLoading: isMaterialDeleting} = useDeleteMaterial()
  const {mutate: editMaterial} = useEditMaterial()
  const {data: assets} = useAssetsQuery()
  const {data: schedule} = useScheduleQuery()

  const canChangeMaterials = usePermission('CHANGE_MATERIALS')

  const selectedMaterialId =
    selectedMaterial && selectedMaterial?.status !== Status.New ? selectedMaterial.id : undefined
  const {data: materialWithRecipes} = useMaterialWithRecipesQuery(selectedMaterialId)

  // using ref here to share state between uncontrolled select from dialog with action callback
  const movedMaterialRef = useRef<HTMLSelectElement>()

  const isMaterialSelected = (material: Material) => {
    if (!selectedMaterial || selectedMaterial.status === Status.New) return false
    return selectedMaterial.id === material.id
  }

  const materialsForType = useMemo(
    () => materials?.filter((material) => material.type === materialType) ?? [],
    [materials, materialType]
  )

  function openMoveMaterialNotPossibleDialog(material: Material, assets: AssetResponse[]) {
    openDialog({
      title: t('materialsSettings.moveMaterialNotPossible'),
      additionalContent: <MaterialMoveInfo material={material} assets={assets} />,
      showCancelButton: false,
      mainAction: {
        text: t('common.ok')
      }
    })
  }

  useEffect(() => {
    if (!selectedMaterial && materialsForType && materialsForType.length > 0) {
      setSelectedMaterial(materialsForType[0])
    }
  }, [selectedMaterial, materialsForType, setSelectedMaterial])

  function openDeleteMaterialDialog(
    schedule: Schedule,
    assets: AssetResponse[],
    material: MaterialWithRecipes
  ) {
    const operationModes = findAllOperationModes(assets, material.recipes)
    const scheduleItems = Object.values(schedule.schedules).filter(
      (item) => operationModes.find((mode) => mode.id === item.assetOperationModeId) !== undefined
    )
    openDialog({
      title: t('materialsSettings.deleteMaterial'),
      mainAction: {
        color: 'error',
        text: t('common.delete'),
        icon: <DeleteOutline />,
        onAction: () =>
          deleteMaterial(
            {plantCode, materialId: material.id},
            {
              onSuccess: () => {
                setSelectedMaterial(undefined)
              }
            }
          )
      },
      additionalContent: (
        <MaterialDeleteInfo
          material={material}
          operationModes={operationModes}
          scheduleItems={scheduleItems}
        />
      )
    })
  }

  const getMaterialActions = (
    schedule: Schedule,
    assets: AssetResponse[],
    material: MaterialWithRecipes
  ): MenuAction[] => [
    {
      icon: <DriveFileMoveOutlined fontSize="small" />,
      title: t('common.move'),
      testId: 'move_material',
      isDisabled: !canChangeMaterials,
      onClick: () => {
        const assetsThatUseAnyRecipe = filterProductionAssetsWithAnyRecipeFromList(
          assets,
          material.recipes
        )
        if (assetsThatUseAnyRecipe.length > 0) {
          openMoveMaterialNotPossibleDialog(material, assetsThatUseAnyRecipe)
          return
        }

        openDialog({
          title: t('materialsSettings.moveMaterial'),
          description: t('materialsSettings.moveMaterialDescription'),
          mainAction: {
            text: t('common.move'),
            onAction: () => {
              if (movedMaterialRef.current?.value) {
                const newMaterialType = movedMaterialRef.current.value as MaterialType
                editMaterial({
                  key: MaterialApiKeys.type,
                  plantCode,
                  name: material.name,
                  source: material.source,
                  materialId: material.id,
                  type: newMaterialType
                })
                setMaterialType(newMaterialType)
              }
            }
          },
          additionalContent: (
            <TextField
              inputRef={movedMaterialRef}
              defaultValue={Object.values(MaterialType).find((type) => type !== material.type)}
              variant="outlined"
              fullWidth
              select
              label={t('materialsSettings.materialType')}
              {...dataTestId('dialog_material_type_select')}
            >
              {getMaterialTypesList(t, material.type)}
            </TextField>
          )
        })
      }
    },
    {
      icon: <DeleteOutlined fontSize="small" color="error" />,
      title: t('common.delete'),
      testId: 'delete_material',
      onClick: () => openDeleteMaterialDialog(schedule, assets, material),
      isDisabled: isMaterialDeleting || !canChangeMaterials
    }
  ]

  const existingMaterialsListItems: SettingsListItem[] =
    materialsForType?.map((material) => ({
      text: material.name,
      isSelected: isMaterialSelected(material),
      onClick: () => setSelectedMaterial(material),
      testId: 'material_list_item',
      secondaryActions:
        isMaterialSelected(material) && materialWithRecipes && assets && schedule
          ? getMaterialActions(schedule, assets, materialWithRecipes)
          : undefined
    })) ?? []

  const listItems: SettingsListItem[] = [
    ...(isNewMaterial
      ? [
          {
            text: t('materialsSettings.newMaterial'),
            isSelected: true,
            testId: 'new_material_list_item',
            listItemTextSx: {fontStyle: 'italic'},
            secondaryActions: [
              {
                icon: <DeleteOutline />,
                onClick: () => setSelectedMaterial(undefined),
                testId: 'cancel_adding_new_material'
              }
            ]
          }
        ]
      : []),
    ...existingMaterialsListItems
  ]

  return (
    <SettingsList
      items={listItems}
      testId="materials_list"
      headerContent={
        <>
          <TextField
            variant="outlined"
            fullWidth
            select
            label={t('materialsSettings.materialType')}
            value={materialType}
            onChange={(e) => {
              const newMaterialType = e.target.value as MaterialType
              setMaterialType(newMaterialType)
              if (selectedMaterial?.status === Status.New) {
                setSelectedMaterial({...selectedMaterial, type: newMaterialType})
              } else {
                setSelectedMaterial(undefined)
              }
            }}
            {...dataTestId('material_type_select')}
          >
            {getMaterialTypesList(t)}
          </TextField>
          <Box>
            <IconButton
              sx={{ml: 2}}
              disabled={isNewMaterial || !canChangeMaterials}
              onClick={() => {
                setSelectedMaterial({
                  name: '',
                  type: materialType,
                  source: MaterialSource.ProducedInPlant,
                  status: Status.New,
                  pxTrendCounters: []
                })
              }}
              {...dataTestId('add_new_material_button')}
            >
              <Add color="primary" />
            </IconButton>
          </Box>
        </>
      }
    />
  )
}
