import {Equipment} from '../../types/equipment'
import {SignalDetail, SignalFormData} from '../../types/signal'

export const parseEquipmentToAutocompleteOptions = (data: Equipment[]) =>
  data.map((equipment) => ({
    label: equipment.text ?? 'Unknown',
    id: equipment.id,
    path: getEquipmentPath(equipment)
  }))

export const getEquipmentPath = (equipment: Equipment): string => {
  if (!equipment) return '---'
  const {area, productionLine, department} = equipment
  return `${area.name} > ${productionLine.name} > ${department.name}`
}

const nullifyEmptyProps = (inObj: object) => {
  for (const [key, value] of Object.entries(inObj)) {
    if (typeof value === 'string') {
      inObj[key] = value || null
    } else if (typeof value === 'object' && value !== null) {
      nullifyEmptyProps(value as object)
    }
  }
}

export const parseSignalFormData = (signalFormData: SignalFormData): SignalDetail => {
  const autocompleteKeys = ['equipment', 'unit', 'type']
  const parsedData = {} as SignalDetail

  for (const key in signalFormData) {
    if (autocompleteKeys.includes(key)) {
      parsedData[key + 'Id'] = signalFormData[key]?.id ?? null
    } else {
      parsedData[key] = structuredClone(signalFormData[key])
    }
  }

  nullifyEmptyProps(parsedData)

  return parsedData
}
