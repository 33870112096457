import {Status} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Card} from '@hconnect/uikit/src/lib2'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../permissions/hooks'
import {useSearchParam} from '../../routing'
import {useMaterialStorageQuery} from '../hooks'

import {useSelectedStorage} from './SelectedStorageProvider'
import {StorageConfigurationForm} from './StorageConfigurationForm'

export const StorageCard = () => {
  const {t} = useTranslation()
  const [materialType] = useSearchParam('materialType')

  const {selectedStorage} = useSelectedStorage()
  if (!selectedStorage) {
    throw new Error('BUG: Storage should be selected to use this component')
  }

  const canChangeMaterialStorage = usePermission('CHANGE_MATERIAL_STORAGE')
  const isNewStorage = selectedStorage.status === Status.New
  const {data: storage} = useMaterialStorageQuery(isNewStorage ? undefined : selectedStorage.id)

  return (
    <Card
      headerContent={t(`materialsSettings.materialTypes.${materialType}`)}
      {...dataTestId('storage_card')}
    >
      <StorageConfigurationForm
        // if storage materialType changed outside of form we need to unmount it to prevent caching an old state
        key={isNewStorage ? 'new_storage' : `${storage?.materialType ?? ''}-${selectedStorage.id}`}
        isReadOnly={!canChangeMaterialStorage}
        storage={storage}
      />
    </Card>
  )
}
