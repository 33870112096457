import moment from 'moment'
import React, {useEffect, useMemo, useState} from 'react'
import {useSearchParams} from 'react-router-dom'

import {MonthlyView} from './MonthlyView'
import {SingleDayView} from './SingleDayView'

export const DailyEntry = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedDate = useMemo(() => searchParams.get('date'), [searchParams])
  const selectedKpi = useMemo(() => searchParams.get('kpiId'), [searchParams])
  const [showSingleDay, setShowSingleDay] = useState<boolean>()

  useEffect(() => {
    if (selectedDate && selectedKpi) {
      setShowSingleDay(true)
    }
  }, [selectedKpi, selectedDate])

  useEffect(() => {
    if (!selectedDate) {
      setSearchParams((params) => {
        params.set('date', moment().subtract(1, 'days').toISOString())
        return params
      })
    } else {
      const yesterday = moment().subtract(1, 'days').toISOString()
      if (moment(selectedDate).isAfter(yesterday)) {
        setSearchParams((params) => {
          params.set('date', yesterday)
          return params
        })
      }
    }
  }, [selectedDate, setSearchParams])

  if (showSingleDay) {
    return <SingleDayView close={() => setShowSingleDay(false)} />
  }

  return <MonthlyView selectSingleDay={() => setShowSingleDay(true)} />
}
