import type {AssetLink, AssetResponse, OperationModeResponse} from '@hconnect/common/types'
import {compact} from 'lodash'
import React from 'react'
import {Trans} from 'react-i18next'

import {InfoGroup, InfoGroupProps} from '../../../common/components'

interface AssetMoveInfoProps {
  asset: AssetResponse
  operationModes: OperationModeResponse[]
  silos: AssetLink[]
}

function cutStringTo40Chars(str: string) {
  return str.length <= 40 ? str : `${str.substring(0, 37)}...`
}

export const AssetMoveInfo: React.FC<AssetMoveInfoProps> = ({asset, operationModes, silos}) => {
  const operationModesStr = cutStringTo40Chars(operationModes.map((mode) => mode.name).join(', '))

  const infoPanels: InfoGroupProps['panels'] = compact([
    operationModes.length > 0 && {
      accentColor: 'warning',
      content: (
        <Trans
          i18nKey="assetsSettings.cantMoveAssetWithOperationModesDescription"
          values={{
            asset_name: asset.name,
            operation_mode_count: operationModes.length,
            operation_modes: operationModesStr
          }}
        />
      )
    },
    silos.length > 0 && {
      accentColor: 'warning',
      content: (
        <Trans
          i18nKey="assetsSettings.cantMoveAssetWithSilosDescription"
          values={{
            silo_count: silos.length
          }}
        />
      )
    }
  ])

  return (
    <InfoGroup
      description={
        <Trans i18nKey="assetsSettings.cantMoveAssetDescription" values={{name: asset.name}} />
      }
      panels={infoPanels}
    />
  )
}
