import {DataScope} from '../types'

export const checkPermissionAccessByDataScope = (
  countryId: string,
  plantId: string,
  permissionDataScope: Partial<Pick<DataScope, 'countryId' | 'plantId'>>
) => {
  if (plantId === permissionDataScope.plantId && countryId === permissionDataScope.countryId) {
    return true
  }
  if (!permissionDataScope.plantId && countryId === permissionDataScope.countryId) {
    return true
  }
  return false
}
