import {
  GLOBAL_MEASUREMENT_TYPES_QUERY_KEY,
  getGlobalMeasurementTypes
} from './getGlobalMeasurementTypes'
import {
  GLOBAL_MEASUREMENT_TYPES_CATEGORIES_QUERY_KEY,
  getGlobalMeasurementTypesCategories
} from './getGlobalMeasurementTypesCategories'
import {LIMS_MEASUREMENT_TYPES_QUERY_KEY, getLimsMeasurementTypes} from './getLimsMeasurementTypes'
import {
  MEASUREMENT_TYPES_MAPPINGS_QUERY_KEY,
  getMeasurementTypesMappings
} from './getMeasurementTypesMappings'

export const measurementsQueries = {
  [GLOBAL_MEASUREMENT_TYPES_CATEGORIES_QUERY_KEY]: getGlobalMeasurementTypesCategories,
  [GLOBAL_MEASUREMENT_TYPES_QUERY_KEY]: getGlobalMeasurementTypes,
  [LIMS_MEASUREMENT_TYPES_QUERY_KEY]: getLimsMeasurementTypes,
  [MEASUREMENT_TYPES_MAPPINGS_QUERY_KEY]: getMeasurementTypesMappings
}
