import {Iso8601} from '../../common/types'

export interface SelectedSilos {
  isOrderSpecified: boolean
  ids: number[]
}

export interface ScheduleItem {
  id: string
  start: Iso8601
  end: Iso8601
  assetOperationModeId: number
  assetId: number
  isShutdownAvailable: boolean
  isTransitionTime: boolean
  selectedSilos: SelectedSilos
  isFixed: boolean
}
