import type {IsoWeekDay} from '@hconnect/uikit/src/common/types'

export interface DefaultAssetOperationTime {
  id: number
  assetOperationModeId: number
  days: IsoWeekDay[]
  start: string // "00.00:00" - "00.23:45"
  end: string // "00.00:15" - "01.00:00"
}

export interface DefaultAssetsOperationTimes {
  [assetId: string]: DefaultAssetOperationTime[]
}

export type DefaultAssetOperationTimePayload = Omit<
  DefaultAssetOperationTime,
  'id' | 'assetOperationModeId'
>

export type NewDefaultAssetOperationTime = Omit<DefaultAssetOperationTime, 'id'> &
  Partial<DefaultAssetOperationTime>
