import {useQuery} from 'react-query'

import {Api} from '../../../../../api'

export const QueryKey = 'signal-type'

export type SignalType = {
  code: string
  name: string
  id: string
}

const parseToAutocompleteOptions = (data: SignalType[]) =>
  data.map((type) => ({label: type.name, id: type.id}))

export const useGetSignalTypes = () => {
  return useQuery(
    [QueryKey],
    async () => {
      const path = 'upm/signal-type'
      return (await Api.axiosInstance.get<SignalType[]>(path)).data
    },
    {
      staleTime: Infinity,
      select: parseToAutocompleteOptions
    }
  )
}
