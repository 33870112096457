import {HttpError} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {Api} from '../../../../../api'
import type {SignalDetail} from '../../../types/signal'

export const QueryKey = 'signal-detail'

export const useGetSignalDetail = (
  signalId: string,
  options?: UseQueryOptions<SignalDetail, AxiosError<HttpError>>
) => {
  return useQuery<SignalDetail, AxiosError<HttpError>>(
    [QueryKey, signalId],
    async () => {
      const path = `/upm/signal/${signalId}`
      return (await Api.axiosInstance.get<SignalDetail>(path)).data
    },
    {
      ...options
    }
  )
}
