import React from 'react'
import {useTranslation} from 'react-i18next'
import {Navigate} from 'react-router-dom'

import {usePermissionsQuery} from '../../permissions'
import {checkPermission} from '../../permissions/helpers/checkPermission'
import {getUrl, mainRoutes, ROUTES} from '../../routing'
import {useUrlParam} from '../../routing/hooks'
import {PageSkeleton} from '../components/skeletons'
import {usePlantDataFromJson} from '../hooks'
import {useLoginDetails} from '../providers/LoginProvider'
import {logger} from '../utils/logger'

const log = logger.context('DefaultPageRedirect')

export const DefaultPageRedirect = () => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {data: permissions} = usePermissionsQuery()
  const plantData = usePlantDataFromJson()
  const {loginDetails} = useLoginDetails()

  if (!loginDetails || !permissions) {
    return <PageSkeleton />
  }

  if (!plantData) {
    const errorMessage = t('error.pageNotFoundTitle')
    log.extras({plantCode}).critical(errorMessage)
    return <Navigate to={getUrl(ROUTES.BASE.NOT_FOUND.path, {})} replace />
  }

  const routeToRedirect = mainRoutes.find((route) =>
    checkPermission(route.permissionType, plantCode, permissions, [plantData.countryId])
  )

  if (!routeToRedirect) {
    const errorMessage = t('error.accessDenied')
    log.extras({plantCode}).critical(errorMessage)
    return <Navigate to={getUrl(ROUTES.BASE.NOT_AUTHORIZED.path, {})} replace />
  }

  return <Navigate to={getUrl(routeToRedirect.path, {plantCode})} replace />
}
