import {EventType} from '../enums'

export interface Comment {
  id: string
  kpiId: string
  kpiName: string
  userName: string
  commentDate: string
  commentParentId: string | null
  value: string
}

export interface Comments {
  janusComments: Comment[]
}

export interface Event {
  id: string
  eventType: EventType
  parentId?: string | null
  kpiName: string
  userName: string
  eventDate: string
  value: string
  version: string
}

export interface Events {
  events: Event[]
}
