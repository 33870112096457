import {MaterialStorage, Material, MaterialWithRecipes} from '@hconnect/common/types'
import {compact} from 'lodash'
import {Trans} from 'react-i18next'

import {InfoGroupProps} from '../../../common/components'

export function getIsMeasuredDisabled({
  materials,
  materialWithRecipes,
  allStorages
}: {
  materials?: Material[]
  materialWithRecipes?: MaterialWithRecipes
  allStorages?: MaterialStorage[]
}) {
  return !materials || !allStorages || !materialWithRecipes
}

export function getErrorsOnChangeIsMeasured({
  materials,
  storage,
  materialWithRecipes,
  allStorages
}: {
  materials?: Material[]
  storage?: MaterialStorage
  materialWithRecipes?: MaterialWithRecipes
  allStorages?: MaterialStorage[]
}) {
  return (newIsMeasured: boolean) => {
    // going from non measured to measured there is never an issue
    if (newIsMeasured) {
      return []
    }

    const attachedMaterial = materials?.find((material) => material.id === storage?.materialId)

    const storagesWithSameMaterial = allStorages?.filter(
      (e) => e.id !== storage?.id && e.materialId === storage?.materialId
    )

    const errorPanels: InfoGroupProps['panels'] = compact([
      attachedMaterial &&
        (materialWithRecipes?.products.length || 0) === 0 && {
          accentColor: 'warning',
          content: (
            <Trans
              i18nKey="storagesSettings.changeUnmeasuredNoMaterialCodeInfo"
              values={{
                material: attachedMaterial.name
              }}
            />
          )
        },
      attachedMaterial &&
        (!storagesWithSameMaterial || storagesWithSameMaterial.length > 0) && {
          accentColor: 'warning',
          content: (
            <Trans
              i18nKey="storagesSettings.changeUnmeasuredAttachedMaterialInOtherStorages"
              values={{
                material: attachedMaterial.name,
                other_storages: storagesWithSameMaterial?.map((storage) => storage.name).join(', ')
              }}
            />
          )
        },
      attachedMaterial &&
        attachedMaterial.pxTrendCounters !== undefined &&
        attachedMaterial.pxTrendCounters.length > 0 && {
          accentColor: 'warning',
          content: (
            <Trans
              i18nKey="storagesSettings.changeUnmeasuredAttachedMaterialHasPxtrend"
              values={{
                material: attachedMaterial.name
              }}
            />
          )
        },
      /// material storage do have email sync enabled
      storage?.emailSyncDetails && {
        accentColor: 'warning',
        content: (
          <Trans
            i18nKey="storagesSettings.changeUnmeasuredMaterialStorageHasEmailSyncEnabled"
            values={{
              storage: storage.name
            }}
          />
        )
      }
    ])

    return errorPanels
  }
}
