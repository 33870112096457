import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../../api/mutations'
import {invalidateSettingsQuery} from '../../../../common/providers/ApiInterface'
import {ErrorResponse} from '../../../../common/types'
import {notifyIfErrorMessage} from '../../../../common/utils/errorHandling'

export const useUpdateDefaultAssetOperationTime = () => {
  const {t} = useTranslation()
  const {notify} = useNotification()
  const raiseError = useErrorHandler()

  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.updateDefaultAssetOperationTime>[0]
  >(mutations.updateDefaultAssetOperationTime, {
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSuccess: () => {
      notify('success', t('success.editStandardOperationTime'))
    },
    onSettled: () => {
      invalidateSettingsQuery('defaultAssetsOperationTimes')
    }
  })
}
