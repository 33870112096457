import React, {useContext, useState, useMemo} from 'react'

import type {Material, SelectedMaterial} from '../types'

interface SelectedMaterialState {
  selectedMaterial: SelectedMaterial | undefined
  setSelectedMaterial: React.Dispatch<React.SetStateAction<SelectedMaterial | undefined>>
}

const SelectedMaterialContext = React.createContext<SelectedMaterialState | undefined>(undefined)

export const useSelectedMaterial = () => {
  const context = useContext(SelectedMaterialContext)
  if (!context)
    throw new Error('Cannot use selected material context outside of a context provider')
  return context
}

export const SelectedMaterialProvider = ({
  materials,
  children
}: {
  materials: Material[]
  children: React.ReactNode
}) => {
  const [_selectedMaterial, _setSelectedMaterial] = useState<SelectedMaterial>()

  const memoizedState = useMemo(() => {
    let selectedMaterial = _selectedMaterial
    if (selectedMaterial && selectedMaterial['id']) {
      selectedMaterial = materials.find((material) => material.id === selectedMaterial?.['id'])
    }
    return {selectedMaterial, setSelectedMaterial: _setSelectedMaterial}
  }, [_selectedMaterial, _setSelectedMaterial, materials])

  return (
    <SelectedMaterialContext.Provider value={memoizedState}>
      {children}
    </SelectedMaterialContext.Provider>
  )
}
