import {dataTestId} from '@hconnect/uikit'
import {Typography, Avatar, Box, Stack} from '@mui/material'
import moment from 'moment'

import {EventType} from '../../enums'
import {getStringAvatar} from '../../helpers'
import {Event} from '../../types'

import {EventVersion} from './EventVersion'

export const KpiComment = ({event}: {event: Event}) => {
  const {userName, value, eventDate, eventType, version, id} = event

  return (
    <Stack gap={1} sx={{cursor: 'pointer'}} {...dataTestId(`kpi-comment-${id}`)}>
      {userName && (
        <Box display="flex" flexDirection="row" gap={1} alignItems="center">
          <Avatar {...getStringAvatar(userName)} alt={userName} />
          <Typography variant="caption">{`${userName} \u00b7 ${moment
            .utc(eventDate)
            .local()
            .format('YYYY-MM-DD HH:mm')}`}</Typography>
        </Box>
      )}
      <Stack gap={2} sx={{paddingLeft: (theme) => theme.spacing(4)}}>
        {eventType === EventType.Comment && <Typography variant="body1">{value}</Typography>}
        {eventType === EventType.Version && <EventVersion version={version} value={value} />}
      </Stack>
    </Stack>
  )
}
