import {useSettingsQuery} from '../../common/providers'
import {useUrlParam} from '../../routing'

export const useSensors = (enabled: boolean) => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('sensors', [plantCode], {
    enabled,
    select: ({plantSensors}) =>
      plantSensors.map(({sensorName, description}) => ({name: sensorName, description}))
  })
}
