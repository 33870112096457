import {useSettingsQuery} from '../../../common/providers/ApiInterface'
import {useUrlParam} from '../../../routing'
import {getBaseLoadPowerConsumptionFromAssets} from '../../utils/assetSelectors'
getBaseLoadPowerConsumptionFromAssets

export const useBaseLoadQuery = () => {
  const plantCode = useUrlParam('plantCode')
  const {data: baseLoad} = useSettingsQuery('assets', [plantCode], {
    select: getBaseLoadPowerConsumptionFromAssets
  })

  return baseLoad ?? 0
}
