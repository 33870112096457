import type {OperationModeResponse} from '@hconnect/common/types'
import {compact} from 'lodash'
import React from 'react'
import {Trans} from 'react-i18next'

import {InfoGroup, InfoGroupProps} from '../../../common/components'
import type {ScheduleItem} from '../../../common/types'
import type {Recipe} from '../../types'

interface RecipeDeleteInfoProps {
  scheduleItems: ScheduleItem[]
  recipe: Recipe
  operationModes: OperationModeResponse[]
}

function cutStringTo40Chars(str: string) {
  return str.length <= 40 ? str : `${str.substring(0, 37)}...`
}

export const RecipeDeleteInfo: React.FC<RecipeDeleteInfoProps> = ({
  scheduleItems,
  recipe,
  operationModes
}) => {
  const operationModesStr = cutStringTo40Chars(operationModes.map((mode) => mode.name).join(', '))

  const panels: InfoGroupProps['panels'] = compact([
    operationModes.length > 0 && {
      accentColor: 'warning',
      content: (
        <Trans
          i18nKey="materialsSettings.deleteRecipeAssignedOperationModes"
          values={{
            operation_mode_count: operationModes.length,
            operation_modes: operationModesStr
          }}
        />
      )
    },
    scheduleItems.length > 0 && {
      accentColor: 'warning',
      content: (
        <Trans
          i18nKey="assetsSettings.deleteOperationModesAssignedScheduleItems"
          values={{
            number: scheduleItems.length
          }}
        />
      )
    }
  ])

  return (
    <InfoGroup
      description={
        <Trans i18nKey="materialsSettings.deleteRecipeConfirmation" values={{name: recipe.name}} />
      }
      panels={panels}
    />
  )
}
