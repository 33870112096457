import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../../api/mutations'
import {settingsQueryClient} from '../../../../common/providers/QueryProvider'
import {
  getSettingsQueryData,
  invalidateSettingsQuery
} from '../../../../common/providers/ApiInterface'
import type {ErrorResponse} from '../../../../common/types'
import {notifyIfErrorMessage} from '../../../../common/utils/errorHandling'
import type {Recipe, MaterialWithRecipes} from '../../../types'

export const useAddMaterialRecipe = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<
    Recipe,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.addMaterialRecipe>[0]
  >(mutations.addMaterialRecipe, {
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSuccess: (recipe, variables) => {
      notify('success', t('success.addRecipe'))
      const prevMaterialWithRecipes = getSettingsQueryData('materialWithRecipes', [
        variables.plantCode,
        variables.materialId
      ])
      if (!prevMaterialWithRecipes) {
        throw new Error('BUG: material data should be available before adding a recipe')
      }
      const newMaterialWithRecipe: MaterialWithRecipes = {
        ...prevMaterialWithRecipes,
        recipes: [...prevMaterialWithRecipes.recipes, recipe]
      }
      void settingsQueryClient.setQueryData(
        ['materialWithRecipes', variables.plantCode, variables.materialId],
        newMaterialWithRecipe
      )
      invalidateSettingsQuery('materialsRecipes')
    }
  })
}
