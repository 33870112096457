import type {OperationModeResponse} from '@hconnect/common/types'
import {roundTo15MinIntervalEnd} from '@hconnect/common/utils'
import {dataTestId} from '@hconnect/uikit'
import {
  MenuItem,
  Stack,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  FormHelperText
} from '@mui/material'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import moment, {Moment} from 'moment-timezone'
import React, {useCallback, useEffect, useMemo} from 'react'
import {Controller, UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {NumberInput} from '../../../../common/components'
import {useCurrentTime} from '../../../../common/hooks/useCurrentTime'
import {useLatestData} from '../../../../common/hooks/useLatestData'
import {minValidator, requiredValidator} from '../../../../common/utils'
import type {
  AssetStandardOperationTimeData,
  NewAssetStandardOperationTimeData
} from '../../../types'
import {
  StandartOperationTimeEnds,
  StandartOperationTimeRepetition
} from '../../../types/standardOperationTimes'
import {getDayOfWeek} from '../../../utils/getDayOfWeek'

export type StandardOperationTimeAddOrEditDialogEditingProps = {
  operationModes: OperationModeResponse[]
} & (
  | {
      type: 'add'
    }
  | {type: 'edit'; standardOperationTime: AssetStandardOperationTimeData}
)

export type StandardOperationTimeAddOrEditDialogContentProps =
  StandardOperationTimeAddOrEditDialogEditingProps &
    Pick<
      UseFormReturn<NewAssetStandardOperationTimeData, void, undefined>,
      'watch' | 'control' | 'trigger'
    >

const generateTimeSteps = (start: Moment, end: Moment, rangeMin = 15) => {
  const values: Moment[] = []
  while (start.isBefore(end)) {
    values.push(start)
    start = start.clone().add(rangeMin, 'minutes')
  }
  return values
}

const getStringToMomentTransformer =
  (timezone_id: string, onChange: (...event: any[]) => void) =>
  (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const momentValue = moment(e.target.value).tz(timezone_id) // Parse ISO string to moment
    onChange(momentValue)
  }

export const StandardOperationTimeAddOrEditDialogContent: React.FC<
  StandardOperationTimeAddOrEditDialogContentProps
> = ({type, operationModes, trigger, watch, control}) => {
  const {t} = useTranslation()
  const {timezone_id} = useLatestData()

  const currentTime = useCurrentTime({timezoneId: timezone_id})
  const currentTimeRoundedToNearest15Min = useMemo(
    () => roundTo15MinIntervalEnd(currentTime).toISOString(),
    [currentTime]
  )

  const assetOperationModesIds = useMemo(
    () => operationModes.map((operationMode) => operationMode.id),
    [operationModes]
  )

  const validateStartEnd = useCallback(
    ({start, end}: {end: Moment; start: Moment}) => {
      return end.isAfter(start)
        ? undefined
        : t('assetsSettings.standardOperationTimes.validation.endAfterStart')
    },
    [t]
  )

  const freq = watch('calendarEvent.recurrenceRule.freq')
  const ends = watch('calendarEvent.recurrenceRule.ends')

  const start = watch('calendarEvent.start')
  const end = watch('calendarEvent.end')

  useEffect(() => {
    void trigger()
  }, [start, end, trigger])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box>
        <Stack direction="column" spacing={2}>
          <Controller
            name="operationModeId"
            control={control}
            rules={requiredValidator(t)}
            render={({field: {ref, value, onChange}, fieldState: {error}}) => (
              <TextField
                select
                label={t('assetsSettings.operationMode')}
                fullWidth
                inputRef={ref}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
                {...dataTestId('operation_mode_select')}
              >
                {assetOperationModesIds.map((id) => (
                  <MenuItem key={id} value={id} {...dataTestId('operation_mode_select_item')}>
                    {operationModes.find((operationMode) => operationMode.id === id)?.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="calendarEvent.start"
            control={control}
            rules={{
              ...requiredValidator(t),
              validate: (value, otherFields) => {
                const validateStartEndError = validateStartEnd({
                  start: value,
                  end: otherFields.calendarEvent.end
                })
                if (validateStartEndError) {
                  return validateStartEndError
                }
                if (type !== 'edit' && value.isBefore(currentTimeRoundedToNearest15Min)) {
                  return t(
                    'assetsSettings.standardOperationTimes.validation.startMustBeAfterCurrentTime'
                  )
                }
                return undefined
              }
            }}
            render={({field: {ref, value, onChange}, fieldState: {error}}) => (
              <Box pb={1}>
                <FormControl ref={ref} error={!!error}>
                  <Stack direction="row" spacing={3}>
                    <DatePicker
                      value={value}
                      onChange={onChange}
                      minDate={moment().tz(timezone_id).startOf('day')}
                      maxDate={moment().tz(timezone_id).add(1, 'years').endOf('year')}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          error={!!error}
                          inputProps={{...props.inputProps, readOnly: true}}
                        />
                      )}
                      label={t('common.startDate')}
                      {...dataTestId('standard_operation_time_start_date_select')}
                    />
                    <TextField
                      sx={{width: ({spacing}) => spacing(30)}}
                      select
                      error={!!error}
                      label={t('common.startTime')}
                      value={value.toISOString()}
                      onChange={getStringToMomentTransformer(timezone_id, onChange)}
                      {...dataTestId('standard_operation_time_start_time_select')}
                    >
                      {generateTimeSteps(
                        value.clone().startOf('day'),
                        value.clone().startOf('day').add(1, 'day')
                      ).map((value) => (
                        <MenuItem
                          key={value.toISOString()}
                          value={value.toISOString()}
                          {...dataTestId('standard_operation_time_start_time_select_item')}
                        >
                          {value.format('HH:mm')}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              </Box>
            )}
          />
          <Controller
            name="calendarEvent.end"
            control={control}
            rules={{
              ...requiredValidator(t),
              validate: (value, otherFields) =>
                validateStartEnd({end: value, start: otherFields.calendarEvent.start})
            }}
            render={({field: {ref, value, onChange}, fieldState: {error}}) => (
              <Box pb={1}>
                <FormControl ref={ref} error={!!error}>
                  <Stack direction="row" spacing={3}>
                    <DatePicker
                      value={value}
                      onChange={onChange}
                      minDate={moment().tz(timezone_id).startOf('day')}
                      maxDate={moment().tz(timezone_id).add(1, 'years').endOf('year')}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          error={!!error}
                          inputProps={{...props.inputProps, readOnly: true}}
                        />
                      )}
                      label={t('common.endDate')}
                      {...dataTestId('standard_operation_time_end_date_select')}
                    />
                    <TextField
                      sx={{width: ({spacing}) => spacing(30)}}
                      select
                      label={t('common.endTime')}
                      value={value.toISOString()}
                      error={!!error}
                      onChange={getStringToMomentTransformer(timezone_id, onChange)}
                      {...dataTestId('standard_operation_time_end_time_select')}
                    >
                      {generateTimeSteps(
                        value.clone().startOf('day'),
                        value.clone().startOf('day').add(1, 'day')
                      ).map((value) => (
                        <MenuItem
                          key={value.toISOString()}
                          value={value.toISOString()}
                          {...dataTestId('standard_operation_time_end_time_select_item')}
                        >
                          {value.format('HH:mm')}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              </Box>
            )}
          />
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>{t('common.interval')}</Typography>
            <Controller
              name="calendarEvent.recurrenceRule.interval"
              control={control}
              rules={{...requiredValidator(t), ...minValidator(t, 1)}}
              render={({field: {ref, value, onChange}, fieldState: {error}}) => (
                <NumberInput
                  inputRef={ref}
                  step={1}
                  min={1}
                  onChange={onChange}
                  value={value}
                  sx={{
                    width: ({spacing}) => spacing(8)
                  }}
                  error={error?.message}
                  dataTestId={'standart_operation_time_internal_input'}
                />
              )}
            />
            <Controller
              name="calendarEvent.recurrenceRule.freq"
              control={control}
              rules={requiredValidator(t)}
              render={({field: {ref, value, onChange}, fieldState: {error}}) => (
                <TextField
                  sx={{width: ({spacing}) => spacing(30)}}
                  select
                  label={t('assetsSettings.standardOperationTimes.frequency')}
                  inputRef={ref}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  {...dataTestId('standard_operation_time_frequency_select')}
                >
                  {[
                    StandartOperationTimeRepetition.DAILY,
                    StandartOperationTimeRepetition.WEEKLY,
                    StandartOperationTimeRepetition.MONTHLY
                  ].map((period) => (
                    <MenuItem
                      key={period}
                      value={period}
                      {...dataTestId('standard_operation_time_frequency_select_item')}
                    >
                      {t(`assetsSettings.standardOperationTimes.schedulingPeriod.${period}`)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Stack>
          {freq === StandartOperationTimeRepetition.WEEKLY && (
            <Stack direction="row" spacing={2} alignItems="center">
              {new Array(7).fill(0).map((_, index) => (
                <Controller
                  key={index}
                  name="calendarEvent.recurrenceRule.daysOfWeek"
                  control={control}
                  render={({field: {ref, value, onChange}}) => {
                    const currentValue = value || {}
                    return (
                      <Button
                        color="primary"
                        ref={ref}
                        variant={(currentValue || {})[index] ? 'contained' : 'outlined'}
                        {...dataTestId(`standard_operation_time_weekly_day_${index}_button`)}
                        onClick={() => {
                          onChange({...currentValue, [index]: !(currentValue || {})[index]})
                        }}
                      >
                        {getDayOfWeek(index)}
                      </Button>
                    )
                  }}
                />
              ))}
            </Stack>
          )}
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>{t('assetsSettings.standardOperationTimes.ends')}</Typography>
            <Controller
              name="calendarEvent.recurrenceRule.ends"
              control={control}
              render={({field: {ref, value, onChange}, fieldState: {error}}) => (
                <TextField
                  sx={{width: ({spacing}) => spacing(30)}}
                  select
                  inputRef={ref}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  {...dataTestId('standard_operation_time_ends_select')}
                >
                  {[StandartOperationTimeEnds.after, StandartOperationTimeEnds.on].map((value) => (
                    <MenuItem
                      key={value}
                      value={value}
                      {...dataTestId('standard_operation_time_ends_select_item')}
                    >
                      {t(`assetsSettings.standardOperationTimes.endsValues.${value}`)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            {ends === StandartOperationTimeEnds.after && (
              <>
                <Controller
                  name="calendarEvent.recurrenceRule.count"
                  control={control}
                  rules={{...requiredValidator(t), ...minValidator(t, 1)}}
                  render={({field: {ref, value, onChange}, fieldState: {error}}) => (
                    <NumberInput
                      inputRef={ref}
                      step={1}
                      onChange={onChange}
                      value={value}
                      sx={{
                        width: ({spacing}) => spacing(8)
                      }}
                      error={error?.message}
                      dataTestId={'standart_operation_time_count_input'}
                    />
                  )}
                />
                <Typography variant="captionBold">
                  {t('assetsSettings.standardOperationTimes.occurrences')}
                </Typography>
              </>
            )}
            {ends === StandartOperationTimeEnds.on && (
              <>
                <Controller
                  name="calendarEvent.recurrenceRule.until"
                  control={control}
                  rules={{
                    ...requiredValidator(t),
                    validate: (value, otherFields) =>
                      value.isSameOrBefore(otherFields.calendarEvent.start)
                        ? t(
                            'assetsSettings.standardOperationTimes.validation.untilMustBeAfterStart'
                          )
                        : undefined
                  }}
                  render={({field: {ref, value, onChange}, fieldState: {error}}) => (
                    <DatePicker
                      value={value}
                      onChange={(e: any) => {
                        onChange((e as Moment).clone().endOf('day') as any)
                      }}
                      minDate={start.clone().startOf('day')}
                      maxDate={moment().tz(timezone_id).add(1, 'years').endOf('year')}
                      inputRef={ref}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          error={!!error}
                          helperText={error?.message}
                          inputProps={{...props.inputProps, readOnly: true}}
                        />
                      )}
                      label={t('common.date')}
                      {...dataTestId('standard_operation_time_until_select')}
                    />
                  )}
                />
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    </LocalizationProvider>
  )
}
