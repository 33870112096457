import {StructureNode, UpmEntityType} from '../types'

export type PathNode = Omit<StructureNode, 'children'>

// no need to optimize, as we will switch to tree view later anyways
export const findEntityPath = (
  root: StructureNode,
  targetId: string,
  targetType: UpmEntityType
): {node: StructureNode | null; path: PathNode[]} => {
  const queue: Array<{node: StructureNode; path: PathNode[]}> = [
    {node: structuredClone(root), path: []}
  ]

  while (queue.length > 0) {
    const {node, path} = queue.shift() as {node: StructureNode; path: PathNode[]}

    path.push({
      id: node.id,
      name: node.name,
      signalCount: node.signalCount,
      entityType: node.entityType
    })

    // We now we requested the correct plant but there is GUID in response
    if (node.id === targetId || (node.entityType === targetType && targetType === 'Plant')) {
      return {node, path}
    }

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        queue.push({node: child, path: structuredClone(path)})
      }
    }
  }

  return {node: null, path: []}
}
