import {Localization} from '@hconnect/uikit'

import {logger} from '../modules/common/utils/logger'

export const supportedLanguages = [
  'en-US',
  'de-DE',
  'ru',
  'it',
  'fr',
  'cs',
  'hu',
  'nl',
  'se',
  'es',
  'ro',
  'no',
  'pl'
]
const log = logger.context('localization', 'loader')

const isDevelopment = process.env.NODE_ENV === 'development'

const loader = async (filename: string): Promise<Record<string, unknown> | undefined> => {
  if (isDevelopment) {
    console.info(`Load Translation for plant settings app [${filename}]`)
  }
  try {
    return (await import(`./${filename}`)).default
  } catch (err) {
    if (isDevelopment) {
      console.error(`Could not load Translation for plant settings app [${filename}]`, err)
    }
    log.tags({filename, message: (err as Error)?.message}).error('Could not load translation')
    throw err
  }
}

export const initLocalization = () => {
  void Localization({
    fallbackLng: 'en-US',
    compatibilityJSON: undefined,
    supportedLngs: [...supportedLanguages],
    backend: {
      loader
    },
    debug: isDevelopment
  })
}
