import {FormControlLabel, Switch as MUISwitch} from '@mui/material'
import type {FormControlLabelProps} from '@mui/material'
import React from 'react'

interface SwitchProps
  extends Pick<FormControlLabelProps, 'inputRef' | 'disabled' | 'onBlur' | 'onKeyDown' | 'sx'> {
  label?: string | React.ReactElement
  ['data-test-id']?: string
  value: boolean
  onClick?: React.MouseEventHandler<HTMLLabelElement>
  onChange?: (checked: boolean) => void | boolean | Promise<boolean>
}

export const Switch = ({label, value, onChange, ...otherProps}: SwitchProps) => {
  return (
    <FormControlLabel
      control={<MUISwitch />}
      label={label}
      checked={value}
      onChange={(e, checked) => onChange?.(checked)}
      sx={{width: 'fit-content'}}
      {...otherProps}
    />
  )
}
