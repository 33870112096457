import {MaterialType} from '@hconnect/common/types'

export type UrlParams = Record<
  'plantCode' | 'page' | '*' | 'assetType' | 'assetId',
  string | undefined
>

export type SearchParams = typeof searchParams
export const searchParams = {
  materialType: [
    MaterialType.AlternativeFuel,
    MaterialType.Cement,
    MaterialType.Clinker,
    MaterialType.ConventionalFuel,
    MaterialType.SemiFinishedProduct,
    MaterialType.RawMaterial,
    MaterialType.RawMeal,
    MaterialType.OutOfSpec
  ],
  measurementTypesCategory: [] as string[]
}
