import type {Material} from './types'

/**
 * The function to find material with specific product
 * one product could be attached only to one material
 * @param productId
 * @param materials
 */
export const getMaterialWithAttachedProduct = (productId: number, materials?: Material[]) => {
  return (materials ?? []).find((material) =>
    material.products.some((product) => product.id === productId)
  )
}
