import {AxiosError} from 'axios'
import React from 'react'
import {useErrorHandler} from 'react-error-boundary'
import {DefaultOptions, QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'

const defaults: DefaultOptions = {
  queries: {
    retry: 3,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  }
}
export const settingsQueryClient = new QueryClient({defaultOptions: defaults})

export const QueryProvider = ({children}: {children: React.ReactNode}) => {
  const raiseError = useErrorHandler()

  const onError = (err) => {
    const axiosError = err as AxiosError<Record<string, string>>
    const response = axiosError.response

    if (!response) {
      raiseError(err)
      return
    }

    const statusCode = response?.status
    const statusText = response?.data?.status ?? response?.statusText
    const url = response?.request?.responseURL

    const message = [
      `Error occurred querying ${url}`,
      `${statusCode}: ${statusText}`,
      response?.data?.detail
    ].join('\n')
    raiseError(new Error(message))
  }

  settingsQueryClient.setDefaultOptions({
    queries: {...defaults.queries, onError},
    mutations: {onError}
  })

  return (
    <QueryClientProvider client={settingsQueryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
