import {MeasurementType, MaterialStorage, MaterialStorageDTO} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {MenuItem, TextField, Grid} from '@mui/material'
import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {Trans, useTranslation} from 'react-i18next'

import {InfoGroup, NumberInput, Switch} from '../../common/components'
import {useConfirmDialog} from '../../common/providers'
import {submitOnBlurAndEnterProps, minValidator, requiredValidator} from '../../common/utils'
import {useMaterialWithRecipesQuery, useMaterialsQuery} from '../../materials/hooks'
import {useSearchParam, useUrlParam} from '../../routing'
import {useAddStorage, useEditStorage, useMaterialStorageListQuery} from '../hooks'

import {useSelectedStorage} from './SelectedStorageProvider'
import {getErrorsOnChangeIsMeasured, getIsMeasuredDisabled} from './utils/storageConfigurationUtils'

interface StorageConfigurationFormProps {
  isReadOnly: boolean
  storage: MaterialStorage | undefined
}

export const StorageConfigurationForm = ({isReadOnly, storage}: StorageConfigurationFormProps) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const [materialType] = useSearchParam('materialType')
  const {setSelectedStorage} = useSelectedStorage()
  const {data: materials} = useMaterialsQuery(materialType)

  const {data: allStorages} = useMaterialStorageListQuery(materialType)

  const {mutate: addStorage} = useAddStorage()
  const {mutate: editStorage} = useEditStorage()

  const {openDialog} = useConfirmDialog()

  const defaultValues: MaterialStorageDTO = storage ?? {
    name: '',
    materialType,
    isOptimized: true,
    isMeasured: true,
    measurementType: MeasurementType.Auto,
    capacity: 0,
    deadStock: 0,
    minimumStockLevel: 0,
    minimumWeekendStockLevel: 0,
    materialId: null
  }
  const {
    handleSubmit,
    control,
    reset,
    formState: {isDirty, dirtyFields}
  } = useForm<MaterialStorageDTO>({mode: 'onChange', shouldFocusError: false, defaultValues})

  const {data: materialWithRecipes} = useMaterialWithRecipesQuery(storage?.materialId ?? undefined)
  const isMeasuredDisabled = getIsMeasuredDisabled({
    materials,
    materialWithRecipes,
    allStorages
  })

  const getChangeIsMeasuredErrors = getErrorsOnChangeIsMeasured({
    materials,
    materialWithRecipes,
    allStorages,
    storage
  })

  const switchIsMeasured = (newIsMeasured: boolean) => {
    const errorPanels = getChangeIsMeasuredErrors(newIsMeasured)

    if (errorPanels.length === 0) {
      // there are no issues with switching is measured
      return true
    }

    openDialog({
      testId: 'material_change_is_measured_not_possible_dialog',
      title: t('storagesSettings.changeIsMeasuredNotPossible'),
      additionalContent: (
        <InfoGroup
          description={<Trans i18nKey="storagesSettings.changeIsMeasuredNotPossibleDescription" />}
          panels={errorPanels}
        />
      ),
      showCancelButton: false,
      mainAction: {
        text: t('common.ok')
      }
    })
    return false
  }

  const submit = handleSubmit((values) => {
    if (!isDirty) {
      return
    }
    if (!storage) {
      return addStorage(
        {plantCode, dto: values},
        {
          onError: () => {
            reset()
          },
          onSuccess: (storage) => {
            setSelectedStorage({status: storage.status, id: storage.id})
          }
        }
      )
    }
    const keys = Object.keys(dirtyFields).map((key) => key as keyof MaterialStorageDTO)
    keys.forEach((key) => {
      editStorage(
        {plantCode, id: storage.id, dto: values, key},
        {
          onError: () => reset(),
          onSuccess: (storage) => {
            setSelectedStorage({status: storage.status, id: storage.id})
            reset(storage)
          }
        }
      )
    })
  })

  return (
    <Grid spacing={2} sx={{width: (theme) => theme.spacing(56)}} container>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="name"
          rules={requiredValidator(t)}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <TextField
              fullWidth
              variant="outlined"
              disabled={isReadOnly}
              label={t('storagesSettings.storageName')}
              inputRef={ref}
              value={value}
              onChange={onChange}
              helperText={error?.message}
              error={Boolean(error?.message)}
              {...submitOnBlurAndEnterProps(submit)}
              {...dataTestId('storage_name_input')}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="materialId"
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <TextField
              fullWidth
              select
              inputRef={ref}
              variant="outlined"
              label={t('storagesSettings.storedMaterial')}
              value={value ?? ''}
              onChange={(e) => onChange(e.target.value !== '' ? e.target.value : null)}
              helperText={error?.message}
              error={Boolean(error?.message)}
              disabled={isReadOnly}
              {...submitOnBlurAndEnterProps(submit)}
              {...dataTestId('stored_material_select')}
            >
              {/* TODO extract to component? */}
              <MenuItem key={'no_material'} value="" {...dataTestId('material_select_item')}>
                {t('storagesSettings.noSelectedMaterial')}
              </MenuItem>
              {materials?.map((material) => (
                <MenuItem
                  key={material.id}
                  value={material.id}
                  {...dataTestId('material_select_item')}
                >
                  {material.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="capacity"
          rules={{...requiredValidator(t), ...minValidator(t, 0)}}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <NumberInput
              inputRef={ref}
              label={t('storagesSettings.capacity')}
              step={10}
              onChange={onChange}
              value={value}
              error={error?.message}
              disabled={isReadOnly}
              {...submitOnBlurAndEnterProps(submit)}
              dataTestId={'capacity_input'}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="deadStock"
          rules={{...requiredValidator(t), ...minValidator(t, 0)}}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <NumberInput
              inputRef={ref}
              label={t('storagesSettings.deadStock')}
              step={10}
              onChange={onChange}
              value={value}
              error={error?.message}
              disabled={isReadOnly}
              {...submitOnBlurAndEnterProps(submit)}
              dataTestId={'dead_stock_input'}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="minimumStockLevel"
          rules={{...requiredValidator(t), ...minValidator(t, 0)}}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <NumberInput
              inputRef={ref}
              label={t('storagesSettings.minStockLevel')}
              step={10}
              onChange={onChange}
              value={value}
              error={error?.message}
              disabled={isReadOnly}
              {...submitOnBlurAndEnterProps(submit)}
              dataTestId={'min_stock_lvl_input'}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="minimumWeekendStockLevel"
          rules={{...requiredValidator(t), ...minValidator(t, 0)}}
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <NumberInput
              inputRef={ref}
              label={t('storagesSettings.minStockLevelWeekend')}
              step={10}
              onChange={onChange}
              value={value}
              error={error?.message}
              disabled={isReadOnly}
              {...submitOnBlurAndEnterProps(submit)}
              dataTestId={'min_stock_lvl_weekend_input'}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="isOptimized"
          render={({field: {ref, value, onChange}}) => (
            <Switch
              label={t('storagesSettings.optimized')}
              inputRef={ref}
              value={value}
              onChange={onChange}
              disabled={isReadOnly}
              {...submitOnBlurAndEnterProps(submit)}
              {...dataTestId('is_optimized_switch')}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="isMeasured"
          render={({field: {ref, value, onChange}}) => (
            <Switch
              label={t('storagesSettings.measured')}
              inputRef={ref}
              value={value}
              onClick={(e) => {
                if (!switchIsMeasured(!value)) {
                  e.preventDefault()
                }
              }}
              onChange={onChange}
              disabled={isReadOnly || isMeasuredDisabled}
              {...submitOnBlurAndEnterProps(submit)}
              {...dataTestId('is_measured_switch')}
            />
          )}
        />
      </Grid>
      {storage && storage.isMeasured && (
        <Grid xs={12} sx={{mt: 4}}>
          <TextField
            fullWidth
            label={t('storagesSettings.unitOfMeasurement')}
            value={t(`storagesSettings.unitOfMeasurementItems.${storage.unitOfMeasurement}`)}
            {...dataTestId('unit_of_measurement_input')}
            disabled={true}
          />
        </Grid>
      )}
    </Grid>
  )
}
