/**
 * @author Martin Petran
 */
import {mergeSx} from '@hconnect/uikit/src/lib2'
import {Autocomplete, SxProps, Theme} from '@mui/material'
import {KeyboardEvent} from 'react'
import {Controller} from 'react-hook-form'
import type {Control, FieldValues, Path} from 'react-hook-form'

import {submitOnBlurAndEnterProps} from '../../../common/utils'

import {BaseTextField} from './BaseTextField'

interface ControlledAutocompleteProps<
  TOption extends {id: string; label: string},
  TField extends FieldValues
> {
  control: Control<TField>
  formDataName: Path<TField>
  options: TOption[]
  onSubmit?: () => Promise<void>
  label: string
  rules?: any
  disableClearable?: boolean
  disabled?: boolean
  helperText?: string
  onAddCustomValue?: (newValue: string) => void
  'data-test-id'?: string
  sx?: SxProps<Theme>
}

export const ControlledAutocomplete = <
  TOption extends {id: string; label: string},
  TField extends FieldValues
>({
  control,
  formDataName,
  options,
  onSubmit,
  label,
  rules,
  disableClearable = true,
  disabled = false,
  helperText,
  onAddCustomValue,
  'data-test-id': dataTestId,
  sx
}: ControlledAutocompleteProps<TOption, TField>) => (
  <Controller
    name={formDataName}
    control={control}
    rules={rules}
    render={({field: {ref, onChange, value}, fieldState: {invalid, error}}) => (
      <Autocomplete
        fullWidth
        value={value}
        disabled={disabled}
        disableClearable={disableClearable}
        onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
          onAddCustomValue?.((event.target as HTMLInputElement).value)
        }}
        onChange={(e, data) => onChange(data)}
        options={options}
        data-test-id={dataTestId}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        {...(onSubmit && submitOnBlurAndEnterProps(onSubmit))}
        componentsProps={{
          paper: {
            elevation: 12,
            sx(theme) {
              return {
                background: theme.palette.grey[50]
              }
            }
          }
        }}
        sx={mergeSx(
          {
            '& .MuiInputBase-root': {
              height: '52px',
              borderRadius: '4px',
              background: '#fbfbfc',
              boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.06)',
              mb: helperText && 1,
              '&.Mui-focused': {
                background: '#fbfbfc'
              }
            }
          },
          sx
        )}
        renderInput={(params) => (
          <BaseTextField
            {...params}
            label={label}
            inputRef={ref}
            helperText={invalid ? (error?.message ? error.message : 'Invalid value') : helperText}
            error={invalid}
          />
        )}
      />
    )}
  />
)
