/**
 * helper function which returns an object with onBlur onKeyDown callback for input use
 * the return object should be spreaded to Textfield component
 */
export const submitOnBlurAndEnterProps = <F extends () => void | Promise<void>>(cb: F) => ({
  onBlur: () => void cb(),
  onKeyDown: (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      void cb()
    }
  }
})
