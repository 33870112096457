import {AssetType} from '@hconnect/common/types'
import {Navigate} from 'react-router-dom'

import {PLANT_ROUTES, getUrl, useUrlParam} from '@settings/modules/routing'

export const DefaultAssetTypeRedirect: React.FC = () => {
  const plantCode = useUrlParam('plantCode')

  return (
    <Navigate
      to={getUrl(PLANT_ROUTES.ASSETS.ASSET_TYPE.path, {plantCode, assetType: AssetType.CementMill})}
    />
  )
}
