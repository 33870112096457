import {Status} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {Add} from '@mui/icons-material'
import {Stack, Button} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../../permissions'
import {useMaterialStorageQuery} from '../../hooks/queries'
import {useSelectedStorage} from '../SelectedStorageProvider'

import {EmailParserConnectionForm} from './EmailParserConnectionForm'

export const EmailParserCard = () => {
  const {t} = useTranslation()
  const [isNewConnection, setIsNewConnection] = useState<boolean>(false)

  const canChangeStorage = usePermission('CHANGE_MATERIAL_STORAGE')

  const {selectedStorage} = useSelectedStorage()

  if (!selectedStorage) {
    throw new Error('BUG: Storage should be selected to use this component')
  }

  const isNewStorage = selectedStorage.status === Status.New
  const storageId = isNewStorage ? undefined : selectedStorage.id
  const {data: storage} = useMaterialStorageQuery(storageId)

  const connection = storage?.emailSyncDetails ?? undefined
  const isAddConnectionButtonDisabled = Boolean(connection) || isNewConnection || !canChangeStorage

  if (storageId === undefined) return null
  return (
    <Card
      headerContent={
        <Stack direction="row" justifyContent="space-between">
          <CardTitle>{t('storagesSettings.emailParserConnection')}</CardTitle>
          <Button
            variant="text"
            startIcon={<Add />}
            disabled={isAddConnectionButtonDisabled}
            onClick={() => setIsNewConnection(true)}
            {...dataTestId('add_email_parser_connection')}
          >
            {t('storagesSettings.addConnection')}
          </Button>
        </Stack>
      }
      {...dataTestId('email_parser_card')}
    >
      {(connection || isNewConnection) && (
        <EmailParserConnectionForm
          key={storageId}
          connection={connection}
          setIsNewConnection={setIsNewConnection}
          storageId={storageId}
          isReadOnly={!canChangeStorage}
        />
      )}
    </Card>
  )
}
