import {SamplingPoint} from '../../types'

import {Api} from '@settings/api'

export const UPDATE_SAMPLING_POINT_MUTATION_KEY = 'updateSamplingPoint'

type UpdateSamplingPointParameters = {plantCode: string; samplingPointId: number} & Pick<
  SamplingPoint,
  'type' | 'assetId' | 'storageId'
>

export async function updateSamplingPoint({
  plantCode,
  samplingPointId,
  type,
  assetId,
  storageId
}: UpdateSamplingPointParameters): Promise<void> {
  const path = `/plants/${plantCode}/materials/sampling-points/${samplingPointId}`
  await Api.axiosInstance.patch(path, {type, assetId, storageId})
}
