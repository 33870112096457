import {isFinite, toNumber} from 'lodash'

import {LastResult} from '../types'

export const isValueError = (lastResult?: LastResult): boolean => {
  if (lastResult?.value) {
    return lastResult?.value.toString().startsWith('ERR_')
  }
  return false
}

export const isValueCorrectNumber = (lastResult?: LastResult): boolean =>
  !!lastResult?.value && isFinite(toNumber(lastResult?.value))
