import {dataTestId} from '@hconnect/uikit'
import {CardBox, Loader} from '@hconnect/uikit/src/lib2'
import {SendOutlined as SendOutlinedIcon, ChevronLeft as ChevronLeftIcon} from '@mui/icons-material'
import {Typography, Stack, TextField, InputAdornment, ButtonBase, Link, Box} from '@mui/material'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useUrlParam} from '../../../../modules/routing'
import {invalidateSettingsQuery} from '../../../common/providers'
import {useAddComment} from '../../hooks/useAddComment'
import {useKpiEvents} from '../../hooks/useKpiEvents'
import {Comment, DailyKpiInfo} from '../../types'

import {EmptyCommentsList} from './EmptyCommentsList'
import {KpiComment} from './KpiComment'

export const KpiCommentsView = ({
  kpi,
  closeComments,
  backButtonText,
  selectedDate
}: {
  kpi: DailyKpiInfo
  closeComments: () => void
  backButtonText?: string
  selectedDate?: string
}) => {
  const {t} = useTranslation()

  const handleClick = () => closeComments()

  const {mutate: addComment} = useAddComment()

  const {data: comments, isLoading, isFetching} = useKpiEvents(kpi.id)

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: {isDirty}
  } = useForm<Comment>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {value: ''}
  })

  const plantCode = useUrlParam('plantCode')

  const submit = handleSubmit(({value}) => {
    if (!value || !isDirty) return
    addComment(
      {plantCode, kpiValueId: kpi.id, value},
      {
        onError: () => reset(),
        onSuccess: () => {
          setValue('value', '')
          invalidateSettingsQuery('comments', plantCode, selectedDate)
          invalidateSettingsQuery('dailyEntries', plantCode, selectedDate)
        }
      }
    )
  })

  const isLoadingOrIsFetching = isLoading || isFetching
  const showComments = !isLoadingOrIsFetching && comments && comments.events.length > 1

  return (
    <CardBox height="550px" sx={{overflowY: 'auto'}}>
      <Stack gap={3}>
        {backButtonText && (
          <Link
            component="span"
            sx={{
              textDecoration: 'none'
            }}
            {...dataTestId(`go-back-to-${backButtonText}`)}
            onClick={handleClick}
          >
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
              <ChevronLeftIcon sx={{verticalAlign: 'text-bottom'}} />
              <Typography>{backButtonText}</Typography>
            </Box>
          </Link>
        )}
        <Typography variant="h3">{`${kpi.name} (${kpi.unit.unitName})`}</Typography>
        <Controller
          control={control}
          name="value"
          rules={{
            validate: (value) => value.length > 0
          }}
          render={({field: {ref, value, onChange}}) => (
            <TextField
              label={t('janusConfig.dailyEntry.newComment')}
              {...dataTestId('new-comment-text-field')}
              value={value}
              inputRef={ref}
              onChange={onChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={() => void submit()}>
                    <ButtonBase {...dataTestId('add-new-comment-button')}>
                      <SendOutlinedIcon />
                    </ButtonBase>
                  </InputAdornment>
                )
              }}
            />
          )}
        />
        {showComments ? (
          <Stack gap={3}>
            {comments.events.map((comment) => (
              <KpiComment event={comment} key={`comment-${comment.id}-${comment.version}`} />
            ))}
          </Stack>
        ) : isLoadingOrIsFetching ? (
          <Loader />
        ) : (
          <EmptyCommentsList />
        )}
      </Stack>
    </CardBox>
  )
}
