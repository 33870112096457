import {Error404Page} from '@hconnect/uikit/src/lib2'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link as RouterLink, useNavigate} from 'react-router-dom'

import {RouteName} from '../../routing'

export const PageNotFound: React.VFC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  return (
    <Error404Page
      caption={t('error.pageNotFoundTitle')}
      description={t('error.pageNotFoundDescription')}
      goToCaption={t('plantSelection.selectPlant')}
      goToLink="/"
      RouterLink={RouterLink}
      onGoBack={() => navigate(-1)}
      goBackCaption={t('common.backToLastPage')}
      dataTestId={`page_${RouteName.NOT_FOUND}`}
    />
  )
}
