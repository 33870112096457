import {useParams} from 'react-router-dom'

import {UrlParams} from '../params'

/**
 * Typed wrapper around react router's `useParams` hook that gets a set of known parameters from the current URL
 */
export const useUrlParam = (param: keyof UrlParams) => {
  const obtainedParams = useParams<Partial<UrlParams>>()
  const parameter = obtainedParams[param]
  if (parameter === undefined) {
    throw new Error(`Could not obtain url parameter with name ${param}`)
  }
  return parameter
}
