import {useQueries} from 'react-query'

import {Api} from '../../../../api'

type Department = {
  id: string
  productionLineId: string
  sourceId: string | null
  typeId: string
  typeText: string
}

const fetchDepartment = async (departmentId) => {
  const path = `upm/department/${departmentId}`
  return (await Api.axiosInstance.get<Department>(path)).data
}

export const useGetDepartments = (departmentIds: string[]) => {
  return useQueries(
    departmentIds.map((id) => ({
      queryKey: ['department', id],
      queryFn: () => fetchDepartment(id),
      staleTime: Infinity
    }))
  )
}
