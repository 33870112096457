import {Api} from '@settings/api'

export const UNMAP_MEASUREMENT_TYPE_MUTATION_KEY = 'unmapMeasurementType'

interface UnmapMeasurementTypeParameters {
  plantCode: string
  measurementTypeMappingId: number
}

export async function unmapMeasurementType({
  plantCode,
  measurementTypeMappingId
}: UnmapMeasurementTypeParameters): Promise<void> {
  const path = `/plants/${plantCode}/materials/measurement-types/${measurementTypeMappingId}`
  await Api.axiosInstance.delete(path)
}
