import {User} from '@hconnect/apiclient'
import {catchPromise} from '@hconnect/common/catchPromise'
import {Content, LandingPage, LoadingSwitcher} from '@hconnect/uikit/src/lib2'
import {AxiosError} from 'axios'
import React, {useCallback, useEffect, useState, useMemo} from 'react'
import {useErrorHandler} from 'react-error-boundary'
import {useNavigate, useLocation} from 'react-router-dom'

import {Api} from '../../../api'
import {ROUTES, getUrl} from '../../routing'
import {logger} from '../utils/logger'

import {prefetchSettingsQuery} from './ApiInterface'

export interface LoginDetails {
  email: string
  userId: string
  name: User['name']
}

export interface LoginContextState {
  loginDetails: LoginDetails | null
  setLoginDetails: React.Dispatch<React.SetStateAction<LoginDetails | null>>
  logout: () => Promise<void>
}

export const LoginContext = React.createContext<LoginContextState | undefined>(undefined)

export const useLoginDetails = (): LoginContextState => {
  const context = React.useContext(LoginContext)
  if (!context) throw new Error('useLoginDetails used outside of ContextProvider!')
  return context
}

export const Login: React.FC = () => {
  const navigate = useNavigate()
  const {loginDetails} = useLoginDetails()
  const redirectToAuthenticator = () => Api.loginFlow.startLoginProcess()

  if (loginDetails) {
    navigate(getUrl(ROUTES.BASE.path, {}))
    return null
  }

  return (
    <LandingPage
      onClickButton={() => void redirectToAuthenticator()}
      customLogoStyle={{
        height: '4rem'
      }}
    />
  )
}

export const LoginProvider = ({children}: {children: React.ReactNode}) => {
  const [loginDetails, setLoginDetails] = useState<LoginDetails | null>(null)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  const raiseError = useErrorHandler()

  if (/index.*?\.html/.exec(location.pathname))
    navigate(getUrl(ROUTES.BASE.path, {}), {replace: true})

  const obtainLoginDetails = useCallback(async () => {
    // check if user is logged in
    const loginResponse = await Api.loginFlow.getLoginState()

    setIsLoggedIn(loginResponse.loggedIn)

    if (!loginResponse.loggedIn || !isInitialLoad) return
    setIsInitialLoad(false)

    // get user information
    const {user_id: userId, email} = loginResponse.decodedToken

    // prefetching permissions
    prefetchSettingsQuery('permissions', userId)

    const getUserDetails = Api.axiosInstance.get<User>(`/users/${userId}`)

    const {data: response, err} = await catchPromise(getUserDetails)
    if (err) {
      raiseError(err as AxiosError)
      return
    }

    if (!response) {
      const message = 'Could not obtain login details'
      logger.context('login provider').fatal(message)
      raiseError(new Error(message))
      return
    }

    setLoginDetails({
      userId,
      email,
      name: response.data.name
    })
  }, [isInitialLoad, raiseError])

  // prepare App for launch
  useEffect(() => {
    if (!loginDetails) void obtainLoginDetails()
  }, [loginDetails, isInitialLoad, obtainLoginDetails])

  const logout = React.useCallback(async () => {
    await Api.loginFlow.startLogoutProcess()
    setLoginDetails(null)
    setIsLoggedIn(false)
    navigate(getUrl(ROUTES.BASE.path, {}))
  }, [navigate])

  const memoizedContextState = useMemo(
    () => ({loginDetails, logout, setLoginDetails}),
    [loginDetails, logout]
  )

  const Children = (
    <LoadingSwitcher variant="overlay" isLoading={!loginDetails}>
      {loginDetails ? children : <Content sx={{width: '100vw', height: '100vh'}} />}
    </LoadingSwitcher>
  )

  return (
    <LoginContext.Provider value={memoizedContextState}>
      {isLoggedIn ? Children : <Login />}
    </LoginContext.Provider>
  )
}
