import {DatamartSyncDetails} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {DeleteOutlined} from '@mui/icons-material'
import {Stack, TextField} from '@mui/material'
import React, {FC} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {DeleteButton, NumberInput} from '../../../common/components'
import {useConfirmDialog} from '../../../common/providers'
import {requiredValidator, minValidator, submitOnBlurAndEnterProps} from '../../../common/utils'
import {useUrlParam} from '../../../routing'
import {
  useAddDatamartConnection,
  useDeleteDataConnection,
  useEditDatamartConnection
} from '../../hooks'

interface DataConnectionFormProps {
  storageId: number
  connection?: DatamartSyncDetails
  setIsNewConnection: (value: boolean) => void
  isReadOnly: boolean
}

export const DatamartConnectionForm: FC<DataConnectionFormProps> = ({
  connection,
  storageId,
  setIsNewConnection,
  isReadOnly
}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {openDialog} = useConfirmDialog()

  const {mutate: addDatamartConnection, isLoading: isConnectionAdding} = useAddDatamartConnection()
  const {mutate: editDatamartConnection, isLoading: isConnectionEditing} =
    useEditDatamartConnection()
  const {mutate: deleteDataConnection, isLoading: isConnectionDeleting} = useDeleteDataConnection()

  const {
    handleSubmit,
    control,
    reset,
    formState: {isDirty, dirtyFields}
  } = useForm<DatamartSyncDetails>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: connection ?? {tagName: '', tonneConversionFactor: 1}
  })

  const submit = handleSubmit((values) => {
    if (!isDirty) {
      return
    }
    if (!connection) {
      return addDatamartConnection(
        {plantCode, storageId, dto: values},
        {
          onSuccess: () => setIsNewConnection(false)
        }
      )
    }
    Object.keys(dirtyFields).forEach((key) => {
      editDatamartConnection(
        {
          plantCode,
          storageId,
          dto: values,
          key: key as keyof DatamartSyncDetails
        },
        {
          onSuccess: ({datamartSyncDetails}) =>
            reset({
              tagName: datamartSyncDetails?.tagName,
              tonneConversionFactor: datamartSyncDetails?.tonneConversionFactor
            })
        }
      )
    })
  })

  const deleteConnection = () => {
    if (!connection) {
      return setIsNewConnection(false)
    }
    openDialog({
      title: t('storagesSettings.deleteDataMartConnection'),
      description: t('storagesSettings.deleteDataMartConnectionDescription'),
      mainAction: {
        text: t('common.delete'),
        color: 'error',
        icon: <DeleteOutlined />,
        onAction: () => deleteDataConnection({plantCode, storageId, type: 'datamart'})
      },
      testId: 'delete_datamart_connection_dialog'
    })
  }

  const shouldDisableDeleteButton =
    isConnectionAdding || isConnectionDeleting || isConnectionEditing || isReadOnly

  return (
    <Stack
      spacing={2}
      direction="row"
      {...dataTestId('datamart_connection_form')}
      alignItems="center"
    >
      <Controller
        control={control}
        name="tagName"
        rules={requiredValidator(t)}
        render={({field: {ref, value, onChange}, fieldState: {error}}) => (
          <TextField
            sx={{width: ({spacing}) => spacing(27)}}
            variant="outlined"
            label={t('storagesSettings.tagName')}
            InputLabelProps={{shrink: true}}
            inputRef={ref}
            value={value}
            onChange={onChange}
            helperText={error?.message}
            error={Boolean(error?.message)}
            disabled={isReadOnly}
            {...submitOnBlurAndEnterProps(submit)}
            {...dataTestId('tag_name_input')}
          />
        )}
      />
      <Controller
        control={control}
        name="tonneConversionFactor"
        rules={{...requiredValidator(t), ...minValidator(t, 0)}}
        render={({field: {ref, value, onChange}, fieldState: {error}}) => (
          <NumberInput
            sx={{width: ({spacing}) => spacing(27)}}
            label={t('storagesSettings.tonneConversionFactor')}
            inputRef={ref}
            value={value}
            onChange={onChange}
            error={error?.message}
            disabled={isReadOnly}
            dataTestId="tonne_conversion_factor_input"
            {...submitOnBlurAndEnterProps(submit)}
          />
        )}
      />
      <div>
        <DeleteButton
          onClick={deleteConnection}
          disabled={shouldDisableDeleteButton}
          {...dataTestId('delete_datamart_connection_button')}
        />
      </div>
    </Stack>
  )
}
