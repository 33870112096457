import type {AssetResponse} from '@hconnect/common/types'
import {AssetType} from '@hconnect/common/types'
import {flow} from 'lodash'
import {useCallback, useMemo} from 'react'

import {useSettingsQuery} from '../../../common/providers/ApiInterface'
import {useUrlParam} from '../../../routing'
import {sortAssets} from '../../utils/assetSelectors'

type AssetSelector = (assets: AssetResponse[]) => AssetResponse[]

const filterAssetsByTypes = (assetTypes: AssetType[]) => (assets: AssetResponse[]) =>
  assets.filter((asset) => assetTypes.includes(asset.type))

interface AssetsQueryProps {
  sorted: boolean
  types: AssetType[]
}

export const useAssetsQuery = (props?: Partial<AssetsQueryProps>) => {
  const plantCode = useUrlParam('plantCode')

  const selectors: AssetSelector[] = useMemo(
    () => [
      ...(props?.types ? [filterAssetsByTypes(props.types)] : []),
      ...(props?.sorted ? [sortAssets] : [])
    ],
    [props?.types, props?.sorted]
  )

  const combinedSelector = useCallback(
    (assets: AssetResponse[]) => flow(...selectors)(assets) as AssetResponse[],
    [selectors]
  )

  return useSettingsQuery('assets', [plantCode], {
    select: selectors.length > 0 ? combinedSelector : undefined
  })
}
