import {isNull} from 'lodash'

import {
  SamplingPointTypesAutocompleteOption,
  SamplingPoint,
  AutocompleteOption,
  SamplingPointEquipmentType
} from '../../../../../types'

interface getSelectedEquipmentOptionParameters {
  samplingPoint: SamplingPoint | undefined
  selectedTypeOption: SamplingPointTypesAutocompleteOption | null
  assetOptions: AutocompleteOption[]
  storageOptions: AutocompleteOption[]
}

export const getSelectedEquipmentOption = ({
  samplingPoint,
  selectedTypeOption,
  assetOptions,
  storageOptions
}: getSelectedEquipmentOptionParameters) => {
  // Lims sampling point is not yet mapped
  if (isNull(selectedTypeOption)) return {selectedEquipmentOption: null, equipmentOptions: []}
  else if (selectedTypeOption.type === SamplingPointEquipmentType.asset)
    // Lims sampling point is of "Asset" type and is mapped to an asset
    return {
      selectedEquipmentOption:
        assetOptions.find((assetOption) => assetOption.id === samplingPoint?.assetId) ?? null,
      equipmentOptions: assetOptions
    }
  else if (selectedTypeOption.type === SamplingPointEquipmentType.storage)
    // Lims sampling point is of "Storage" type and mapped to a storage
    return {
      selectedEquipmentOption:
        storageOptions.find((storageOption) => storageOption.id === samplingPoint?.storageId) ??
        null,
      equipmentOptions: storageOptions
    }
  // Lims sampling point is of "Other" type
  else return {selectedEquipmentOption: null, equipmentOptions: []}
}
