import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../../api/mutations'
import {notifyIfErrorMessage} from '../../../../../modules/common/utils/errorHandling'
import type {ErrorResponse} from '../../../../common/types'

import {invalidateSettingsQuery} from '@settings/modules/common/providers'

type deleteGlobalMaterialParams = Parameters<typeof mutations.deleteGlobalMaterial>[0]

export const useDeleteGlobalMaterial = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<void, AxiosError<ErrorResponse>, deleteGlobalMaterialParams>(
    mutations.deleteGlobalMaterial,
    {
      onSuccess: () => {
        notify('success', t('success.editMaterial'))
      },
      onError: (error) => {
        notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
      },
      onSettled: (data, error, variables) => {
        invalidateSettingsQuery('materials', variables.plantCode)
        invalidateSettingsQuery('materialWithRecipes', variables.plantCode, variables.materialId)
      }
    }
  )
}
