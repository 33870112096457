import {dataTestId} from '@hconnect/uikit'
import {InfoOutlined} from '@mui/icons-material'
import {Box, Typography} from '@mui/material'
import {isNil} from 'lodash'
import React, {PropsWithChildren} from 'react'

export interface TextWithCaptionProps extends PropsWithChildren<unknown> {
  text: string
  caption?: string
  showInfoIcon?: boolean
  'data-test-id'?: string
}

export function TextWithCaption({
  text,
  caption,
  showInfoIcon = false,
  'data-test-id': testId = 'text-with-caption'
}: TextWithCaptionProps): JSX.Element {
  return (
    <Box {...dataTestId(testId)}>
      <Typography variant="h4" mb={{xs: 2, md: 3}} sx={{mb: 0}} {...dataTestId(`${testId}_title`)}>
        {text}
      </Typography>
      {!isNil(caption) && (
        <Typography variant="caption" {...dataTestId(`${testId}_caption`)}>
          {showInfoIcon && (
            <InfoOutlined
              sx={{
                mr: 0.5,
                verticalAlign: 'middle',
                fontSize: 'inherit'
              }}
            />
          )}
          {caption}
        </Typography>
      )}
    </Box>
  )
}
