import {Status} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {Add} from '@mui/icons-material'
import {Box, Button} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../../permissions'
import {useMaterialWithRecipesQuery} from '../../hooks'
import {NewRecipe} from '../../types'
import {useSelectedMaterial} from '../SelectedMaterialProvider'

import {RecipeForm} from './RecipeForm'

export const RecipesCard: React.FC = () => {
  const {t} = useTranslation()
  const [newRecipe, setNewRecipe] = useState<NewRecipe>()
  const {selectedMaterial} = useSelectedMaterial()
  const selectedMaterialId =
    selectedMaterial && selectedMaterial?.status !== Status.New ? selectedMaterial.id : undefined
  const {data: materialWithRecipes} = useMaterialWithRecipesQuery(selectedMaterialId)

  const canChangeMaterials = usePermission('CHANGE_MATERIALS')

  if (!materialWithRecipes) {
    return null
  }
  const recipes = materialWithRecipes.recipes
  return (
    <Card
      {...dataTestId('recipe_card')}
      headerContent={
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <CardTitle sx={{mb: 0}} {...dataTestId('recipe_list_title')}>{`${t(
            'materialsSettings.recipes'
          )} (${recipes.length ?? 0})`}</CardTitle>
          <Button
            variant="text"
            startIcon={<Add />}
            disabled={Boolean(newRecipe) || !canChangeMaterials}
            onClick={() => {
              if (selectedMaterialId !== undefined) {
                setNewRecipe({
                  name: '',
                  mainMaterialId: selectedMaterialId,
                  components: [],
                  status: Status.New
                })
              }
            }}
            {...dataTestId('add_recipe_button')}
          >
            {t('materialsSettings.addRecipe')}
          </Button>
        </Box>
      }
    >
      {recipes.map((recipe, index) => (
        <RecipeForm
          key={recipe.id}
          index={index}
          recipe={recipe}
          isReadOnly={!canChangeMaterials}
        />
      ))}
      {newRecipe && (
        <RecipeForm
          index={recipes.length}
          key="new"
          recipe={newRecipe}
          setNewRecipe={setNewRecipe}
          isReadOnly={!canChangeMaterials}
        />
      )}
    </Card>
  )
}
