import {useQuery, UseQueryOptions, UseQueryResult} from 'react-query'

import {queries, Queries} from '../../../api/queries'
import {settingsQueryClient} from '@settings/modules/common/providers/QueryProvider'

// extract  the inner type of a promise
type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T

// get the inner type of the returned promise for a query
export type QueryReturn<K extends keyof Queries> = Awaited<ReturnType<Queries[K]>>

/**
 * Wrapper around useQuery from react-query.  Should be used as it allows a type-safe interface into the available queries that we have defined as well as provides a consistent interface.
 * @param queryKey
 * @param params
 * @param options
 */
export const useSettingsQuery = <K extends keyof Queries, TReturn = QueryReturn<K>>(
  queryKey: K,
  params: Parameters<Queries[K]>,
  options?: Omit<UseQueryOptions<QueryReturn<K>, unknown, TReturn>, 'queryKey' | 'queryFn'>
): UseQueryResult<TReturn, unknown> => {
  return useQuery<QueryReturn<K>, unknown, TReturn>(
    [queryKey, ...params],
    async () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return queries[queryKey](...params) as Promise<QueryReturn<K>>
    },
    options
  )
}

type QueryHelper = <K extends keyof Queries, T extends Queries>(
  queryKey: K,
  ...params: Parameters<T[K]> | []
) => void

type QueryHelperAsync = <K extends keyof Queries, T extends Queries>(
  queryKey: K,
  ...params: Parameters<T[K]> | []
) => Promise<QueryReturn<K>>

export const invalidateSettingsQuery = <K extends keyof Queries, Q extends Queries[K]>(
  queryKey: K,
  ...params: Parameters<Q> | []
) =>
  void settingsQueryClient.invalidateQueries([queryKey, ...params], {
    refetchActive: true,
    refetchInactive: true
  })

export const removeSettingsQuery: QueryHelper = (queryKey, ...params) =>
  settingsQueryClient.removeQueries([queryKey, ...params])

export const refetchSettingsQuery: QueryHelper = (queryKey, ...params) =>
  settingsQueryClient.refetchQueries([queryKey, ...params])

export const getSettingsQueryData = <K extends keyof Queries, T extends Queries>(
  queryKey: K,
  params: Parameters<T[K]>
) => settingsQueryClient.getQueryData<QueryReturn<K>>([queryKey, ...params])

export const setSettingsQueryData = <K extends keyof Queries, T extends Queries>(
  queryKey: K,
  params: Parameters<T[K]>,
  data: QueryReturn<K>
) => settingsQueryClient.setQueryData([queryKey, ...params], data)

export const fetchSettingsQuery: QueryHelperAsync = <K extends keyof Queries, T extends Queries>(
  queryKey: K,
  ...params: Parameters<T[K]> | []
) =>
  settingsQueryClient.fetchQuery<QueryReturn<K>>([queryKey, ...params], async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return queries[queryKey](...params) as Promise<QueryReturn<K>>
  })

export const prefetchSettingsQuery: QueryHelper = <K extends keyof Queries, T extends Queries>(
  queryKey: K,
  ...params: Parameters<T[K]> | []
) => {
  void settingsQueryClient.prefetchQuery([queryKey, ...params], async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return queries[queryKey](...params) as Promise<QueryReturn<K>>
  })
}
