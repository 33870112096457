import {Permission} from '@hconnect/common/hproduce'

export interface PermissionParsed
  extends Pick<Permission, 'userId' | 'connector' | 'permissionType' | 'expiryDate' | 'dataScope'> {
  parsedDataScope: Partial<DataScope>
}

// Data scope used in Planner
export interface DataScope {
  businessLine: string
  businessLineId: string
  countryId: string
  plantId: string
}
