import type {PermissionType} from '@hconnect/apiclient'
import React from 'react'
import {Outlet, Navigate} from 'react-router-dom'

import {usePermission} from '../../permissions'
import {getUrl} from '../helpers'
import {ROUTES} from '../routes'

interface PermissionGuardedRouteProps {
  requiredPermissions: [type: PermissionType, ...types: PermissionType[]]
  isRouteAllowed?: boolean
}

export const PermissionGuardedRoute: React.FC<PermissionGuardedRouteProps> = ({
  requiredPermissions,
  isRouteAllowed = true
}) => {
  const isAccessGranted = usePermission(...requiredPermissions)

  if (!(isAccessGranted && isRouteAllowed)) {
    return <Navigate to={getUrl(ROUTES.BASE.NOT_AUTHORIZED.path, {})} />
  }
  return <Outlet />
}
