import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../api/mutations'
import {invalidateSettingsQuery} from '../../common/providers/ApiInterface'
import type {ErrorResponse} from '../../common/types'
import {notifyIfErrorMessage} from '../../common/utils/errorHandling'
import {Kpi} from '../types'

export const useEditKpiDisplayName = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<
    Kpi,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.editKpiDisplayName>[0]
  >(mutations.editKpiDisplayName, {
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.message, raiseError, notify)
    },
    onSuccess: () => {
      notify('success', t('success.editKpiDisplayName'))
    },
    onSettled: (data, error, variables) => {
      invalidateSettingsQuery('kpis', variables.plantCode as string)
    }
  })
}
