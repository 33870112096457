import type {MaterialStorage} from '@hconnect/common/types'

import {useSettingsQuery} from '../../../../modules/common/providers/ApiInterface'
import {useUrlParam} from '../../../../modules/routing'
import {isStorageWithMaterialGuard} from '../../../../modules/storages'

const selector = (data: MaterialStorage[]) => data.filter(isStorageWithMaterialGuard)

export const useStoragesWithMaterialQuery = () => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('materialStorage', [plantCode], {select: selector})
}
