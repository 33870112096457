import {alpha} from '@mui/material'

export const getStringToColor = (string: string) => {
  let hash = 0
  let i: number

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

export const getStringAvatar = (name: string) => {
  const color = getStringToColor(name)
  return {
    sx: {
      bgcolor: alpha(color, 0.1),
      width: '24px',
      height: '24px',
      color: color,
      fontSize: 10
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  }
}
