import {KpiRangeBreachSeverity} from '@hconnect/common/types'

import {Threshold, ThresholdPreview, ThresholdPreviewType, ThresholdSign} from '../types'

export const generateThresholdPreview = (thresholds: Threshold[]): ThresholdPreview[] => {
  const validThresholds = thresholds.filter(
    (threshold) => threshold.value !== undefined && threshold.severity && threshold.sign
  )

  if (validThresholds.length === 0) {
    return []
  }

  const moreThanThresholds = validThresholds
    .filter((t) => t.sign === ThresholdSign.MoreThan)
    .sort((a, b) => b.value - a.value)
  const lessThanThresholds = validThresholds
    .filter((t) => t.sign === ThresholdSign.LessThan)
    .sort((a, b) => b.value - a.value)

  const previews: ThresholdPreview[] = []

  // Generate 'moreThan' signs ranges
  moreThanThresholds.forEach((threshold, index) => {
    const prevValue = moreThanThresholds[index - 1]?.value
    if (index === 0) {
      previews.push({
        type: ThresholdPreviewType.MoreThan,
        severity: threshold.severity,
        value: threshold.value,
        unit: threshold.unit
      })
    } else if (prevValue !== undefined) {
      previews.push({
        type: ThresholdPreviewType.Between,
        severity: threshold.severity,
        from: threshold.value,
        to: prevValue,
        unit: threshold.unit
      })
    }
  })

  // Generate ranges for missing signs
  if (moreThanThresholds.length === 0) {
    const highestLessThan = lessThanThresholds[0]
    previews.push({
      type: ThresholdPreviewType.MoreThan,
      severity: KpiRangeBreachSeverity.Optimal,
      value: highestLessThan.value,
      unit: highestLessThan.unit
    })
  }
  if (lessThanThresholds.length === 0) {
    const lowestMoreThan = moreThanThresholds[moreThanThresholds.length - 1]
    previews.push({
      type: ThresholdPreviewType.LessThan,
      severity: KpiRangeBreachSeverity.Optimal,
      value: lowestMoreThan.value,
      unit: lowestMoreThan.unit
    })
  }

  // Generate in-between signs range
  if (moreThanThresholds.length > 0 && lessThanThresholds.length > 0) {
    const lowestMoreThan = moreThanThresholds[moreThanThresholds.length - 1]
    const highestLessThan = lessThanThresholds[0]
    previews.push({
      type: ThresholdPreviewType.Between,
      severity: KpiRangeBreachSeverity.Optimal,
      from: highestLessThan.value,
      to: lowestMoreThan.value,
      unit: lowestMoreThan.unit
    })
  }

  // Generate 'lessThan' signs ranges
  lessThanThresholds.forEach((threshold, index) => {
    const nextValue = lessThanThresholds[index + 1]?.value
    if (lessThanThresholds.length - 1 === index) {
      previews.push({
        type: ThresholdPreviewType.LessThan,
        severity: threshold.severity,
        value: threshold.value,
        unit: threshold.unit
      })
    } else if (nextValue !== undefined) {
      previews.push({
        type: ThresholdPreviewType.Between,
        severity: threshold.severity,
        from: nextValue,
        to: threshold.value,
        unit: threshold.unit
      })
    }
  })

  return previews
}
