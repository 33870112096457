import {useSettingsQuery} from '../../common/providers/ApiInterface'
import {useUrlParam} from '../../routing'

export const useKpiEvents = (kpiValueId: string) => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('events', [plantCode, kpiValueId], {
    cacheTime: 0,
    staleTime: 0
  })
}
