import {NumericTextField} from '@hconnect/common/components/forms/NumericTextField'
import {dataTestId as spreadDataTestId} from '@hconnect/uikit'
import type {TextFieldProps} from '@mui/material'
import React from 'react'

function mapInputValueToValue(inputValue: string) {
  return inputValue === '' ? null : parseFloat(inputValue)
}

function mapValueToInputValue(value: number | null) {
  return value === null ? '' : value
}

interface NumberInputProps
  extends Pick<TextFieldProps, 'inputRef' | 'label' | 'disabled' | 'onBlur' | 'onKeyDown' | 'sx'> {
  step?: number
  min?: number
  value: number | null
  onChange: (value: NumberInputProps['value']) => void
  error?: string
  dataTestId?: string
}

export const NumberInput = ({
  sx,
  inputRef,
  step = 1,
  min,
  label,
  value,
  onChange,
  error,
  disabled,
  dataTestId = 'number_input',
  ...eventProps
}: NumberInputProps) => {
  return (
    <NumericTextField
      sx={sx}
      fullWidth
      label={label}
      inputRef={inputRef}
      inputProps={{step, min}}
      onChange={({target: {value}}) => onChange(mapInputValueToValue(value))}
      value={mapValueToInputValue(value)}
      error={Boolean(error)}
      disabled={disabled}
      helperText={error}
      {...eventProps}
      {...spreadDataTestId(dataTestId)}
    />
  )
}
