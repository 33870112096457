import type {NotifyFn} from '@hconnect/uikit/src/common'

type RaiseError = (error: Error | null) => void

export const notifyIfErrorMessage = (
  data: unknown,
  raiseError: RaiseError,
  notify: NotifyFn
): void => {
  if (typeof data === 'string') notify('error', data)
  else if (data) raiseError(new Error(JSON.stringify(data)))
  // TODO: Add generic error message when no data.
}
