import {AssetType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Card} from '@hconnect/uikit/src/lib2'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {NewAssetForm} from './general/NewAssetForm'

import {useUrlParam} from '@settings/modules/routing'

export const PageNewAsset: React.FC = () => {
  const {t} = useTranslation()
  const assetType = useUrlParam('assetType')

  return (
    <Card
      headerContent={`${t('common.new')} ${t(`assetsSettings.assetTypes.${assetType}`)}`}
      {...dataTestId('new_asset_card')}
    >
      <NewAssetForm assetType={assetType as AssetType} />
    </Card>
  )
}
