import {dataTestId} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {Frequency, LastResult} from '../../types'

import {FormulaForm} from './FormulaForm'

type FormulaCardProps = {
  formula: string | null
  kpiId: number
  frequency: Frequency
  lastResult?: LastResult
}

export const FormulaCard: FC<FormulaCardProps> = ({formula, kpiId, lastResult, frequency}) => {
  const {t} = useTranslation()

  return (
    <Card
      headerContent={
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <CardTitle sx={{mb: 0}}>{t('kpiCalculationSettings.label.formula')}</CardTitle>
        </Box>
      }
      {...dataTestId('formula-card')}
    >
      <FormulaForm formula={formula} kpiId={kpiId} lastResult={lastResult} frequency={frequency} />
    </Card>
  )
}
