import type {AssetTransition} from '@hconnect/common/types'
import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../api/mutations'
import {getSettingsQueryData, setSettingsQueryData} from '../../../common/providers/ApiInterface'
import type {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'

export const useEditAssetTransition = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<
    AssetTransition,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.editAssetTransition>[0]
  >(mutations.editAssetTransition, {
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSuccess: (editedTransition, variables) => {
      notify('success', t('success.editTransition'))
      const prevAssetsTransitions = getSettingsQueryData('assetsTransitions', [variables.plantCode])
      if (!prevAssetsTransitions)
        throw new Error('Assets transitions should be loaded to query before using this hook')

      const [assetId, assetTransitions] =
        Object.entries(prevAssetsTransitions).find(([, transitions]) =>
          transitions.some((transition) => transition.id === variables.id)
        ) ?? []

      if (!assetId || !assetTransitions)
        throw new Error('BUG: updated asset transition should exist in transitions dictionary')

      const editedAssetsTransitions = {
        ...prevAssetsTransitions,
        [assetId]: assetTransitions.map((transition) =>
          transition.id === variables.id ? editedTransition : transition
        )
      }

      setSettingsQueryData('assetsTransitions', [variables.plantCode], editedAssetsTransitions)
    }
  })
}
