import React from 'react'
import {Helmet} from 'react-helmet-async'

interface SettingsHelmetProps {
  title: string
}

export const SettingsHelmet: React.FC<SettingsHelmetProps> = ({title}) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}
