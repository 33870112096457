import {AssetType} from '@hconnect/common/types'

import {useSettingsQuery} from '../../../common/providers/ApiInterface'
import {useUrlParam} from '../../../routing'
import {Recipe} from '../../types'

export const useMaterialsRecipesById = (assetType?: AssetType) => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('materialsRecipes', [{plantCode, assetType}], {
    select: (data) =>
      data.reduce(
        (recipesById, recipe) => ({...recipesById, [String(recipe.id)]: recipe}),
        {} as Record<string, Recipe>
      )
  })
}
