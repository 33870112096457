import {useSettingsQuery} from '../../../common/providers/ApiInterface'
import {useUrlParam} from '../../../routing'

import {useFeatureFlag} from '@settings/modules/common/hooks/useFeatureFlag'

export const useLimsMaterialsQuery = () => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('limsMaterials', [plantCode], {
    enabled: useFeatureFlag('limsMaterialsToMaterialMapping')
  })
}
