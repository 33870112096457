import {Alert, type AlertProps} from '@hconnect/uikit/src/lib2'
import {Stack, Typography} from '@mui/material'
import React from 'react'

export interface InfoGroupProps {
  description: React.ReactNode | string
  panels?: AlertProps[]
}

export const InfoGroup: React.FC<InfoGroupProps> = ({description, panels}) => {
  return (
    <>
      <Typography variant="body2">{description}</Typography>
      {panels && panels.length > 0 && (
        <Stack spacing={1} sx={{mt: 2}}>
          {panels.map((panelProps, index) => (
            <Alert key={index} {...panelProps} />
          ))}
        </Stack>
      )}
    </>
  )
}
