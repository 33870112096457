import {dataTestId} from '@hconnect/uikit/src/common'
import {TableCell} from '@mui/material'
import {isEqual, isNull, isUndefined} from 'lodash'
import React, {useEffect, useImperativeHandle, forwardRef} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useCreateSamplingPointMutation} from '../../../../hooks/mutations/useCreateSamplingPointMutation'
import {useDeleteSamplingPointMutation} from '../../../../hooks/mutations/useDeleteSamplingPointMutation'
import {useUpdateSamplingPointMutation} from '../../../../hooks/mutations/useUpdateSamplingPointMutation'
import {
  SamplingPointEquipmentType,
  AutocompleteOption,
  SamplingPointTypesAutocompleteOption,
  LimsSamplingPoint
} from '../../../../types'
import {SamplingPointFormAutocomplete} from '../SamplingPointFormAutocomplete/SamplingPointFormAutocomplete'

import {getSelectedEquipmentOption} from './helpers/getSelectedEquipmentOption'
import {useGetSelectedTypeOption} from './hooks/useGetSelectedTypeOption'

import {useUrlParam} from '@settings/modules/routing'

export interface SamplingPointTypeEquipmentFormProps {
  limsSamplingPoint: LimsSamplingPoint
  samplingPointTypeOptions: SamplingPointTypesAutocompleteOption[]
  assetOptions: AutocompleteOption[]
  storageOptions: AutocompleteOption[]
  ref: React.ForwardedRef<{
    mapLimsSamplingPoint: () => Promise<void>
  }>
}

interface SamplingPointTypeEquipmentDefaultValues {
  typeOption: SamplingPointTypesAutocompleteOption | null
  equipmentOption: AutocompleteOption | null
}

const getEquipmentIdParamBySamplingPointType = (
  samplingPointType: SamplingPointEquipmentType,
  equipmentId: number | undefined
) => {
  return samplingPointType === SamplingPointEquipmentType.asset
    ? {assetId: equipmentId}
    : samplingPointType === SamplingPointEquipmentType.storage
      ? {storageId: equipmentId}
      : {}
}

export const SamplingPointTypeEquipmentForm: React.FC<SamplingPointTypeEquipmentFormProps> =
  forwardRef(({limsSamplingPoint, samplingPointTypeOptions, assetOptions, storageOptions}, ref) => {
    const {t} = useTranslation()
    const plantCode = useUrlParam('plantCode')

    const {mutateAsync: createSamplingPoint, isLoading: isSamplingPointBeingCreated} =
      useCreateSamplingPointMutation()
    const {mutateAsync: updateSamplingPoint, isLoading: isSamplingPointBeingUpdated} =
      useUpdateSamplingPointMutation()
    const {mutateAsync: deleteSamplingPoint, isLoading: isSamplingPointBeingDeleted} =
      useDeleteSamplingPointMutation()

    // Getting the selected type option
    const {selectedTypeOption, setSelectedTypeOption} = useGetSelectedTypeOption({
      samplingPointTypeOptions,
      samplingPointType: limsSamplingPoint.samplingPoint?.type
    })

    // Getting the selected equipment option and relevant set of options
    const {selectedEquipmentOption, equipmentOptions} = getSelectedEquipmentOption({
      samplingPoint: limsSamplingPoint.samplingPoint,
      selectedTypeOption,
      assetOptions,
      storageOptions
    })

    // Creating a form which contains selected type and equipment
    const {
      control,
      handleSubmit,
      reset,
      formState: {defaultValues}
    } = useForm<SamplingPointTypeEquipmentDefaultValues>({
      shouldFocusError: false,
      defaultValues: {
        typeOption: selectedTypeOption,
        equipmentOption: selectedEquipmentOption
      }
    })

    const mapLimsSamplingPoint = handleSubmit(
      ({typeOption: newTypeOption, equipmentOption: newEquipmentOption}) => {
        if (isUndefined(defaultValues)) return

        const oldTypeOption = defaultValues.typeOption
        const oldEquipmentOption = defaultValues.equipmentOption

        if (
          isEqual(newTypeOption, oldTypeOption) &&
          isEqual(newEquipmentOption, oldEquipmentOption)
        )
          return

        // User tries to delete existing mapping
        if (isNull(newTypeOption)) {
          return deleteSamplingPoint(
            {
              plantCode,
              samplingPointId: limsSamplingPoint.samplingPoint!.id
            },
            {
              onSuccess: () =>
                reset({typeOption: newTypeOption, equipmentOption: newEquipmentOption}),
              onError: () => {
                reset()
                setSelectedTypeOption(oldTypeOption as SamplingPointTypesAutocompleteOption)
              }
            }
          )
        }
        // User tries to create a new mapping
        else if (isNull(oldTypeOption)) {
          return createSamplingPoint(
            {
              plantCode,
              type: newTypeOption.label,
              limsSamplingPointId: limsSamplingPoint.id,
              ...getEquipmentIdParamBySamplingPointType(newTypeOption.type, newEquipmentOption?.id)
            },
            {
              onSuccess: () =>
                reset({typeOption: newTypeOption, equipmentOption: newEquipmentOption}),
              onError: () => {
                reset()
                setSelectedTypeOption(null)
              }
            }
          )
        }
        // User tries to update existing mapping
        else {
          return updateSamplingPoint(
            {
              plantCode,
              type: newTypeOption.label,
              samplingPointId: limsSamplingPoint.samplingPoint!.id,
              ...getEquipmentIdParamBySamplingPointType(newTypeOption.type, newEquipmentOption?.id)
            },
            {
              onSuccess: () =>
                reset({typeOption: newTypeOption, equipmentOption: newEquipmentOption}),
              onError: () => {
                reset()
                setSelectedTypeOption(oldTypeOption as SamplingPointTypesAutocompleteOption)
              }
            }
          )
        }
      }
    )

    // Providing handle mapping function to a parent's save button
    useImperativeHandle(ref, () => ({mapLimsSamplingPoint, isTypeDropdownDisabled}))

    const isTypeDropdownDisabled =
      isSamplingPointBeingCreated || isSamplingPointBeingUpdated || isSamplingPointBeingDeleted

    const isEquipmentDropdownDisabled =
      isTypeDropdownDisabled ||
      isNull(selectedTypeOption) ||
      selectedTypeOption.type === SamplingPointEquipmentType.other

    useEffect(() => {
      reset(
        {typeOption: selectedTypeOption, equipmentOption: selectedEquipmentOption},
        {keepDefaultValues: true}
      )
    }, [reset, selectedTypeOption, selectedEquipmentOption])

    return (
      <>
        <TableCell>
          <Controller
            name="typeOption"
            control={control}
            render={({field: {value, ref, onChange}}) => (
              <SamplingPointFormAutocomplete
                value={value}
                inputRef={ref}
                options={samplingPointTypeOptions}
                label={t('samplingPointsSettings.samplingPointType')}
                disabled={isTypeDropdownDisabled}
                onChange={(typeOption) => {
                  setSelectedTypeOption(typeOption as SamplingPointTypesAutocompleteOption)
                  onChange(typeOption)
                }}
                {...dataTestId('sampling_point_form_type_autocomplete')}
              />
            )}
          />
        </TableCell>
        <TableCell>
          <Controller
            name="equipmentOption"
            control={control}
            render={({field: {value, ref, onChange}, fieldState: {error}}) => (
              <SamplingPointFormAutocomplete
                value={value}
                inputRef={ref}
                options={equipmentOptions}
                label={t('samplingPointsSettings.samplingPointEquipment')}
                error={
                  error ? (error.message ? error.message : t('error.invalidInput')) : undefined
                }
                disabled={isEquipmentDropdownDisabled}
                onChange={onChange}
                {...dataTestId('sampling_point_form_equipment_autocomplete')}
              />
            )}
            rules={{
              validate: (equipmentOption) => {
                if (!isEquipmentDropdownDisabled && isNull(equipmentOption))
                  return t('error.required')
              }
            }}
          />
        </TableCell>
      </>
    )
  })
