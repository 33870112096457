import {Button, ButtonProps} from '@mui/material'
import {CircularProgress} from '@mui/material'

export function LoadingButton({isLoading, ...props}: {isLoading: boolean} & ButtonProps) {
  return (
    <Button
      {...props}
      startIcon={
        isLoading ? (
          <CircularProgress size="sm" color="primary" sx={{width: (theme) => theme.spacing(2)}} />
        ) : null
      }
      sx={{
        minWidth: (theme) => theme.spacing(15)
      }}
    >
      {props.children}
    </Button>
  )
}
