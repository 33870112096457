import {MeasurementTypesMapping} from '../../types/MeasurementTypesMapping'

import {Api} from '@settings/api'

export const MEASUREMENT_TYPES_MAPPINGS_QUERY_KEY = 'measurementTypesMappings'

export async function getMeasurementTypesMappings(
  plantCode: string
): Promise<MeasurementTypesMapping[]> {
  const path = `/plants/${plantCode}/materials/measurement-types`
  const response = await Api.axiosInstance.get<MeasurementTypesMapping[]>(path)
  return response.data
}
