import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import moment from 'moment-timezone'

import {useUrlParam} from '../../../routing'
import {useSettingsQuery} from '../../providers/ApiInterface'
import {useLatestData} from '../useLatestData'

export const useScheduleQuery = (isDisabled = false) => {
  const plantCode = useUrlParam('plantCode')
  const latest = useLatestData()

  // TODO: should be resolved by HCP-60882
  // this logic is for currently to match default time range in planner
  const startOfToday = moment.utc().tz(latest.timezone_id).startOf('day')
  const start = roundTo15MinIntervalStart(moment.utc().tz(latest.timezone_id))
  const end = startOfToday.clone().add(8, 'days')

  return useSettingsQuery(
    'schedule',
    [{plantCode, start: start.toISOString(), end: end.toISOString()}],
    {
      enabled: !isDisabled
    }
  )
}
