import {isEmpty} from 'lodash'
import {useCallback} from 'react'
import {useSearchParams} from 'react-router-dom'

import {SearchParams, searchParams} from '../params'

/**
 * Typed wrapper around react router's `useSearchParams` hook that gets a set of known search parameters
 * from the current URL and validates incorrect parameter values
 */
export function useSearchParam<P extends keyof SearchParams, V extends SearchParams[P][number]>(
  paramName: P
): [V, (value: V) => void]
export function useSearchParam<
  P extends keyof SearchParams,
  T extends true,
  V extends SearchParams[P][number]
>(paramName: P, required: T): [V, (value: V) => void]
export function useSearchParam<
  P extends keyof SearchParams,
  T extends false,
  V extends SearchParams[P][number]
>(paramName: P, required?: T): [V | null, (value: V) => void]

export function useSearchParam<P extends keyof SearchParams, V extends SearchParams[P][number]>(
  paramName: P,
  required = true
) {
  const [obtainedSearchParams, setSearchParams] = useSearchParams()

  const setSearchParameter = useCallback(
    (value: V) => {
      setSearchParams({[paramName]: value})
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paramName]
  )

  const obtainedValue = obtainedSearchParams.get(paramName)

  if (obtainedValue === null) {
    if (required) {
      throw new Error(`Could not obtain search parameter with name ${paramName}`)
    }
    return [obtainedValue, setSearchParameter]
  }

  // validating parameter values
  const acceptableValues = searchParams[paramName] as string[]
  if (!(isEmpty(acceptableValues) || acceptableValues.includes(obtainedValue))) {
    throw new Error(`The value ${obtainedValue} is not correct for parameter name ${paramName} `)
  }
  return [obtainedValue, setSearchParameter]
}
