import {dataTestId} from '@hconnect/uikit'
import {
  ChatBubbleOutline as ChatBubbleOutlineIcon,
  KeyboardAltOutlined as KeyboardAltOutlinedIcon,
  ErrorOutline as ErrorOutlineIcon,
  LayersOutlined as LayersOutlinedIcon,
  CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon
} from '@mui/icons-material'
import {InputAdornment, TextField, Typography, Box, Stack} from '@mui/material'
import {Controller, useFormContext} from 'react-hook-form'

import {DailyKpiInfo, KpiType} from '../../types'

export const DailyEntryMobileView = ({
  data,
  setSelectedKpi
}: {
  data: DailyKpiInfo[]
  setSelectedKpi: (kpi: DailyKpiInfo) => void
}) => {
  const {
    control,
    formState: {errors}
  } = useFormContext()

  return (
    <Stack gap={2}>
      {data.map((entry) => {
        const {kpiType, value, id, name, versions, comments} = entry
        const isError = kpiType === KpiType.Calculated && value === null

        const Icon =
          kpiType === KpiType.Manual ? (
            <KeyboardAltOutlinedIcon />
          ) : isError ? (
            <ErrorOutlineIcon color="error" />
          ) : (
            <CheckCircleOutlineOutlinedIcon color="success" />
          )

        return (
          <Box
            key={id}
            display="flex"
            flexDirection="row"
            gap={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Controller
              control={control}
              rules={{
                validate: (value) => value >= 0
              }}
              name={`entries.${entry.index}.value`}
              render={({field: {ref, value, onChange}}) => {
                const error = errors?.entries?.[entry.index]?.value
                const helperText = (error && error.message) || ''

                return (
                  <TextField
                    {...dataTestId(`value-text-field-${entry.id}`)}
                    fullWidth
                    onClick={(event) => event.stopPropagation()}
                    onChange={onChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{Icon}</InputAdornment>
                    }}
                    value={value}
                    label={name}
                    inputRef={ref}
                    type="number"
                    variant="filled"
                    error={!!error}
                    helperText={helperText}
                  />
                )
              }}
            />

            <Box
              display="flex"
              flexDirection="row"
              alignContent="center"
              gap={1}
              sx={{cursor: 'pointer'}}
              {...dataTestId('kpi-show-comments-button')}
              onClick={() => setSelectedKpi(entry)}
            >
              <Typography variant="body1">
                <LayersOutlinedIcon />
              </Typography>
              <Typography variant="body1">{versions}</Typography>
              <Typography variant="body1">
                <ChatBubbleOutlineIcon />
              </Typography>
              <Typography variant="body1">{comments}</Typography>
            </Box>
          </Box>
        )
      })}
    </Stack>
  )
}
