import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../api/mutations'
import {invalidateSettingsQuery} from '../../common/providers/ApiInterface'
import type {ErrorResponse} from '../../common/types'
import {notifyIfErrorMessage} from '../../common/utils/errorHandling'
import {Kpi} from '../types'

export const useEditKpiUnit = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<Kpi, AxiosError<ErrorResponse>, Parameters<typeof mutations.editKpiUnit>[0]>(
    mutations.editKpiUnit,
    {
      onError: (error) => {
        notifyIfErrorMessage(error.response?.data.message, raiseError, notify)
      },
      onSuccess: () => {
        notify('success', t('success.editKpiUnit'))
      },
      onSettled: (data, error, variables) => {
        invalidateSettingsQuery('kpis', variables.plantCode as string)
      }
    }
  )
}
