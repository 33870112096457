import {MeasurementTypesMapping} from '../../types/MeasurementTypesMapping'

import {Api} from '@settings/api'

export const MAP_MEASUREMENT_TYPE_MUTATION_KEY = 'mapMeasurementType'

type MapMeasurementTypeParameters = {plantCode: string} & Omit<MeasurementTypesMapping, 'id'>

export async function mapMeasurementType({
  plantCode,
  globalMeasurementTypeId,
  limsMeasurementTypeId
}: MapMeasurementTypeParameters): Promise<void> {
  const path = `/plants/${plantCode}/materials/measurement-types`
  await Api.axiosInstance.post(path, {globalMeasurementTypeId, limsMeasurementTypeId})
}
