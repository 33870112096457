import {dataTestId} from '@hconnect/uikit'
import {CardBox, ExpandCollapseButton, Loader} from '@hconnect/uikit/src/lib2'
import {Box, Button, Grid, Stack, TextField} from '@mui/material'
import {styled} from '@mui/material/styles'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'

import {PathNode, findEntityPath} from '../../../common/helpers'
import {useGetPlantStructure} from '../../../common/hooks/useGetPlantStructure'
import {UpmEntityType} from '../../../common/types'
import {useUrlParam} from '../../../routing'
import {useSelectedItem} from '../SelectedItemProvider'

import {ItemDetails} from './ItemDetails'
import {ListItem} from './ListItem'

const FilterOptionButton = styled(Button)(() => ({
  textTransform: 'none',
  backgroundColor: '#F7F9FA',
  border: '1px solid #E8ECF0',
  borderRadius: '16px',
  height: '32px',
  color: '#52667A',
  padding: '0.5',
  '&[data-selected="true"]': {
    color: '#fff',
    backgroundColor: '#016AD4'
  }
}))

enum FilterOptions {
  All = 'all',
  Kilns = 'kilns',
  Mills = 'mills',
  Storages = 'storages'
}

export const PlantStructure = () => {
  const {data: plantStructure, isLoading} = useGetPlantStructure()

  const [expandAll, setExpandAll] = useState<boolean>(false)
  const {t} = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedFilterOption, setSelectedFilterOption] = useState<FilterOptions>(FilterOptions.All)
  const [pathNodes, setPathNodes] = useState<PathNode[]>([])
  const [expandedAfterDirectEntry, setExpandedAfterDirectEntry] = useState<boolean>(false)

  const plantCode = useUrlParam('plantCode')

  const selectedEntityId = useMemo(
    () => searchParams.get('entityId') ?? plantCode,
    [plantCode, searchParams]
  )
  const selectedEntityType = useMemo(
    () => (searchParams.get('entityType') ?? 'Plant') as UpmEntityType,
    [searchParams]
  )

  const {selectedItem, setSelectedItem} = useSelectedItem()

  useEffect(() => {
    if (plantStructure) {
      const entity = findEntityPath(plantStructure, selectedEntityId, selectedEntityType)
      const lastItem = entity.path.pop()
      const selected = lastItem ? {...lastItem, children: []} : undefined
      setSelectedItem(selected)
      if (!expandedAfterDirectEntry) {
        if (entity?.path.length > 0) {
          setPathNodes(entity?.path || [])
          setExpandedAfterDirectEntry(true)
        }
      }
    }
  }, [
    expandedAfterDirectEntry,
    plantStructure,
    selectedEntityId,
    selectedEntityType,
    setSelectedItem
  ])

  if (isLoading) {
    return <Loader />
  }

  return (
    <Grid container spacing={2} data-test-id={dataTestId}>
      <Grid item xs={12} sm={4}>
        <CardBox p={2}>
          <Stack
            direction="column"
            justifyContent="space-between"
            {...dataTestId('janus-plant-structure-menu')}
            alignItems={'left'}
            gap={2}
          >
            <Box
              gap={1}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: {xs: 'column', sm: 'row'}
              }}
            >
              {Object.values(FilterOptions).map((option) => (
                <FilterOptionButton
                  data-selected={option === selectedFilterOption}
                  onClick={() => setSelectedFilterOption(option)}
                  key={option}
                  variant="outlined"
                  {...dataTestId(`plant-structure-type-filter-${option}`)}
                >
                  {t(`janusConfig.label.${option}`)}
                </FilterOptionButton>
              ))}
            </Box>
            <TextField
              fullWidth
              label={t('janusConfig.label.filter')}
              variant="filled"
              {...dataTestId('plant-structure-text-filter')}
            />
            <Box>
              <ExpandCollapseButton
                variant="text"
                expanded={false}
                onClick={() => setExpandAll((prev) => !prev)}
                expandLabel={t('janusConfig.action.expand')}
                {...dataTestId('janus-plant-structure-expand-all-button')}
              />
            </Box>
            <ListItem
              data={plantStructure}
              level={1}
              initiallyExpandedNodes={pathNodes}
              onClick={(item) =>
                setSearchParams((params) => {
                  params.set('entityId', item.id)
                  params.set('entityType', item.entityType)
                  return params
                })
              }
              selectedItemId={selectedItem?.id}
              expandAll={expandAll}
              setExpandAll={(expand: boolean) => setExpandAll(expand)}
            />
          </Stack>
        </CardBox>
      </Grid>
      <Grid item xs={12} sm={8}>
        <ItemDetails />
      </Grid>
    </Grid>
  )
}
