import type {AssetResponse} from '@hconnect/common/types'
import {dataTestId, useNotification} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {Add, DeleteOutlined} from '@mui/icons-material'
import {Stack, Box, Button} from '@mui/material'
import {AxiosError} from 'axios'
import {useCallback, useState} from 'react'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'

import {useLatestData} from '../../../common/hooks/useLatestData'
import {useConfirmDialog} from '../../../common/providers'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'
import {usePermission} from '../../../permissions'
import {useUrlParam} from '../../../routing'
import {
  useAddStandardOperationTime,
  useAssetsQuery,
  useDefaultAssetOperationTimesQuery,
  useDeleteStandardOperationTime,
  useUpdateStandardOperationTime
} from '../../hooks'
import {useAssetData} from '../../hooks/useAssetData'
import type {
  AssetStandardOperationTimeData,
  AssetStandardOperationTimeValidationError,
  NewAssetStandardOperationTimeData
} from '../../types'
import {AssetStandardOperationTimeConflictResolutionAction} from '../../types'

import {
  StandardOperationTimeAddOrEditDialog,
  StandardOperationTimeValidationError
} from './editDialog/StandardOperationTimeAddOrEditDialog'
import {StandardOperationTimePreview} from './StandardOperationTimePreview'

import moment from 'moment-timezone'
import {Typography} from '@mui/material'

type StandardOperationTimesCardProps = {
  asset: AssetResponse
}

export const StandardOperationTimesCard = ({asset}: StandardOperationTimesCardProps) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {openDialog} = useConfirmDialog()
  const {timezone_id} = useLatestData()
  const {notify} = useNotification()
  const raiseError = useErrorHandler()

  const [dialog, changeDialog] = useState<
    | {type: 'edit'; standardOperationTime: AssetStandardOperationTimeData}
    | {type: 'add'}
    | undefined
  >(undefined)
  const [validateError, changeValidateError] = useState<StandardOperationTimeValidationError>()

  const canChangeAssets = usePermission('CHANGE_ASSETS')
  const canViewMachinePlan = usePermission('VIEW_MACHINE_PLAN')
  const canChangeMachinePlan = usePermission('CHANGE_MACHINE_PLAN')

  const onSaveSuccess = useCallback(() => {
    changeDialog(undefined)
    changeValidateError(undefined)
  }, [changeDialog])

  const onCancelConflictDialog = useCallback(() => {
    changeValidateError(undefined)
  }, [changeValidateError])

  const {mutate: deleteStandardOperationTime} = useDeleteStandardOperationTime()
  const {mutate: addStandardOperationTime, isLoading: isAddLoading} = useAddStandardOperationTime({
    onSuccess: onSaveSuccess
  })
  const {mutate: updateStandardOperationTime, isLoading: isUpdateLoading} =
    useUpdateStandardOperationTime({onSuccess: onSaveSuccess})
  const isLoading = isAddLoading || isUpdateLoading

  const onSubmitDialog = useCallback(
    (
      operationTime: NewAssetStandardOperationTimeData,
      onConflict: AssetStandardOperationTimeConflictResolutionAction
    ) => {
      const handleValidationError = (
        error: AxiosError<AssetStandardOperationTimeValidationError, void>
      ) => {
        if (!error.response?.data) {
          return changeValidateError(undefined)
        }
        if (error.response.data.extendedData) {
          if (error.response.data.extendedData.conflictItems.length > 0) {
            changeValidateError({
              data: operationTime,
              validateResult: error.response.data.extendedData
            })
          } else {
            notifyIfErrorMessage(error.response?.data.extendedData.errorMessage, raiseError, notify)
          }
        }
      }

      if (dialog?.type === 'add') {
        return addStandardOperationTime(
          {
            plantCode,
            assetId: asset.id,
            onConflict,
            ...operationTime
          },
          {
            onError: handleValidationError
          }
        )
      }
      if (dialog?.type === 'edit') {
        return updateStandardOperationTime(
          {
            plantCode,
            assetId: asset.id,
            onConflict,
            defaultOperationTimeId: dialog.standardOperationTime.id,
            ...operationTime
          },
          {
            onError: handleValidationError
          }
        )
      }
      throw new Error('Unknown dialog type')
    },
    [
      addStandardOperationTime,
      updateStandardOperationTime,
      changeValidateError,
      notify,
      raiseError,
      plantCode,
      dialog,
      asset.id
    ]
  )

  const onOverwriteConflicts = useCallback(() => {
    if (!validateError) {
      throw new Error('Cannot do this action if there is no validation error')
    }
    onSubmitDialog(validateError.data, AssetStandardOperationTimeConflictResolutionAction.Overwrite)
  }, [onSubmitDialog, validateError])
  const onSkipConflicts = useCallback(() => {
    if (!validateError) {
      throw new Error('Cannot do this action if there is no validation error')
    }
    onSubmitDialog(validateError.data, AssetStandardOperationTimeConflictResolutionAction.Skip)
  }, [onSubmitDialog, validateError])

  const onClose = useCallback(() => {
    changeDialog(undefined)
  }, [changeDialog])

  const {data: assets} = useAssetsQuery()
  const assetIds = (assets ?? []).map(({id}) => id)
  const selectedAsset = useAssetData()
  const {data: standardOperationTimes} = useDefaultAssetOperationTimesQuery({
    assetsIds: assetIds,
    timezone_id
  })

  const getStandardOperationTimesForAsset = () => {
    const currentStandardOperationTimes: AssetStandardOperationTimeData[] =
      standardOperationTimes?.[selectedAsset.id] ?? []
    return currentStandardOperationTimes
  }

  const standartOperationTimes = getStandardOperationTimesForAsset()

  const onClickDelete = useCallback(
    (standardOperationTimeId: number) => {
      const standardOperationTime = standartOperationTimes.find(
        ({id}) => id === standardOperationTimeId
      )
      if (!standardOperationTime) throw new Error('Cannot find standart operation time')

      const {scheduleItems, hasMore} = standardOperationTime.occurrences
      const visibleOccurences = scheduleItems.map((occurence) =>
        moment.tz(occurence, timezone_id).format('LLL')
      )
      const deletingScheduleItemStarts = [...visibleOccurences, ...(hasMore ? ['...'] : [])]

      openDialog({
        testId: 'delete_standard_operation_time_confirmation_dialog',
        title: t('assetsSettings.standardOperationTimes.delete'),
        description: t('assetsSettings.standardOperationTimes.deleteConfirmation'),
        additionalContent:
          deletingScheduleItemStarts.length > 0 ? (
            <Typography variant="caption" color="textSecondary">
              {t('assetsSettings.standardOperationTimes.associatedScheduledItems', {
                items: deletingScheduleItemStarts.join(', ')
              })}
            </Typography>
          ) : undefined,
        mainAction: {
          text: t('common.delete'),
          color: 'error',
          icon: <DeleteOutlined />,
          onAction: () => {
            deleteStandardOperationTime({
              plantCode,
              AssetStandardOperationTimeId: standardOperationTimeId
            })
          }
        }
      })
    },
    [deleteStandardOperationTime, openDialog, plantCode, standartOperationTimes, timezone_id, t]
  )

  const onClickEdit = useCallback(
    (standartOperationTimeId: number) => {
      const standartOperationTime = standartOperationTimes.find(
        ({id}) => id === standartOperationTimeId
      )
      if (!standartOperationTime) throw new Error('Cannot find standart operation time')
      changeDialog({type: 'edit', standardOperationTime: standartOperationTime})
    },
    [changeDialog, standartOperationTimes]
  )
  const onAdd = useCallback(() => {
    changeDialog({type: 'add'})
  }, [changeDialog])

  if (!canViewMachinePlan) return null

  const {operationModes: availableOperationModes} = selectedAsset

  const isReadOnly = !canChangeAssets || !canChangeMachinePlan
  const isAddButtonDisabled = availableOperationModes.length === 0 || isReadOnly

  return (
    <>
      <Card
        headerContent={
          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <CardTitle sx={{mb: 0}}>
              {t('assetsSettings.standardOperationTimesTitle', {
                amount: (standardOperationTimes?.[selectedAsset.id] ?? []).length
              })}
            </CardTitle>
            <Button
              variant="text"
              startIcon={<Add />}
              disabled={isAddButtonDisabled}
              onClick={() => {
                onAdd()
              }}
              {...dataTestId('add_operation_time')}
            >
              {t('assetsSettings.addOperationTime')}
            </Button>
          </Box>
        }
        {...dataTestId('standard_operation_times_card')}
      >
        <Stack spacing={3}>
          {standartOperationTimes.map((operationTime, index) => (
            <StandardOperationTimePreview
              index={index}
              key={operationTime.id}
              operationTime={operationTime}
              onDelete={() => onClickDelete(operationTime.id)}
              onEdit={() => onClickEdit(operationTime.id)}
              operationModes={availableOperationModes}
              isReadOnly={isReadOnly}
            />
          ))}
        </Stack>
      </Card>
      {!isReadOnly && dialog && (
        <StandardOperationTimeAddOrEditDialog
          {...dialog}
          assetId={asset.id}
          operationModes={availableOperationModes}
          onOverwriteConflicts={onOverwriteConflicts}
          onCancelConflictDialog={onCancelConflictDialog}
          validateError={validateError}
          onSkipConflicts={onSkipConflicts}
          isLoading={isLoading}
          onSubmit={onSubmitDialog}
          onClose={onClose}
        />
      )}
    </>
  )
}
