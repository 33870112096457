import type {OperationModeResponse} from '@hconnect/common/types'
import React from 'react'
import {Trans} from 'react-i18next'

import {InfoGroup} from '../../../common/components'
import type {ScheduleItem} from '../../../common/types'

interface OperationModeDeleteInfoProps {
  scheduleItems: ScheduleItem[]
  operationMode: OperationModeResponse
}

export const OperationModeDeleteInfo: React.FC<OperationModeDeleteInfoProps> = ({
  scheduleItems,
  operationMode
}) => {
  return (
    <InfoGroup
      description={
        <Trans
          i18nKey="assetsSettings.deleteOperationModeConfirmation"
          values={{name: operationMode.name}}
        />
      }
      panels={
        scheduleItems.length > 0
          ? [
              {
                accentColor: 'warning',
                content: (
                  <Trans
                    i18nKey="assetsSettings.deleteOperationModeAssignedScheduleItems"
                    values={{
                      number: scheduleItems.length
                    }}
                  />
                )
              }
            ]
          : undefined
      }
    />
  )
}
