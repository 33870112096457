import moment from 'moment'

export const getDailyDataCanBeEdited = (currentDate: moment.Moment, date?: string) => {
  if (!date) {
    return
  }
  const inputDate = moment(date)

  const nextMonthAfterNinth = inputDate.clone().add(1, 'month').date(10).endOf('day')

  if (inputDate.isSame(currentDate, 'month')) {
    return true
  }

  if (currentDate.isAfter(nextMonthAfterNinth)) {
    return false
  }

  return true
}
