import {DateTimeFormat} from '@hconnect/common/components/dateTime/DateTimeFormat'
import {dataTestId} from '@hconnect/uikit'
import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {alpha, Box, Button, Stack, TextField, Tooltip, Typography} from '@mui/material'
import i18next, {TFunction} from 'i18next'
import React, {FC} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useUrlParam} from '../../../../modules/routing'
import {useVerifyKpiFormula} from '../../hooks/useVerifyKpiFormula'
import {Frequency, LastResult} from '../../types'
import {isValueCorrectNumber, isValueError} from '../../utils/lastResult'

import {KpiLastResult} from './KpiLastResult'

import {getFrequencyLabel} from '@settings/modules/kpi-calculation/utils/dates.utils'

type FormulaFormProps = {
  formula: string | null
  kpiId: number
  frequency: Frequency
  lastResult?: LastResult
}

const MATHEMATICAL_OPERATORS = ['+', '-', '*', '/', '(', ')', '<', '>', '=']

const getErrorMessage = (value?: string | number) => {
  if (i18next.exists(`kpiCalculationSettings.error.${value}`)) {
    return `kpiCalculationSettings.error.${value}`
  }

  return 'kpiCalculationSettings.error.generic'
}

const getTooltipTitle = (t: TFunction, lastResult?: LastResult): React.ReactNode => {
  if (isValueCorrectNumber(lastResult)) {
    return (
      <Stack display="flex" flexWrap="nowrap" gap={0.5}>
        <Typography>{t('kpiCalculationSettings.label.from')}:</Typography>
        <DateTimeFormat
          date={lastResult?.startTime}
          timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        />
        <Typography>{t('kpiCalculationSettings.label.to')}:</Typography>
        <DateTimeFormat
          date={lastResult?.endTime}
          timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        />
      </Stack>
    )
  }
  if (isValueError(lastResult)) {
    return <Typography>{t(getErrorMessage(lastResult?.value))}</Typography>
  }
  return ''
}

export const FormulaForm: FC<FormulaFormProps> = ({formula, kpiId, lastResult, frequency}) => {
  const plantCode = useUrlParam('plantCode')
  const {mutate: verifyKpiFormula} = useVerifyKpiFormula()
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const {
    handleSubmit,
    control,
    reset,
    formState: {dirtyFields}
  } = useForm<{customFormula?: string}>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {customFormula: formula || undefined}
  })

  const submit = handleSubmit(({customFormula}) => {
    if (!dirtyFields.customFormula) return
    verifyKpiFormula(
      {formula: customFormula, kpiId, plantCode},
      {
        onError: () => reset(),
        onSuccess: () => {
          reset({customFormula})
        }
      }
    )
  })

  return (
    <form onSubmit={submit}>
      <Box
        {...dataTestId('edit_kpi_form')}
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Controller
          control={control}
          name="customFormula"
          render={({field: {ref, value, onChange}, fieldState: {error}}) => (
            <TextField
              sx={{
                width: '100%',
                '& .MuiInputBase-root': {
                  pt: 1
                }
              }}
              value={value}
              placeholder={value}
              multiline={true}
              minRows={3}
              inputRef={ref}
              variant="filled"
              onChange={onChange}
              helperText={error?.message}
              error={Boolean(error?.message)}
              {...dataTestId('custom_formula_input')}
            />
          )}
        />
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}} mt={2}>
          <Box sx={{display: 'flex', flexWrap: {xs: 'wrap', md: 'no-wrap'}}} gap={1.5}>
            {MATHEMATICAL_OPERATORS.map((operator) => (
              <Button
                key={operator}
                sx={(theme) => ({
                  width: 40,
                  minWidth: 40,
                  height: 40,
                  boxShadow: 2,
                  borderColor: alpha(theme.palette.secondary.dark, 0.1)
                })}
                color="secondary"
                {...dataTestId(`custom-formula-operator-${operator}`)}
              >
                {operator}
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
              {lastResult && isValueCorrectNumber(lastResult) && (
                <Typography pr={1} variant="caption">
                  {getFrequencyLabel(frequency)}
                </Typography>
              )}
              <Tooltip
                title={getTooltipTitle(t, lastResult)}
                placement="top"
                {...dataTestId('last_result_time_range')}
              >
                <Box
                  sx={(theme) => ({
                    backgroundColor: customThemeConstants().palette.contentHover,
                    padding: '10px',
                    marginRight: theme.spacing(1),
                    border: `1px solid ${customThemeConstants().palette.contentSelectedHover}`,
                    borderRadius: 1
                  })}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: 'primary.main'
                    }}
                    {...dataTestId('custom_formula_result')}
                  >
                    <KpiLastResult language={language} lastResult={lastResult} prefix="= " />
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
            <Button type="submit" variant="contained" {...dataTestId('formula-form-verify')}>
              {t('kpiCalculationSettings.action.verify')}
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  )
}
