import {useSettingsQuery} from '../../common/providers/ApiInterface'
import {useUrlParam} from '../../routing'
import {CalendarRange} from '../enums'

export const useCalendar = (range: CalendarRange, date?: string) => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('calendarInfo', [plantCode, range, date], {
    enabled: !!date,
    cacheTime: 0,
    staleTime: 0
  })
}
