import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../api/mutations'
import {getSettingsQueryData} from '../../../../modules/common/providers/ApiInterface'
import {settingsQueryClient} from '../../../../modules/common/providers/QueryProvider'
import {notifyIfErrorMessage} from '../../../../modules/common/utils/errorHandling'
import {ErrorResponse} from '../../../common/types'
import {Material} from '../../types'

export const useAddMaterial = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<
    Material,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.addMaterial>[0]
  >(mutations.addMaterial, {
    onError: (error) => {
      const data = error.response?.data
      notifyIfErrorMessage(data?.detail ?? data?.message, raiseError, notify)
    },
    onSuccess: (material, variables) => {
      notify('success', t('success.addMaterial'))
      const prevMaterials = getSettingsQueryData('materials', [variables.plantCode])
      if (prevMaterials) {
        const newMaterials = [...prevMaterials, material]
        void settingsQueryClient.setQueryData(['materials', variables.plantCode], newMaterials)
      }
    }
  })
}
