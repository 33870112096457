import {IconButton, Popover, PopoverOrigin} from '@mui/material'
import React, {useRef, useState} from 'react'

type Props = {
  anchorOrigin?: PopoverOrigin
  children: React.ReactNode
  'data-test-id'?: string
  closeOnClick?: boolean
  icon: React.ReactNode
  iconSize?: 'small' | 'medium'
  transformOrigin?: PopoverOrigin
}

export const IconWithPopover: React.FC<Props> = (props) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const refContainer = useRef(null)

  return (
    <>
      <IconButton
        sx={{border: 'none', boxShadow: 'none', color: 'primary.main'}}
        data-test-id={props['data-test-id']}
        ref={refContainer}
        onClick={() => setOpen(!isOpen)}
        size={props.iconSize ?? 'small'}
      >
        {props.icon}
      </IconButton>
      <Popover
        sx={{
          '& .MuiPopover-paper': {
            marginTop: (theme) => theme.spacing(1),
            maxWidth: '60%',
            background: 'rgb(247, 247, 247)'
          }
        }}
        anchorEl={refContainer?.current}
        anchorOrigin={props.anchorOrigin}
        data-test-id="popover"
        onClick={() => props.closeOnClick && setOpen(false)}
        onClose={() => setOpen(false)}
        open={isOpen}
        transformOrigin={props.transformOrigin}
      >
        {props.children}
      </Popover>
    </>
  )
}
