import ErrorIcon from '@material-ui/icons/BlockOutlined'
import {toNumber} from 'lodash'
import React from 'react'

import {formatNumber} from '../../../common/utils/numberFormat.helper'
import {LastResult} from '../../types'
import {isValueCorrectNumber, isValueError} from '../../utils/lastResult'

type Props = {
  language?: string
  lastResult?: LastResult
  prefix?: string
}

export const KpiLastResult = ({language, lastResult, prefix = ''}: Props) => {
  if (lastResult && isValueCorrectNumber(lastResult) && language) {
    return <>{`${prefix}${formatNumber(toNumber(lastResult.value), language)}`}</>
  }

  if (isValueError(lastResult)) {
    return (
      <ErrorIcon color="error" style={{fontSize: 16}} data-test-id="kpi-last-result-error-icon" />
    )
  }

  return <>-</>
}
