import type {PermissionType} from '@hconnect/apiclient'

import {useLoginDetails, useSettingsQuery} from '../../../common/providers'
import type {PermissionParsed} from '../../types'

const selector = (permissionTypes: PermissionType[]) => (permissions: PermissionParsed[]) =>
  permissions.filter((entry) => permissionTypes.includes(entry.permissionType))

export const usePermissionsQuery = (permissionTypes?: PermissionType[]) => {
  const {loginDetails} = useLoginDetails()
  if (!loginDetails) throw new Error('Login details data is missing!')
  const {userId} = loginDetails
  return useSettingsQuery('permissions', [userId], {
    select: permissionTypes ? selector(permissionTypes) : undefined
  })
}
