import {
  AssetType,
  OperationModeType,
  type AssetLink,
  type AssetResponse
} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Add, DeleteOutline, DeleteOutlined, DriveFileMoveOutlined} from '@mui/icons-material'
import {IconButton, MenuItem, TextField} from '@mui/material'
import {Box} from '@mui/system'
import {TFunction} from 'i18next'
import {useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import type {SettingsListItem} from '../../../common/components'
import {MenuAction} from '../../../common/components'
import {SettingsList} from '../../../common/components/settingsList'
import {useScheduleQuery} from '../../../common/hooks'
import {useConfirmDialog} from '../../../common/providers'
import type {Schedule} from '../../../common/types'
import {usePermission} from '../../../permissions'
import {PLANT_ROUTES, getUrl, useUrlParam} from '../../../routing'
import {useAssetsQuery, useAttachedSilosQuery, useDeleteAsset, useEditAsset} from '../../hooks'

import {AssetDeleteInfo} from './AssetDeleteInfo'
import {AssetMoveInfo} from './AssetMoveInfo'

const getAssetTypeList = (t: TFunction, excludedType?: AssetType) =>
  Object.values(AssetType)
    .filter((type) => type !== excludedType)
    .map((type) => (
      <MenuItem key={type} value={type} {...dataTestId(`${type}_select_item`)}>
        {t(`assetsSettings.assetTypes.${type}`)}
      </MenuItem>
    ))

export const AssetsList = () => {
  const navigate = useNavigate()
  const plantCode = useUrlParam('plantCode')
  const assetType = useUrlParam('assetType')

  const relativePath = useUrlParam('*')
  const subDirectory = relativePath.split('/')[2] as 'id' | 'new'
  const isNewAsset = subDirectory === 'new'

  const parsedAssetId: undefined | string = isNewAsset ? undefined : relativePath.split('/')[3]
  const selectedAssetId = parsedAssetId ? Number(parsedAssetId) : undefined

  const {data: schedule} = useScheduleQuery()
  const {data: attachedSilosLinks} = useAttachedSilosQuery(selectedAssetId)

  const {data: assetsForType} = useAssetsQuery({sorted: true, types: [assetType as AssetType]})

  const {openDialog} = useConfirmDialog()
  const {t} = useTranslation()

  const canChangeAssets = usePermission('CHANGE_ASSETS')

  const {mutateAsync: deleteAsset, isLoading: isAssetDeleting} = useDeleteAsset()
  const {mutateAsync: editAsset, isLoading: isAssetEditing} = useEditAsset()

  const moveAssetSelectRef = useRef<HTMLSelectElement>()

  const handleDeleteAsset = (asset: AssetResponse) => {
    void deleteAsset(
      {plantCode, assetId: asset.id},
      {
        onSuccess: () =>
          navigate(getUrl(PLANT_ROUTES.ASSETS.ASSET_TYPE.path, {plantCode, assetType}))
      }
    )
  }

  function openDeleteAssetDialog(schedule: Schedule, asset: AssetResponse) {
    const operationModes = asset.operationModes
    const scheduleItems = Object.values(schedule.schedules).filter(
      (item) => operationModes.find((mode) => mode.id === item.assetOperationModeId) !== undefined
    )
    openDialog({
      title: t('assetsSettings.deleteAsset'),
      mainAction: {
        color: 'error',
        text: t('common.delete'),
        icon: <DeleteOutline />,
        onAction: () => handleDeleteAsset(asset)
      },
      additionalContent: (
        <AssetDeleteInfo
          scheduleItems={scheduleItems}
          asset={asset}
          operationModes={operationModes}
        />
      )
    })
  }

  const getAssetsActions = (
    schedule: Schedule,
    asset: AssetResponse,
    attachedSilosLinks: AssetLink[]
  ): MenuAction[] => {
    if (asset.type === AssetType.BaseLoad) return []
    return [
      {
        icon: <DriveFileMoveOutlined fontSize="small" />,
        title: t('common.move'),
        isDisabled: isAssetEditing || !canChangeAssets,
        testId: 'move_asset',
        onClick: () => {
          const operationModes = asset.operationModes.filter(
            (om) => om.type !== OperationModeType.Maintenance
          )
          if (operationModes.length > 0 || attachedSilosLinks.length > 0) {
            openDialog({
              title: t('assetsSettings.moveAssetNotPossible'),
              showCancelButton: false,
              mainAction: {
                text: t('common.ok')
              },
              additionalContent: (
                <AssetMoveInfo
                  asset={asset}
                  operationModes={operationModes}
                  silos={attachedSilosLinks}
                />
              )
            })
            return
          }
          openDialog({
            title: t('assetsSettings.moveAsset'),
            description: t('assetsSettings.moveAssetDescription'),
            mainAction: {
              text: t('common.move'),
              onAction: () => {
                if (moveAssetSelectRef.current?.value) {
                  const newAssetType = moveAssetSelectRef.current?.value as AssetType
                  void editAsset(
                    {
                      key: 'type',
                      plantCode,
                      assetId: asset.id,
                      dto: {name: asset.name, type: newAssetType}
                    },
                    {
                      onSuccess: (editedAsset) => {
                        navigate(
                          getUrl(PLANT_ROUTES.ASSETS.ASSET_TYPE.ID.ASSET_ID.path, {
                            plantCode,
                            assetType: newAssetType,
                            assetId: String(editedAsset.id)
                          })
                        )
                      }
                    }
                  )
                }
              }
            },
            additionalContent: (
              <TextField
                inputRef={moveAssetSelectRef}
                variant="outlined"
                fullWidth
                select
                label={t('assetsSettings.assetType')}
                defaultValue={Object.values(AssetType).find((type) => type !== asset.type)}
                {...dataTestId('dialog_asset_type_select')}
              >
                {getAssetTypeList(t, asset.type)}
              </TextField>
            )
          })
        }
      },
      {
        icon: <DeleteOutlined fontSize="small" color="error" />,
        title: t('common.delete'),
        isDisabled: isAssetDeleting || !canChangeAssets,
        testId: 'delete_asset',
        onClick: () => {
          openDeleteAssetDialog(schedule, asset)
        }
      }
    ]
  }

  const isAssetSelected = (asset: AssetResponse) =>
    selectedAssetId !== undefined && asset.id === selectedAssetId

  const isAssetTypeBaseload = (assetType as AssetType) === AssetType.BaseLoad

  const newAssetItem: SettingsListItem = {
    text: t('assetsSettings.newAsset'),
    isSelected: true,
    testId: 'new_asset_list_item',
    listItemTextSx: {fontStyle: 'italic'},
    secondaryActions: [
      {
        icon: <DeleteOutlined />,
        onClick: () => navigate(getUrl(PLANT_ROUTES.ASSETS.ASSET_TYPE.path, {plantCode, assetType}))
      }
    ]
  }

  const existingAssetListItems: SettingsListItem[] =
    assetsForType?.map((asset) => ({
      text: asset.name,
      isSelected: isAssetSelected(asset),
      testId: 'asset_list_item',
      onClick: () =>
        navigate(
          getUrl(PLANT_ROUTES.ASSETS.ASSET_TYPE.ID.ASSET_ID.path, {
            plantCode,
            assetType: asset.type,
            assetId: String(asset.id)
          })
        ),
      secondaryActions:
        isAssetSelected(asset) && schedule && attachedSilosLinks !== undefined
          ? getAssetsActions(schedule, asset, attachedSilosLinks)
          : undefined
    })) ?? []

  const assetListItems: SettingsListItem[] = [
    ...(isNewAsset ? [newAssetItem] : []),
    ...existingAssetListItems
  ]

  const isAddNewAssetButtonDisabled = isNewAsset || !canChangeAssets || isAssetTypeBaseload

  return (
    <SettingsList
      headerContent={
        <>
          <TextField
            variant="outlined"
            fullWidth
            select
            label={t('assetsSettings.assetType')}
            value={assetType}
            // disabling asset type change when creating new asset for simplicity
            disabled={isNewAsset}
            onChange={(e) => {
              const newAssetType = e.target.value as AssetType
              navigate(
                getUrl(PLANT_ROUTES.ASSETS.ASSET_TYPE.path, {plantCode, assetType: newAssetType})
              )
            }}
            {...dataTestId('asset_type_select')}
          >
            {getAssetTypeList(t)}
          </TextField>
          <Box>
            <IconButton
              sx={{ml: 2}}
              disabled={isAddNewAssetButtonDisabled}
              onClick={() =>
                navigate(
                  getUrl(PLANT_ROUTES.ASSETS.ASSET_TYPE.NEW_ASSET.path, {
                    plantCode,
                    assetType
                  })
                )
              }
              {...dataTestId('add_new_asset')}
            >
              <Add color="primary" />
            </IconButton>
          </Box>
        </>
      }
      items={assetListItems}
      testId="assets_list"
    />
  )
}
