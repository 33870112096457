import {dataTestId} from '@hconnect/uikit'
import {ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon} from '@mui/icons-material'
import {Collapse, ListItemButton, ListItemIcon, ListItemText, IconButton} from '@mui/material'
import {useEffect, useState} from 'react'

import {PathNode} from '../../../common/helpers'
import {StructureNode} from '../../../common/types'

export const ListItem = ({
  data,
  level,
  onClick,
  selectedItemId,
  expandAll,
  setExpandAll,
  initiallyExpandedNodes
}: {
  data?: StructureNode
  level: number
  onClick: (item: StructureNode) => void
  selectedItemId?: string
  expandAll: boolean
  setExpandAll: (boolean) => void
  initiallyExpandedNodes: PathNode[]
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    setExpanded(
      (prev) => prev || expandAll || initiallyExpandedNodes.some((node) => node.id === data?.id)
    )
  }, [data?.id, expandAll, initiallyExpandedNodes])

  if (!data) {
    return null
  }

  return (
    <>
      <ListItemButton
        key={data.id}
        selected={data.id === selectedItemId}
        sx={{
          ml: level,
          borderBottom: '1px solid #E8ECF0'
        }}
        onClick={() => onClick(data)}
        {...dataTestId(`list-item-${data.id}`)}
      >
        <ListItemText
          primary={`${data.name} (${data.signalCount ?? 0})`}
          primaryTypographyProps={{variant: 'body1'}}
        />
        {data?.children && data.children.length > 0 && (
          <ListItemIcon
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              height: '40px',
              width: '40px'
            }}
          >
            <IconButton
              size="medium"
              onClick={(e) => {
                if (expanded) {
                  setExpandAll(false)
                }
                setExpanded((prev) => !prev)
                e.stopPropagation()
              }}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </ListItemIcon>
        )}
      </ListItemButton>
      <Collapse in={expanded} timeout="auto">
        {data.children?.map((item) => (
          <ListItem
            onClick={onClick}
            initiallyExpandedNodes={initiallyExpandedNodes}
            selectedItemId={selectedItemId}
            key={item.id}
            data={item}
            level={level + 2}
            expandAll={expandAll}
            setExpandAll={setExpandAll}
          />
        ))}
      </Collapse>
    </>
  )
}
