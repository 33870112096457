import {Status, MaterialStorage} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Add, DeleteOutlined} from '@mui/icons-material'
import {IconButton} from '@mui/material'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {SettingsList} from '../../../common/components'
import type {SettingsListItem} from '../../../common/components'
import {useMaterialsById} from '../../../materials/hooks'
import {usePermission} from '../../../permissions'
import {useSearchParam} from '../../../routing'
import {useMaterialStorageListQuery} from '../../hooks'
import {useSelectedStorage} from '../SelectedStorageProvider'

import {StorageTypeSelect} from './StorageTypeSelect'
import {useStorageListActions} from './useStorageListActions'

export const StorageList = () => {
  const {t} = useTranslation()
  const {selectedStorage, setSelectedStorage} = useSelectedStorage()
  const [selectedMaterialType] = useSearchParam('materialType')
  const {data: materialStorage} = useMaterialStorageListQuery(selectedMaterialType)
  const {data: materialsById} = useMaterialsById()

  if (!materialsById) {
    throw new Error('BUG: materialsById should be available to render MaterialStorageList')
  }
  const canChangeMaterialStorage = usePermission('CHANGE_MATERIAL_STORAGE')

  const {getStorageListActions} = useStorageListActions()

  const isStorageSelected = (storage: MaterialStorage) => {
    if (!selectedStorage || selectedStorage.status === Status.New) return false
    return selectedStorage.id === storage.id
  }

  const isNewStorageSelected = selectedStorage?.status === Status.New

  const newStorageItem: SettingsListItem = {
    text: t('storagesSettings.newStorage'),
    isSelected: true,
    testId: 'new_storage_list_item',
    listItemTextSx: {fontStyle: 'italic'},
    secondaryActions: [
      {
        icon: <DeleteOutlined />,
        onClick: () => setSelectedStorage(undefined)
      }
    ]
  }

  const existingStorageListItems: SettingsListItem[] =
    materialStorage?.map((storage) => ({
      text: storage.name,
      caption: storage.materialId
        ? materialsById[storage.materialId].name
        : t('storagesSettings.materialNotAssigned'),
      isSelected: isStorageSelected(storage),
      testId: 'storage_list_item',
      onClick: () => setSelectedStorage({id: storage.id, status: storage.status}),
      secondaryActions: isStorageSelected(storage) ? getStorageListActions(storage) : undefined
    })) ?? []

  const storageListItems: SettingsListItem[] = [
    ...(isNewStorageSelected ? [newStorageItem] : []),
    ...existingStorageListItems
  ]

  useEffect(() => {
    if (!selectedStorage && materialStorage && materialStorage.length > 0) {
      setSelectedStorage({id: materialStorage[0].id, status: materialStorage[0].status})
    }
  }, [selectedStorage, materialStorage, setSelectedStorage])

  const isAddNewStorageBtnDisabled = !canChangeMaterialStorage || isNewStorageSelected

  return (
    <SettingsList
      headerContent={
        <>
          <StorageTypeSelect />
          <div>
            <IconButton
              sx={{ml: 2}}
              disabled={isAddNewStorageBtnDisabled}
              {...dataTestId('add_new_storage')}
              onClick={() => setSelectedStorage({status: Status.New})}
              id="storage-add-button"
            >
              <Add color="primary" />
            </IconButton>
          </div>
        </>
      }
      items={storageListItems}
      testId="storages_list"
    />
  )
}
