import {useState} from 'react'

import {SamplingPointTypesAutocompleteOption} from '../../../../../types'

interface useGetSelectedTypeOptionParameters {
  samplingPointTypeOptions: SamplingPointTypesAutocompleteOption[]
  samplingPointType: string | undefined
}

export const useGetSelectedTypeOption = ({
  samplingPointTypeOptions,
  samplingPointType
}: useGetSelectedTypeOptionParameters) => {
  const [selectedTypeOption, setSelectedTypeOption] =
    useState<SamplingPointTypesAutocompleteOption | null>(
      samplingPointTypeOptions.find(
        (samplingPointTypeOption) => samplingPointTypeOption.label === samplingPointType
      ) ?? null
    )
  return {selectedTypeOption, setSelectedTypeOption}
}
