import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../../api/mutations'
import {settingsQueryClient} from '../../../../common/providers/QueryProvider'
import {
  getSettingsQueryData,
  invalidateSettingsQuery
} from '../../../../common/providers/ApiInterface'
import type {ErrorResponse} from '../../../../common/types'
import {notifyIfErrorMessage} from '../../../../common/utils/errorHandling'
import type {Recipe, MaterialWithRecipes} from '../../../types'

export const useEditMaterialRecipe = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<
    Recipe,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.editMaterialRecipe>[0]
  >(mutations.editMaterialRecipe, {
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSuccess: (editedRecipe, variables) => {
      notify('success', t('success.editRecipe'))
      const prevMaterialWithRecipes = getSettingsQueryData('materialWithRecipes', [
        variables.plantCode,
        variables.materialId
      ])
      if (!prevMaterialWithRecipes) {
        throw new Error('BUG: material data should be available for editing a recipe')
      }
      const newMaterialWithRecipe: MaterialWithRecipes = {
        ...prevMaterialWithRecipes,
        recipes: prevMaterialWithRecipes.recipes.map((recipe) => {
          if (recipe.id === variables.recipeId) {
            return editedRecipe
          }
          return recipe
        })
      }
      void settingsQueryClient.setQueryData(
        ['materialWithRecipes', variables.plantCode, variables.materialId],
        newMaterialWithRecipe
      )
      invalidateSettingsQuery('materialsRecipes')
    }
  })
}
