import {MaterialSource, Status} from '@hconnect/common/types'
import {Stack} from '@mui/material'
import React from 'react'

import {MaterialCard} from './general'
import {LimsMaterialsCard} from './lims-materials'
import {ProductsCard} from './products'
import {PxTrendCard} from './pxtrend'
import {RecipesCard} from './recipes'
import {useSelectedMaterial} from './SelectedMaterialProvider'

import {useFeatureFlag} from '@settings/modules/common/hooks/useFeatureFlag'

export const MaterialDetails = () => {
  const {selectedMaterial} = useSelectedMaterial()

  const isMaterialSelected = selectedMaterial !== undefined
  const isMaterialProducedInPlant =
    isMaterialSelected && selectedMaterial.source === MaterialSource.ProducedInPlant

  const shouldShowMaterialCard = isMaterialSelected
  const shouldShowPxTrendCard = isMaterialProducedInPlant && selectedMaterial.status !== Status.New
  const shouldShowProductsCard = isMaterialSelected && selectedMaterial.status !== Status.New

  const isLimsMaterialsCardEnabled = useFeatureFlag('limsMaterialsToMaterialMapping')
  const shouldShowLimsMaterialsCard =
    isLimsMaterialsCardEnabled && isMaterialSelected && selectedMaterial.status !== Status.New

  const shouldShowRecipesCard = isMaterialProducedInPlant && selectedMaterial.status !== Status.New

  return (
    <Stack spacing={2}>
      {shouldShowMaterialCard && <MaterialCard selectedMaterial={selectedMaterial} />}
      {shouldShowPxTrendCard && <PxTrendCard material={selectedMaterial} />}
      {shouldShowProductsCard && <ProductsCard material={selectedMaterial} />}
      {shouldShowLimsMaterialsCard && <LimsMaterialsCard material={selectedMaterial} />}
      {shouldShowRecipesCard && <RecipesCard />}
    </Stack>
  )
}
