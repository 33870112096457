import {dataTestId} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import Grid from '@mui/material/Unstable_Grid2'
import {useTranslation} from 'react-i18next'
import {Navigate, Route, Routes} from 'react-router'

import {PageLayout} from '../../common/components'
import {RouteName} from '../../routing'

import {EquipmentMenu} from './EquipmentMenu/EquipmentMenu'
import {EditSignal} from './SignalForm/EditSignal'
import {NewSignal} from './SignalForm/NewSignal'
import {SignalList} from './SignalList/SignalList'

export const PageUpmSettings = () => {
  const {t} = useTranslation()

  return (
    <PageLayout
      title={t('upmSettings.plantModel')}
      {...dataTestId(`page_${RouteName.UPM_SETTINGS}`)}
    >
      <Grid container spacing={2} mt={2} maxWidth={1600}>
        <Grid xs={12} md={5} xl={4}>
          <CardBox pt={2} pb={0} px={0}>
            <EquipmentMenu />
          </CardBox>
        </Grid>
        <Grid xs>
          <Routes>
            <Route path="plantModel" element={<Navigate to={'signals'} />} />
            <Route path="plantModel/signals">
              <Route index element={<SignalList />} />
              <Route path="new" element={<NewSignal />} />
              <Route path=":signalId" element={<EditSignal />} />
            </Route>
          </Routes>
        </Grid>
      </Grid>
    </PageLayout>
  )
}
