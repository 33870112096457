import moment from 'moment-timezone'

import {Frequency} from '@settings/modules/kpi-calculation/types'

export const getFrequencyLabel = (frequency: Frequency) => {
  switch (frequency) {
    case Frequency.Daily:
      return moment().subtract(1, 'days').endOf('day').format('L')
    case Frequency.Weekly: {
      const endOfLastWeek = moment().startOf('week').subtract(1, 'days')
      const startOfLastWeekLabel = endOfLastWeek.clone().subtract(6, 'days').format('L')
      return `${startOfLastWeekLabel} - ${endOfLastWeek.format('L')}`
    }
    case Frequency.Monthly:
      return moment().subtract(1, 'months').format('MM YYYY')
    case Frequency.Yearly:
      return moment().subtract(1, 'years').format('YYYY')
  }
}
