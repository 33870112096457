import {PermissionType} from '@hconnect/apiclient'

import {PermissionParsed} from '../types'

import {checkPermissionAccessByDataScope} from './checkPermissionAccessByDataScope'

export const checkPermission = (
  permissionType: PermissionType,
  plantCode: string,
  permissions: PermissionParsed[],
  countryIds: string[]
) => {
  if (countryIds.length === 0) {
    throw new Error(`BUG: Plant with plantCode ${plantCode} is missing in HConnect plants api`)
  }

  const foundPermissions = permissions.filter(({parsedDataScope}) =>
    countryIds.some((countryId) =>
      checkPermissionAccessByDataScope(countryId, plantCode, parsedDataScope)
    )
  )

  const foundPermissionTypes = foundPermissions.map(({permissionType}) => permissionType)

  return foundPermissionTypes.includes(permissionType)
}
