import {MaterialType} from '@hconnect/common/types'

import {useSettingsQuery} from '../../../common/providers/ApiInterface'
import {GlobalMaterial, AutocompleteOption} from '../../types'

import {useFeatureFlag} from '@settings/modules/common/hooks/useFeatureFlag'

export const useGlobalMaterialsQuery = (materialType: MaterialType) => {
  return useSettingsQuery('globalMaterials', [], {
    enabled: useFeatureFlag('materialToGlobalMaterialMappingInput'),
    select: (data: GlobalMaterial[]): AutocompleteOption[] =>
      data
        .filter((globalMaterial) => globalMaterial.type === materialType)
        .map((globalMaterial) => ({label: globalMaterial.name, id: globalMaterial.id.toString()}))
  })
}
