import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../../api/mutations'
import {settingsQueryClient} from '../../../../common/providers/QueryProvider'
import {
  getSettingsQueryData,
  invalidateSettingsQuery,
  removeSettingsQuery
} from '../../../../common/providers/ApiInterface'
import type {ErrorResponse} from '../../../../common/types'
import {notifyIfErrorMessage} from '../../../../common/utils/errorHandling'
import type {MaterialWithRecipes} from '../../../types'

export const useDeleteMaterialRecipe = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.deleteMaterialRecipe>[0]
  >(mutations.deleteMaterialRecipe, {
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSuccess: (data, variables) => {
      notify('success', t('success.deleteRecipe'))
      const prevMaterialWithRecipes = getSettingsQueryData('materialWithRecipes', [
        variables.plantCode,
        variables.materialId
      ])
      if (!prevMaterialWithRecipes) {
        throw new Error('BUG: material data should be available before deleting a recipe')
      }
      const newMaterialWithRecipe: MaterialWithRecipes = {
        ...prevMaterialWithRecipes,
        recipes: prevMaterialWithRecipes.recipes.filter(
          (recipe) => recipe.id !== variables.recipeId
        )
      }
      void settingsQueryClient.setQueryData(
        ['materialWithRecipes', variables.plantCode, variables.materialId],
        newMaterialWithRecipe
      )
      removeSettingsQuery('schedule')
      invalidateSettingsQuery('materialsRecipes')
      // assets could have deleted recipeId in operation modes
      invalidateSettingsQuery('assets')
    }
  })
}
