import moment, {Moment, MomentInput} from 'moment-timezone'

type ParsableTime = string | Date | Moment | number
interface Timespan<T extends ParsableTime = ParsableTime> {
  start: T
  end: T
}

const Iso8601Min = (d1: string, d2: string) => (d1 <= d2 ? d1 : d2)

type SplitIntoIntervals = (
  start: MomentInput,
  end: MomentInput,
  intervalLength: number
) => Timespan<string>[]

/**
 * Takes a timespan and splits it up into multiple intervals with given length.
 *
 * @returns ISO8601 formatted start/end strings
 * @param start
 * @param end
 * @param intervalLength Length of the interval in minutes.
 */
export const splitToIntervals: SplitIntoIntervals = (start, end, intervalLength) => {
  const slots = [] as Timespan<string>[]

  const current = moment.utc(start)
  const endString = moment.utc(end).toISOString()

  while (current.isBefore(end)) {
    const s = moment.utc(current).toISOString()
    current.add(intervalLength, 'minutes')
    const e = Iso8601Min(current.toISOString(), endString)
    slots.push({start: s, end: e})
  }

  return slots
}

/**
 * function returns list of timespan hours for one day
 * from 00.00:00 to '01.00:00'
 */

export const getTimeSpanListForOneDay = (interval: 15 | 30 | 60) => {
  const lastTimeSpanEntry = '01.00:00'
  const startTime = moment.utc().startOf('day')
  const endTime = moment.utc().endOf('day')
  const intervals = splitToIntervals(startTime, endTime, interval)
  const formattedTimeList = intervals.map(({start}) => moment.utc(start).format('00.HH:mm'))

  // adding last 01.00:00 entry
  const timeListFor24Hours = [...formattedTimeList, lastTimeSpanEntry]
  return timeListFor24Hours
}
