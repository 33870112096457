/**
 * @author Martin Petran
 */
import {Autocomplete, CircularProgress} from '@mui/material'
import React, {KeyboardEvent} from 'react'
import {Controller} from 'react-hook-form'
import type {Control, FieldValues, Path} from 'react-hook-form'

import {submitOnBlurAndEnterProps} from '../../../common/utils'

import {BaseTextField} from './BaseTextField'

interface ControlledAsyncAutocompleteProps<
  TOption extends {id: string; label: string},
  TField extends FieldValues
> {
  control: Control<TField>
  formDataName: Path<TField>
  options: TOption[]
  onSubmit?: () => Promise<void>
  label: string
  rules?: any
  disableClearable?: boolean
  helperText?: string
  onAddCustomValue?: (newValue: string) => void
  'data-test-id'?: string
  loading?: boolean
  stringOnly?: boolean
}

export const ControlledAsyncAutocomplete = <
  TOption extends {id: string; label: string},
  TField extends FieldValues
>({
  control,
  formDataName,
  options,
  onSubmit,
  label,
  rules,
  disableClearable = true,
  helperText,
  onAddCustomValue,
  loading,
  stringOnly,
  'data-test-id': dataTestId
}: ControlledAsyncAutocompleteProps<TOption, TField>) => (
  <Controller
    name={formDataName}
    control={control}
    rules={rules}
    render={({field: {ref, onChange, value}, fieldState: {invalid, error}}) => (
      <Autocomplete
        fullWidth
        value={value}
        disableClearable={disableClearable}
        onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
          onAddCustomValue?.((event.target as HTMLInputElement).value)
        }}
        onChange={(e, data) => {
          if (stringOnly && data && typeof data === 'object') {
            const selectedOption = data as unknown as TOption
            onChange(selectedOption.id)
          } else {
            onChange(data)
          }
        }}
        options={options}
        data-test-id={dataTestId}
        isOptionEqualToValue={(option, value) => {
          // @ts-expect-error for stringOnly value is plain string
          if (stringOnly) return option.id === value
          return option.id === value.id
        }}
        freeSolo
        {...(onSubmit && submitOnBlurAndEnterProps(onSubmit))}
        filterOptions={(x) => x}
        componentsProps={{
          paper: {
            elevation: 12,
            sx(theme) {
              return {
                background: theme.palette.grey[50]
              }
            }
          }
        }}
        sx={{
          '& .MuiInputBase-root': {
            height: '52px',
            borderRadius: '4px',
            background: '#fbfbfc',
            boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.06)',
            mb: helperText && 1,
            '&.Mui-focused': {
              background: '#fbfbfc'
            }
          }
        }}
        renderInput={(params) => (
          <BaseTextField
            {...params}
            label={label}
            inputRef={ref}
            helperText={invalid ? (error?.message ? error.message : 'Invalid value') : helperText}
            error={invalid}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
    )}
  />
)
