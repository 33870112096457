import {useQuery} from 'react-query'

import {Api} from '../../../../../api'
import {Unit} from '../../../types/signal'

export const QueryKey = 'units'

const parseToAutocompleteOptions = (data: Unit[]) =>
  data.map((unit) => ({label: unit.name, id: unit.id}))

export const useGetUnits = () => {
  return useQuery(
    [QueryKey],
    async () => {
      const path = 'upm/unit'
      return (await Api.axiosInstance.get<Unit[]>(path)).data
    },
    {
      staleTime: Infinity,
      select: parseToAutocompleteOptions
    }
  )
}
