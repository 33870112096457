import {CardBox} from '@hconnect/uikit/src/lib2'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import {useNavigate} from 'react-router'
import {useSearchParams} from 'react-router-dom'

import {UpmEntityType} from '../../../common/types'
import {useUrlParam} from '../../../routing'
import {useDeleteSignal} from '../../hooks/useDeleteSignal'
import {SignalListData} from '../../types/signal'

import {useGetSignals} from './api/useGetSignals'

const getSignalPath = (signal: SignalListData): string => {
  const {area, productionLine, department, equipment} = signal
  return `${area.name} > ${productionLine.name} > ${department.name} > ${equipment.name}`
}

export const SignalList = () => {
  const navigate = useNavigate()
  const plantCode = useUrlParam('plantCode')

  const [searchParams, setSearchParams] = useSearchParams()
  const searchTerm = searchParams.get('Term') ?? ''
  const entityId = searchParams.get('entityId')
  const entityType = searchParams.get('entityType')

  const {mutate: deleteSignal} = useDeleteSignal()
  const {
    data: signals,
    isLoading,
    isFetching
  } = useGetSignals({
    entityType: (entityType ?? 'Plant') as UpmEntityType,
    entityId: entityId ?? plantCode,
    searchTerm
  })

  return (
    <CardBox py={3} pb={0} px={0}>
      <Stack spacing={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" px={3}>
          <Typography variant="h2">
            {`${searchTerm ? 'Filtered' : 'All'} (${signals?.length ?? 0})`}
          </Typography>
          <TextField
            label="Filter"
            variant="filled"
            size="small"
            value={searchTerm}
            onChange={(e) =>
              setSearchParams((params) => {
                if (e.target.value === '') {
                  params.delete('Term')
                } else {
                  params.set('Term', e.target.value)
                }
                return params
              })
            }
          />
        </Stack>
        {isFetching && !isLoading ? <LinearProgress /> : <div style={{height: '4px'}}></div>}
        <List sx={{maxHeight: 'calc(100vh - 300px)', overflow: 'auto'}}>
          {!isLoading &&
            signals?.map((signal) => (
              <ListItem
                key={`signal-${signal.id}`}
                disablePadding
                divider
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    color="error"
                    disabled={!signal.localName?.toUpperCase()?.includes('TEST')}
                    onClick={() => deleteSignal(signal.id)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                }
              >
                <ListItemButton
                  sx={{px: 3}}
                  onClick={() =>
                    navigate(
                      {pathname: signal.id, search: searchParams.toString()},
                      {relative: 'path'}
                    )
                  }
                >
                  <ListItemText primary={signal.localName} secondary={getSignalPath(signal)} />
                </ListItemButton>
              </ListItem>
            ))}

          {isLoading && (
            <Stack sx={{px: 3, py: 2}} spacing={2}>
              {Array(10)
                .fill(0)
                .map((_, i) => (
                  <Stack
                    key={`skeleton-${i}`}
                    justifyContent="space-between"
                    alignItems="center"
                    direction="row"
                    spacing={2}
                  >
                    <Skeleton variant="rounded" height={42} width={350} />
                    <Skeleton variant="circular" height={25} width={25} />
                  </Stack>
                ))}
            </Stack>
          )}
        </List>
      </Stack>
    </CardBox>
  )
}
