import {dataTestId} from '@hconnect/uikit'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {Autocomplete, TextField, Typography, Stack, Checkbox} from '@mui/material'
import {isNil} from 'lodash'
import React, {useCallback} from 'react'

export type AutocompleteOption = {
  value: string
  label: string
}

export interface MultiAutoCompleteProps {
  options: AutocompleteOption[]
  values?: string[]
  onChange?: (values: string[]) => void | Promise<void>
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>
  onBlur?: React.FocusEventHandler<HTMLDivElement>
  error?: string
  label?: string
  placeholder?: string
  isDisabled?: boolean
  'data-test-id'?: string
}

export function MultiAutoComplete({
  options,
  values = [],
  onChange,
  onKeyDown,
  onBlur,
  error,
  label,
  placeholder,
  isDisabled = false,
  'data-test-id': testId = 'free-text-auto-complete'
}: MultiAutoCompleteProps): JSX.Element {
  const getOptionByValue = useCallback(
    (searchValue: string) => options.find(({value}) => value === searchValue),
    [options]
  )

  const getOptionLabelByValue = useCallback(
    (searchValue: string) => {
      const option = getOptionByValue(searchValue)
      if (!option) return searchValue
      return option.label
    },
    [getOptionByValue]
  )

  return (
    <Autocomplete
      multiple
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      sx={{width: (theme) => theme.spacing(50)}}
      disabled={isDisabled}
      options={options.map(({value}) => value)}
      value={values}
      isOptionEqualToValue={(optionValue, inputValue) =>
        optionValue === inputValue || inputValue === ''
      }
      onChange={(_, values) => void onChange?.(values)}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            sx={{
              '& .MuiFilledInput-root': {
                backgroundColor: 'white',
                fontWeight: 500,
                pt: 3
              }
            }}
            variant="filled"
            label={label}
            helperText={error}
            error={!isNil(error)}
            placeholder={placeholder}
            {...dataTestId(`${testId}_input`)}
          />
        )
      }}
      getOptionLabel={getOptionLabelByValue}
      renderOption={(props, optionValue, {selected}) => {
        const option = getOptionByValue(optionValue)
        if (!option) return <></>
        return (
          <li {...{...props, key: optionValue}}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{marginRight: 8}}
              checked={selected}
            />
            <Stack spacing={0.5}>
              <Typography variant="subtitle1">{option.label}</Typography>
            </Stack>
          </li>
        )
      }}
    />
  )
}
