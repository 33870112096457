export interface LimsSamplingPoint {
  // Returned from BE endpoint
  id: number
  code: string
  description?: string

  // Assigned later
  samplingPoint?: SamplingPoint
  limsSamplingPointRef: React.RefObject<{mapLimsSamplingPoint: () => Promise<void>}>
}

export interface SamplingPoint {
  id: number
  type: string
  assetId?: number
  storageId?: number
  limsSamplingPointId: number
}

export enum SamplingPointEquipmentType {
  asset = 'Asset',
  storage = 'Storage',
  other = 'Other'
}

export interface SamplingPointType {
  id: number
  name: string
  equipmentType: SamplingPointEquipmentType
}

export interface AutocompleteOption {
  id: number
  label: string
}

export type SamplingPointTypesAutocompleteOption = AutocompleteOption & {
  type: SamplingPointEquipmentType
}
