import {Status} from '../enums'

export interface DayInfo {
  date: string
  status: Status
}

export interface CalendarInfo {
  days: DayInfo[]
}
