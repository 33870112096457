import type {PermissionType} from '@hconnect/apiclient'
import {useMemo} from 'react'

import {usePlantDataFromJson} from '../../common/hooks'
import {useUrlParam} from '../../routing'
import {checkMultiplePermissions} from '../helpers/checkMultiplePermissions'
import {checkPermissionAccessByDataScope} from '../helpers/checkPermissionAccessByDataScope'

import {usePermissionsQuery} from './queries'

/*
 * generic hook to check permissions by type
 */

export const usePermission = (...types: PermissionType[]) => {
  const {data: permissions} = usePermissionsQuery(types)
  const plantCode = useUrlParam('plantCode')
  const plantData = usePlantDataFromJson()
  // Using temporary solution with plants data from Json static file until plants endpoint
  // will retrieve/fix missing plants which are duplicates.

  return useMemo(() => {
    if (!permissions || !plantData) {
      return false
    }
    return checkMultiplePermissions({
      requiredPermissionTypes: types,
      permissions,
      testFn: (permission) =>
        checkPermissionAccessByDataScope(plantData.countryId, plantCode, permission.parsedDataScope)
    })
  }, [permissions, types, plantCode, plantData])
}
