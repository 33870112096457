import {Moment} from 'moment-timezone'

export enum StandartOperationTimeRepetition {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  DAILY = 'DAILY'
}

export enum StandartOperationTimeEnds {
  on = 'on',
  after = 'after'
}

type StandartOperationTimeBaseRRule = (
  | {
      freq: StandartOperationTimeRepetition.DAILY | StandartOperationTimeRepetition.MONTHLY
    }
  | {
      freq: StandartOperationTimeRepetition.WEEKLY
      daysOfWeek: {
        [day: number]: boolean
      }
    }
) & {
  interval: number
}

export type StandartOperationTimeRRule = StandartOperationTimeBaseRRule &
  (
    | {
        ends: StandartOperationTimeEnds.after
        count: number
      }
    | {
        ends: StandartOperationTimeEnds.on
        until: Moment
      }
  )

export type StandardOperationTime = {
  start: Moment
  end: Moment
  recurrenceRule: StandartOperationTimeRRule
}
