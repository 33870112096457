import {GuidedToursProvider} from '@hconnect/uikit/src/lib2'
import React from 'react'

import {useSettingsTours} from '../../../guidedTours/settingsTours'
import {usePermission} from '../../permissions'

export const SettingsToursProvider = ({children}: {children: React.ReactNode}) => {
  const tours = useSettingsTours()
  const isIntroductionTourAllowed = usePermission(
    'VIEW_MATERIAL_STORAGE',
    'VIEW_ASSETS',
    'VIEW_MATERIALS'
  )

  const enabledTours = {introductionTour: isIntroductionTourAllowed}

  return (
    <GuidedToursProvider tours={tours} enabledTours={enabledTours}>
      {children}
    </GuidedToursProvider>
  )
}
