import {dataTestId} from '@hconnect/uikit'
import {Tab, Tabs, Typography} from '@mui/material'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../../permissions'
import {PLANT_ROUTES} from '../../../routing'
import {SelectedView} from '../../enums'
import {DailyEntry} from '../DailyEntry/DailyEntry'
import {MonthlyEntry} from '../MonthlyEntry/MonthlyEntry'
import {PlantStructure} from '../PlantStructure/PlantStructure'

export type HeaderTab = {
  title: string
  view: JSX.Element
  index: number
  id: SelectedView
  url: `${string}/${string}`
}

export const generateTabsList = (
  readConfigPermission: boolean,
  readDataPermission: boolean
): HeaderTab[] => {
  let index = 0
  return [
    ...(readConfigPermission
      ? [
          {
            id: SelectedView.plantSetup,
            index: index++,
            title: 'plantSetup',
            view: <PlantStructure />,
            url: PLANT_ROUTES.PLANT_SETUP.path
          }
        ]
      : []),
    ...(readDataPermission
      ? [
          {
            title: 'dailyEntry',
            id: SelectedView.dailyEntry,
            index: index++,
            view: <DailyEntry />,
            url: PLANT_ROUTES.ENTRY_DAILY.path
          },
          {
            id: SelectedView.monthlyEntry,
            title: 'monthlyEntry',
            index: index++,
            view: <MonthlyEntry />,
            url: PLANT_ROUTES.ENTRY_MONTHLY.path
          },
          {
            id: SelectedView.standardReports,
            title: 'standardReports',
            index: index++,
            view: <div />,
            url: PLANT_ROUTES.STANDARD_REPORTS.path
          }
        ]
      : [])
  ]
}

export const HeaderTabs = ({
  selectedValue,
  onChange
}: {
  selectedValue: HeaderTab
  onChange: (tab: HeaderTab) => void
}) => {
  const {t} = useTranslation()

  const canReadConfig = usePermission('READ_JANUS_PLANT_CONFIG')
  const canReadData = usePermission('READ_JANUS_PLANT_DATA')

  const tabs = useMemo(
    () => generateTabsList(canReadConfig, canReadData),
    [canReadConfig, canReadData]
  )

  return (
    <Tabs
      indicatorColor="secondary"
      textColor="inherit"
      variant="scrollable"
      scrollButtons="auto"
      value={selectedValue.index}
      onChange={(e, tabIndex: number) => onChange(tabs[tabIndex])}
      sx={{
        marginTop: -1,
        marginBottom: 1
      }}
      {...dataTestId('janus-page-tab-selection')}
    >
      {tabs.map((tab) => (
        <Tab
          {...dataTestId(`header-tabs-tab-${tab.title}`)}
          key={tab.title}
          label={<Typography>{t(`janusConfig.headerTab.${tab.title}`)}</Typography>}
        />
      ))}
    </Tabs>
  )
}
