import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useMemo} from 'react'
import {useErrorHandler} from 'react-error-boundary'

import {useSettingsQuery} from '../../../common/providers/ApiInterface'
import {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'
import {useUrlParam} from '../../../routing'
import {getDefaultOperationTimesDtoParser} from '../utils/standardOperationTimes'

export const useDefaultAssetOperationTimesQuery = ({
  assetsIds,
  timezone_id,
  isDisabled = assetsIds.length === 0
}: {
  assetsIds: number[]
  timezone_id: string
  isDisabled?: boolean
}) => {
  const plantCode = useUrlParam('plantCode')
  const {notify} = useNotification()
  const raiseError = useErrorHandler()

  const dtoParser = useMemo(() => getDefaultOperationTimesDtoParser({timezone_id}), [timezone_id])

  return useSettingsQuery('assetsStandardOperationTimes', [{assetsIds, plantCode}], {
    enabled: !isDisabled,
    select: dtoParser,
    onError: (error) =>
      notifyIfErrorMessage(
        (error as AxiosError<ErrorResponse>)?.response?.data.detail,
        raiseError,
        notify
      )
  })
}
