import {alpha} from '@material-ui/core'
import {Chip, useTheme, type ChipProps} from '@mui/material'

export const Pill = ({color = 'primary', ...props}: ChipProps) => {
  const {palette} = useTheme()
  const paletteColor = (palette[color]?.main as string) ?? palette.primary.main

  return (
    <Chip
      {...props}
      sx={{
        background: alpha(paletteColor, 0.08),
        color: paletteColor,
        fontWeight: 500,
        padding: 0.5,
        borderRadius: '4px',
        border: `1.5px solid ${alpha(paletteColor, 0.12)}`,
        '&&:hover': {
          background: alpha(paletteColor, 0.12)
        },
        ...props.sx
      }}
    />
  )
}
