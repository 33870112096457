import {AssetLinkType} from '@hconnect/common/types'
import type {AssetLink, NewAttachedSilo} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {Add} from '@mui/icons-material'
import {Stack, Box, Button} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../../permissions'
import {useMaterialStorageListQuery} from '../../../storages/hooks'
import {useAttachedSilosQuery} from '../../hooks'
import {useAssetData} from '../../hooks/useAssetData'

import {AttachedSiloForm} from './AttachedSiloForm'

export const AttachedSilosCard = () => {
  const {t} = useTranslation()
  const canChangeAssets = usePermission('CHANGE_ASSETS')
  const selectedAsset = useAssetData()
  const selectedAssetId = selectedAsset.id
  const {data: attachedSilosLinks} = useAttachedSilosQuery(selectedAssetId)
  const {data: materialStorage} = useMaterialStorageListQuery()

  const [newAttachedSilo, setNewAttachedSilo] = useState<NewAttachedSilo>()

  const availableSilos = materialStorage?.map(({id, name}) => ({id, name}))

  const isReadOnly = !canChangeAssets
  const availableSiloLinks = attachedSilosLinks ?? []
  const attachedSilos: (NewAttachedSilo | AssetLink)[] = [
    ...availableSiloLinks,
    ...(newAttachedSilo ? [newAttachedSilo] : [])
  ]

  return (
    <Card
      headerContent={
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <CardTitle sx={{mb: 0}}>
            {t('assetsSettings.attachedSilosTitle', {amount: attachedSilosLinks?.length ?? 0})}
          </CardTitle>
          <Button
            variant="text"
            startIcon={<Add />}
            disabled={isReadOnly || Boolean(newAttachedSilo)}
            onClick={() =>
              setNewAttachedSilo({
                to: {type: AssetLinkType.Asset, id: selectedAsset.id}
              })
            }
            {...dataTestId('add_silo')}
          >
            {t('assetsSettings.addSilo')}
          </Button>
        </Box>
      }
      {...dataTestId('attached_silos_card')}
    >
      <Stack spacing={3}>
        {availableSilos &&
          attachedSilos?.map((siloLink, index) => (
            <AttachedSiloForm
              key={siloLink.id ?? 'new'}
              index={index}
              siloLink={siloLink}
              setNewSiloLink={setNewAttachedSilo}
              availableSilos={availableSilos}
              assetId={selectedAsset.id}
              isReadOnly={isReadOnly}
            />
          ))}
      </Stack>
    </Card>
  )
}
