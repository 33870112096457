import {useNotification} from '@hconnect/uikit/src/common'
import type {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../../api/mutations'
import {invalidateSettingsQuery} from '../../../../common/providers/ApiInterface'
import type {ErrorResponse} from '../../../../common/types'
import {notifyIfErrorMessage} from '../../../../common/utils/errorHandling'

type addLimsMaterialToMaterialParams = Parameters<typeof mutations.addLimsMaterialToMaterial>[0]

export const useAddLimsMaterialToMaterial = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<void, AxiosError<ErrorResponse>, addLimsMaterialToMaterialParams>(
    mutations.addLimsMaterialToMaterial,
    {
      onSuccess: (_, variables) => {
        notify('success', t('success.addLimsMaterial'))
        invalidateSettingsQuery('materials', variables.plantCode)
        invalidateSettingsQuery('materialWithRecipes', variables.plantCode, variables.materialId)
      },
      onError: (error) => {
        notifyIfErrorMessage(error.response?.data.message, raiseError, notify)
      }
    }
  )
}
