import {GlobalMeasurementType} from '../../types/GlobalMeasurementType'

import {Api} from '@settings/api'

export const GLOBAL_MEASUREMENT_TYPES_QUERY_KEY = 'globalMeasurementTypes'

export async function getGlobalMeasurementTypes(): Promise<GlobalMeasurementType[]> {
  const path = '/plants/materials/global-measurement-types'
  const response = await Api.axiosInstance.get<GlobalMeasurementType[]>(path)
  return response.data
}
