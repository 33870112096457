import {AssetLinkType} from '@hconnect/common/types'
import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../api/mutations'
import {getSettingsQueryData, setSettingsQueryData} from '../../../common/providers/ApiInterface'
import {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'

export const useDeleteSiloLink = (assetId: number) => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.deleteAssetLink>[0]
  >(mutations.deleteAssetLink, {
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSuccess: (data, variables) => {
      notify('success', t('success.deleteSiloLink'))
      // params valid for attached silos
      const assetLinkTypes = [AssetLinkType.Asset, AssetLinkType.Silo]
      const assetLinkParams = {
        plantCode: variables.plantCode,
        assetId,
        fromTypes: assetLinkTypes,
        toTypes: assetLinkTypes
      }
      const prevAssetsLinks = getSettingsQueryData('assetsLinks', [assetLinkParams])
      if (!prevAssetsLinks) return
      setSettingsQueryData(
        'assetsLinks',
        [assetLinkParams],
        prevAssetsLinks.filter((assetLink) => assetLink.id !== variables.id)
      )
    }
  })
}
