import {RecipeComponentType} from '@hconnect/common/types'
import {useTranslation} from 'react-i18next'

import {Material, Recipe, RecipeComponent} from '../../types'
import {useSelectedMaterial} from '../SelectedMaterialProvider'

import {getAvailableMaterials} from './helpers/getAvailableMaterials'
import {RecipeComponentForm} from './RecipeComponentForm'

interface RecipeComponentListProps {
  recipeComponents: RecipeComponent[]
  materials: Material[]
  recipe: Recipe
  newRecipeComponent?: Omit<RecipeComponent, 'id'> & {id: number | undefined}
  setNewRecipeComponent?: ((component: undefined) => void) | undefined
  type: RecipeComponentType
  isReadOnly: boolean
}

export const RecipeComponentList: React.FC<RecipeComponentListProps> = ({
  recipeComponents,
  materials,
  recipe,
  type,
  isReadOnly,
  newRecipeComponent,
  setNewRecipeComponent
}) => {
  const {t} = useTranslation()

  const label =
    type === RecipeComponentType.Input
      ? t('materialsSettings.ingredient')
      : t('materialsSettings.output')

  const {selectedMaterial} = useSelectedMaterial()

  const canRemove = type !== RecipeComponentType.Output || recipeComponents.length > 1

  return (
    <>
      {recipeComponents.map((component, index) => {
        // TODO: move this logic into backend
        const isMaterialDefault =
          (selectedMaterial as Partial<Material>).id === component.material.id

        return (
          <RecipeComponentForm
            key={component.id}
            recipe={recipe}
            index={index}
            component={component}
            label={label}
            canRemove={!isMaterialDefault}
            canEditFraction={canRemove}
            canEditMaterial={!isMaterialDefault}
            availableMaterials={getAvailableMaterials(materials, recipe, component.material.id)}
            isReadOnly={isReadOnly}
          />
        )
      })}
      {newRecipeComponent && (
        <RecipeComponentForm
          key="new"
          canRemove={true}
          canEditFraction={true}
          canEditMaterial={true}
          recipe={recipe}
          index={recipeComponents.length}
          component={newRecipeComponent}
          label={label}
          availableMaterials={getAvailableMaterials(
            materials,
            recipe,
            newRecipeComponent.material.id
          )}
          setNewRecipeComponent={setNewRecipeComponent}
          isReadOnly={isReadOnly}
        />
      )}
    </>
  )
}
