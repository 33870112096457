import {Skeleton as MUISkeleton, Paper, Stack} from '@mui/material'
import React from 'react'

import {LayoutSettings} from '../LayoutSettings'

import {PageSkeleton} from './PageSkeleton'

interface SettingsSkeletonProps {
  height: string
}

export const Skeleton: React.VFC<SettingsSkeletonProps> = ({height}) => {
  return (
    <MUISkeleton
      variant="rectangular"
      component={Paper}
      sx={{
        height: height,
        backgroundColor: ({palette}) => palette.background.paper
      }}
    />
  )
}

export const DetailsSkeleton = () => {
  return (
    <Stack spacing={2}>
      <Skeleton height="10vh" />
      <Skeleton height="20vh" />
      <Skeleton height="25vh" />
    </Stack>
  )
}

export const SettingsSkeleton = () => {
  return (
    <PageSkeleton>
      <LayoutSettings
        dataTestId="settings_tab_skeleton"
        list={<Skeleton height="65vh" />}
        details={<DetailsSkeleton />}
      />
    </PageSkeleton>
  )
}
