import {PermissionType} from '@hconnect/apiclient'

import {PermissionParsed} from '../types'

interface CheckIfMultiplePermissionsGrantedParams {
  permissions: PermissionParsed[]
  requiredPermissionTypes: PermissionType[]
  testFn: (permission: PermissionParsed) => boolean
}
/**
 * funtion to check if access is granted for multiple permission types
 * permission1 && permission2
 */
export const checkMultiplePermissions = ({
  requiredPermissionTypes,
  permissions,
  testFn
}: CheckIfMultiplePermissionsGrantedParams) => {
  if (!permissions.length || !requiredPermissionTypes.length) {
    return false
  }
  const isGranted = requiredPermissionTypes
    .map((permissionType) =>
      permissions.some(
        (permission) => permission.permissionType === permissionType && testFn(permission)
      )
    )
    .every((isGranted) => isGranted)

  return isGranted
}
