import {dataTestId} from '@hconnect/uikit'
import {Box, Typography} from '@mui/material'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {WarningMessage} from '../../enums'

const WarningMessageColors: Record<WarningMessage, string> = {
  [WarningMessage.NoDataFor3Days]: 'rgba(254, 249, 242, 1)',
  [WarningMessage.FillDataReminder]: 'rgba(254, 249, 242, 1)',
  [WarningMessage.DataOverDue]: 'rgba(253, 243, 242, 1)'
}

const StatusIndicatorColor: Record<WarningMessage, string> = {
  [WarningMessage.NoDataFor3Days]: 'rgba(238, 139, 0, 1)',
  [WarningMessage.FillDataReminder]: 'rgba(238, 139, 0, 1)',
  [WarningMessage.DataOverDue]: 'rgba(218, 9, 1, 1)'
}

const Translations: Record<WarningMessage, string> = {
  [WarningMessage.NoDataFor3Days]: 'janusConfig.dailyEntry.noReportFor3Days',
  [WarningMessage.FillDataReminder]: 'janusConfig.dailyEntry.pleaseFillTheData',
  [WarningMessage.DataOverDue]: 'janusConfig.dailyEntry.dataOverdue'
}

export const WarningBlock = ({messageType}: {messageType: WarningMessage}) => {
  const {t} = useTranslation()
  const nextMonth = moment().add(1, 'days').format('DD.MM.YYYY').toString()
  const previousMonth = moment().subtract(1, 'months').format('MMMM YYYY').toString()

  return (
    <Box
      {...dataTestId(`warning-block-${messageType}`)}
      display="flex"
      flexDirection="row"
      alignItems="center"
      height="48px"
      sx={{
        paddingLeft: (theme) => theme.spacing(2),
        backgroundColor: WarningMessageColors[messageType],
        width: '100%',
        borderLeft: `4px solid ${StatusIndicatorColor[messageType]}`
      }}
    >
      <Typography variant="body1">
        {t(Translations[messageType], {nextMonth, previousMonth})}
      </Typography>
    </Box>
  )
}
