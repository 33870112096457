import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {MEASUREMENT_TYPES_MAPPINGS_QUERY_KEY} from '../../api/queries/getMeasurementTypesMappings'

import {mutations} from '@settings/api/mutations'
import {invalidateSettingsQuery} from '@settings/modules/common/providers'
import {ErrorResponse} from '@settings/modules/common/types'
import {notifyIfErrorMessage} from '@settings/modules/common/utils'

type UnmapMeasurementTypeParams = Parameters<typeof mutations.unmapMeasurementType>[0]

export function useUnmapMeasurementTypeMutation() {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<void, AxiosError<ErrorResponse>, UnmapMeasurementTypeParams>(
    mutations.unmapMeasurementType,
    {
      onSuccess: () => {
        invalidateSettingsQuery(MEASUREMENT_TYPES_MAPPINGS_QUERY_KEY)
        notify('success', t('measurementsSettings.editMappingSuccess'))
      },
      onError: (error) => {
        const errorDetails = error?.response?.data.detail ?? error.message ?? error.response?.status
        notifyIfErrorMessage(
          t('measurementsSettings.mutations.unmapMeasurementTypeError', {
            details: errorDetails
          }),
          raiseError,
          notify
        )
      }
    }
  )
}
