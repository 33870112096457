import {dataTestId} from '@hconnect/uikit'
import {Done, MoreHoriz} from '@mui/icons-material'
import {alpha, useTheme, Stack, Typography, Button} from '@mui/material'
import {FC, ReactElement} from 'react'
import {useTranslation} from 'react-i18next'

import {ProductForecastStatus} from '../../../../common/enums'
import {ProductForecastInfo} from '../../../types'

interface ForecastStatusProps {
  forecastInfo: ProductForecastInfo | undefined
  requestNewForecast: () => void
}

export const ForecastStatus: FC<ForecastStatusProps> = ({forecastInfo, requestNewForecast}) => {
  const {t} = useTranslation()
  const {palette} = useTheme()

  const forecastStatusToColorMap: Record<ProductForecastStatus, string> = {
    [ProductForecastStatus.Success]: palette.success.main,
    [ProductForecastStatus.Requested]: palette.primary.main
  }

  const forecastStatusToIconMap: Record<ProductForecastStatus, ReactElement> = {
    [ProductForecastStatus.Success]: <Done />,
    [ProductForecastStatus.Requested]: <MoreHoriz />
  }
  const currentColor = forecastInfo
    ? forecastStatusToColorMap[forecastInfo.status]
    : palette.secondary.main
  const isValidForecastAvailable = Boolean(forecastInfo)
  const shouldShowRequestButton =
    !forecastInfo || forecastInfo.status !== ProductForecastStatus.Requested

  const icon = forecastInfo ? forecastStatusToIconMap[forecastInfo.status] : null
  const text = t(
    forecastInfo
      ? `materialsSettings.salesForecastInfoByStatus.${forecastInfo.status}`
      : 'materialsSettings.salesForecastInfoByStatus.None'
  )

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        p: 1,
        borderRadius: ({spacing}) => spacing(1),
        backgroundColor: alpha(currentColor, 0.1),
        color: currentColor,
        width: 'fit-content'
      }}
      {...dataTestId('sales_forecast_status')}
    >
      {icon}
      <Typography variant="body1">{text}</Typography>
      {shouldShowRequestButton && (
        <Button
          variant="text"
          size="small"
          sx={{py: 0}}
          onClick={requestNewForecast}
          {...dataTestId('request_sales_forecast_button')}
        >
          {t(
            isValidForecastAvailable ? 'materialsSettings.requestNew' : 'materialsSettings.request'
          )}
        </Button>
      )}
    </Stack>
  )
}
