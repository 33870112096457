import {useQuery} from 'react-query'

import {Api} from '../../../../../api'
import {Equipment} from '../../../types/equipment'

import {useUrlParam} from '@settings/modules/routing'

export const QueryKey = 'equipment'

export const useGetEquipment = () => {
  const plantCode = useUrlParam('plantCode')
  return useQuery(
    [QueryKey, plantCode],
    async () => {
      const path = `upm/equipment/plant/${plantCode}?pageSize=1000`
      return (await Api.axiosInstance.get<Equipment[]>(path)).data
    },
    {
      staleTime: Infinity
    }
  )
}
