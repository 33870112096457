import {ProductType, MaterialSource, Product} from '@hconnect/common/types'
import {pickBy} from 'lodash'

const isMaterialTypeBoughtFromVendor = (materialType: string) =>
  materialType === (ProductType.MARERIAL_SAP as string)

export const getProductsPerMaterialSource = (
  data: Record<ProductType, Product[]>,
  materialSource: MaterialSource
) => {
  switch (materialSource) {
    case MaterialSource.BoughtFromVendor:
      return pickBy(data, (_v, key) => isMaterialTypeBoughtFromVendor(key)) as Record<
        ProductType,
        Product[]
      >
    case MaterialSource.ProducedInPlant:
      return pickBy(data, (_v, key) => !isMaterialTypeBoughtFromVendor(key)) as Record<
        ProductType,
        Product[]
      >
    default:
      throw new Error(`BUG: There are no products for material source ${materialSource}`)
  }
}
