import {TextField, styled} from '@mui/material'

export const CalendarInput = styled(TextField)({
  '& .MuiInputLabel-root': {
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.5)'
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translateX(-50%) scale(0.75)'
  },
  '& .MuiInputBase-root': {
    textAlign: 'center',
    backgroundColor: 'transparent'
  },
  '& .MuiInputBase-input': {
    textAlign: 'center',
    color: 'white',
    paddingBottom: '8px'
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.25)'
  },
  '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.5)'
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      legend: {
        display: 'none'
      }
    }
  }
})
