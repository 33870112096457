import {useQuery} from 'react-query'

import {Api} from '@settings/api'
import {settingsQueryClient} from '@settings/modules/common/providers'

export const QueryKey = 'signal-names'

const parseToAutocompleteOptions = (data: string[]) => data.map((type) => ({label: type, id: type}))

export const useGetSignalNames = (
  plantCode: string,
  searchTerm: string | null,
  signalSource?: string | null
) => {
  return useQuery(
    [QueryKey, signalSource, plantCode, searchTerm],
    async () => {
      const path = `upm/signal/plant/${plantCode}/local-names`
      return (await Api.axiosInstance.get<string[]>(path, {params: {signalSource, searchTerm}}))
        .data
    },
    {
      staleTime: Infinity,
      select: parseToAutocompleteOptions,
      useErrorBoundary: false,
      onError: (err) => {
        // @ts-expect-error err is unknown object so status code is not known
        if (err.statusCode === 404) {
          settingsQueryClient.setQueryData([QueryKey, signalSource, plantCode, searchTerm], [])
        }
      },
      enabled:
        signalSource !== undefined &&
        searchTerm !== null &&
        signalSource !== null &&
        searchTerm.length > 2,
      retry: 0
    }
  )
}
