import {KpiThresholdIcon} from '@hconnect/common/components/kpiPerformance/KpiThresholdIcon'
import {KpiRangeBreachSeverity} from '@hconnect/common/types'
import React, {FC, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {Thresholds, ThresholdsConfig} from '../../../common/components'

import {useEditKpiThresholds} from '@settings/modules/kpi-calculation/hooks'
import {
  ThresholdsData,
  ThresholdSign,
  ThresholdsName,
  Thresholds as ThresholdsValues
} from '@settings/modules/kpi-calculation/types'
import {useUrlParam} from '@settings/modules/routing'

type ThresholdCardProps = {
  unit: string | null
  kpiId: number
  thresholds: ThresholdsValues
}

type KpiThresholdsConfig = ThresholdsConfig<ThresholdsData, KpiRangeBreachSeverity>

export const ThresholdCard: FC<ThresholdCardProps> = ({unit, kpiId, thresholds}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {mutate: editKpiThresholds} = useEditKpiThresholds()

  const plantThresholdsConfig = useMemo<KpiThresholdsConfig[]>(
    () => [
      {
        label: t('kpiCalculationSettings.label.highHigh'),
        valueName: `thresholds.${ThresholdsName.HighHigh}.value`,
        statusName: `thresholds.${ThresholdsName.HighHigh}.severity`,
        defaultStatus: KpiRangeBreachSeverity.Alarm,
        unit,
        sign: ThresholdSign.MoreThan
      },
      {
        label: t('kpiCalculationSettings.label.high'),
        valueName: `thresholds.${ThresholdsName.High}.value`,
        statusName: `thresholds.${ThresholdsName.High}.severity`,
        defaultStatus: KpiRangeBreachSeverity.Warning,
        unit,
        sign: ThresholdSign.MoreThan
      },
      {
        label: t('kpiCalculationSettings.label.low'),
        valueName: `thresholds.${ThresholdsName.Low}.value`,
        statusName: `thresholds.${ThresholdsName.Low}.severity`,
        defaultStatus: KpiRangeBreachSeverity.Warning,
        unit,
        sign: ThresholdSign.LessThan
      },
      {
        label: t('kpiCalculationSettings.label.lowLow'),
        valueName: `thresholds.${ThresholdsName.LowLow}.value`,
        statusName: `thresholds.${ThresholdsName.LowLow}.severity`,
        defaultStatus: KpiRangeBreachSeverity.Alarm,
        unit,
        sign: ThresholdSign.LessThan
      }
    ],
    [t, unit]
  )

  const thresholdSeverityOptions = useMemo(
    () => [
      {
        value: KpiRangeBreachSeverity.Alarm,
        label: t('thresholdTable.label.alarm'),
        Icon: <KpiThresholdIcon status={KpiRangeBreachSeverity.Alarm} />
      },
      {
        value: KpiRangeBreachSeverity.Warning,
        label: t('thresholdTable.label.warning'),
        Icon: <KpiThresholdIcon status={KpiRangeBreachSeverity.Warning} />
      }
    ],
    [t]
  )

  const defaultValues: ThresholdsData = {
    thresholds: thresholds
  }

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    reset,
    formState: {isDirty, isSubmitting}
  } = useForm<ThresholdsData>({
    defaultValues,
    shouldFocusError: false
  })

  const submit = ({thresholds}: ThresholdsData) => {
    if (isDirty && !isSubmitting) {
      editKpiThresholds({thresholds: prepareThresholdsForSubmission(thresholds), kpiId, plantCode})
      reset(watch(), {keepValues: false, keepDirtyValues: false, keepErrors: false})
    }
  }

  return (
    <Thresholds
      title={t('kpiCalculationSettings.label.thresholds')}
      control={control}
      getValues={getValues}
      onSubmit={handleSubmit(submit)}
      thresholdsConfig={plantThresholdsConfig}
      setValue={setValue}
      statusOptions={thresholdSeverityOptions}
      enableThresholdsPreview
    />
  )
}

const prepareThresholdsForSubmission = (thresholds: ThresholdsValues) => {
  Object.keys(thresholds).forEach((key) => {
    const threshold = thresholds[key]
    if (threshold.value === '') {
      threshold.value = null
    }
    if (threshold.severity === '') {
      threshold.severity = null
    }
  })
  return thresholds
}
