import type {MaterialStorage} from '@hconnect/common/types'

import {useSettingsQuery} from '../../../../modules/common/providers/ApiInterface'
import {useUrlParam} from '../../../../modules/routing'

const selector = (id: number | undefined) => (data: MaterialStorage[]) => {
  // since selector runs even if query is disabled we need to handle a case when id is undefined
  if (id === undefined) {
    return undefined
  }
  const selectedMaterial = data.find((storage) => storage.id === id)
  if (!selectedMaterial) {
    throw new Error(`Bug: Storage with id ${id} doesn't exist in storage list query`)
  }
  return selectedMaterial
}

export const useMaterialStorageQuery = (id?: number) => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('materialStorage', [plantCode], {
    select: selector(id),
    enabled: id !== undefined
  })
}
