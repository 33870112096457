import {AssetLinkType} from '@hconnect/common/types'

import {useSettingsQuery} from '../../../common/providers/ApiInterface'
import {useUrlParam} from '../../../routing'

export const useAttachedSilosQuery = (assetId?: number) => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery(
    'assetsLinks',
    [
      {
        plantCode,
        // query shouldn't run with an assetId === undefined
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        assetId: assetId!,
        fromTypes: [AssetLinkType.Asset, AssetLinkType.Silo],
        toTypes: [AssetLinkType.Asset, AssetLinkType.Silo]
      }
    ],
    {
      enabled: assetId !== undefined
    }
  )
}
