import {MaterialSource, MaterialWithRecipes, Status} from '@hconnect/common/types'
import type {AssetResponse} from '@hconnect/common/types'
import {dataTestId, useMobileBreakPoint} from '@hconnect/uikit'
import {
  Apartment,
  ApartmentOutlined,
  LocalShipping,
  LocalShippingOutlined
} from '@mui/icons-material'
import {Box, TextField} from '@mui/material'
import React, {useEffect} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {MaterialApiKeys} from '../../../../api/mutations'
import {ControlledAutocomplete} from '../../../common/components'
import {SegmentedButton} from '../../../common/components/form/SegmentedButton'
import {useConfirmDialog} from '../../../common/providers'
import {requiredValidator, submitOnBlurAndEnterProps} from '../../../common/utils'
import {useUrlParam} from '../../../routing'
import {useEditMaterial, useAddMaterial, useMaterialWithRecipesQuery} from '../../hooks'
import {useDeleteGlobalMaterial} from '../../hooks/mutations/globalMaterials/useDeleteGlobalMaterial'
import {SelectedMaterial, MaterialDTO, AutocompleteOption} from '../../types'
import {useSelectedMaterial} from '../SelectedMaterialProvider'

import {MaterialChangeSourceInfo} from './MaterialChangeSourceInfo'

import {findAllOperationModes} from '@settings/modules/assets'
import {useFeatureFlag} from '@settings/modules/common/hooks/useFeatureFlag'
import {Schedule} from '@settings/modules/common/types'

const toggleMaterialSource = (source: MaterialSource) =>
  source === MaterialSource.BoughtFromVendor
    ? MaterialSource.ProducedInPlant
    : MaterialSource.BoughtFromVendor

interface MaterialFormProps {
  selectedMaterial: SelectedMaterial
  isReadOnly: boolean
  schedule: Schedule
  assets: AssetResponse[]
  globalMaterialOptions: AutocompleteOption[]
}

type MaterialFormDefaultValues = Omit<MaterialDTO, 'globalMaterialId' | 'pxTrendCounters'> & {
  globalMaterial: AutocompleteOption | null
}

export const MaterialForm: React.FC<MaterialFormProps> = ({
  selectedMaterial,
  isReadOnly,
  schedule,
  assets,
  globalMaterialOptions
}) => {
  const isMaterialToGlobalMaterialMappingInputEnabled = useFeatureFlag(
    'materialToGlobalMaterialMappingInput'
  )
  const isMobile = useMobileBreakPoint()

  const plantCode = useUrlParam('plantCode')

  const {setSelectedMaterial} = useSelectedMaterial()

  const selectedMaterialId =
    selectedMaterial.status !== Status.New ? selectedMaterial.id : undefined

  const {openDialog} = useConfirmDialog()

  const {t} = useTranslation()

  const {mutate: addMaterial} = useAddMaterial()
  const {mutate: editMaterial, isLoading: isMaterialEditing} = useEditMaterial()

  const {mutate: deleteGlobalMaterial, isLoading: isGlobalMaterialDeleting} =
    useDeleteGlobalMaterial()

  const {data: materialWithRecipes} = useMaterialWithRecipesQuery(selectedMaterialId)

  const getMaterialFormDefaultValues = () => {
    return {
      ...(({type, name, source}) => ({
        type,
        name,
        source
      }))(selectedMaterial),
      globalMaterial:
        globalMaterialOptions.find(
          (globalMaterialOption) =>
            Number(globalMaterialOption.id) === selectedMaterial.globalMaterialId
        ) ?? null
    }
  }

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: {dirtyFields}
  } = useForm<MaterialFormDefaultValues>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: getMaterialFormDefaultValues()
  })

  const currentMaterialType = watch('type')
  const currentMaterialName = watch('name')

  const shouldShowSourceChangeDialog =
    materialWithRecipes !== undefined &&
    (materialWithRecipes.products.length > 0 || materialWithRecipes.recipes.length > 0)

  const openChangeMaterialSourceDialog = ({
    material,
    onAccept,
    onReject
  }: {
    material: MaterialWithRecipes
    onAccept: () => void
    onReject: () => void
  }) => {
    const operationModes = findAllOperationModes(assets, material.recipes)
    const scheduleItems = Object.values(schedule.schedules).filter(
      (item) => operationModes.find((mode) => mode.id === item.assetOperationModeId) !== undefined
    )
    return openDialog({
      testId: 'change_source_warning_dialog',
      title: t('materialsSettings.changeMaterialSource'),
      mainAction: {
        text: t('common.ok'),
        onAction: onAccept
      },
      onCancel: onReject,
      additionalContent: materialWithRecipes && (
        <MaterialChangeSourceInfo
          material={materialWithRecipes}
          operationModes={operationModes}
          scheduleItems={scheduleItems}
        />
      )
    })
  }

  const handleChangeSource = (source: MaterialSource) => {
    if (selectedMaterial.status === Status.New) return
    const materialId = selectedMaterial.id
    const editMaterialSource = () =>
      editMaterial(
        {
          key: MaterialApiKeys.source,
          plantCode,
          materialId,
          name: currentMaterialName,
          source,
          type: currentMaterialType
        },
        {
          onError: () => reset(),
          onSuccess: () => {
            reset({...getMaterialFormDefaultValues(), source})
            setSelectedMaterial({...selectedMaterial, source})
          }
        }
      )
    if (shouldShowSourceChangeDialog) {
      // schedule and assets should be loaded to enable editing the source
      return openChangeMaterialSourceDialog({
        material: materialWithRecipes,
        onAccept: editMaterialSource,
        onReject: () => {
          // rolling source back
          reset({...getMaterialFormDefaultValues(), source: toggleMaterialSource(source)})
        }
      })
    }
    editMaterialSource()
  }

  const handleGlobalMaterialRemoval = () => {
    if (selectedMaterial.status === Status.New) return
    const materialId = selectedMaterial.id
    deleteGlobalMaterial(
      {plantCode, materialId},
      {
        onError: () => reset(),
        onSuccess: () => {
          reset({...getMaterialFormDefaultValues(), globalMaterial: null})
          setSelectedMaterial({...selectedMaterial, globalMaterialId: undefined})
        }
      }
    )
  }

  const submit = handleSubmit((params) => {
    const {name, source, type, globalMaterial} = params

    if (selectedMaterial.status === Status.New) {
      return addMaterial(
        {plantCode, name, source, type, pxTrendCounters: []},
        {
          onError: () => reset(),
          onSuccess: (createdMaterial) => setSelectedMaterial(createdMaterial)
        }
      )
    }
    const keys = Object.keys(dirtyFields).map((key) => MaterialApiKeys[key]) as MaterialApiKeys[]
    const materialId = selectedMaterial.id

    keys.forEach((key) => {
      if (key === MaterialApiKeys.source) {
        return handleChangeSource(source)
      } else if (key === MaterialApiKeys.globalMaterial && globalMaterial === null) {
        // If Global Material is unset by user --> make a DELETE request to backend
        return handleGlobalMaterialRemoval()
      }
      editMaterial(
        {
          key,
          plantCode,
          materialId,
          name,
          source,
          type,
          globalMaterialId: globalMaterial ? Number(globalMaterial.id) : undefined
        },
        {
          onError: () => reset(),
          onSuccess: () => {
            reset({...getMaterialFormDefaultValues(), name, type, source, globalMaterial})
            setSelectedMaterial({
              ...selectedMaterial,
              name,
              type,
              source,
              globalMaterialId: globalMaterial ? Number(globalMaterial.id) : undefined
            })
          }
        }
      )
    })
  })

  // since we are changing material type outside of form, we should update it manualy
  useEffect(() => {
    if (selectedMaterial.type !== currentMaterialType) {
      setValue('type', selectedMaterial.type, {})

      // When user changes Material type --> reset the Global Material field
      setValue('globalMaterial', null)
    }
  }, [selectedMaterial.type, currentMaterialType, setValue])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        p: (theme) => theme.spacing(1)
      }}
      {...dataTestId('add_edit_material_form')}
    >
      <Controller
        control={control}
        name="name"
        rules={requiredValidator(t)}
        render={({field: {ref, value, onChange}, fieldState: {error}}) => (
          <TextField
            label={t('materialsSettings.localName')}
            sx={isMobile ? {width: '100%', mb: 2} : {width: ({spacing}) => spacing(28)}}
            value={value}
            placeholder={value}
            inputRef={ref}
            onChange={onChange}
            variant="filled"
            helperText={error?.message}
            error={Boolean(error?.message)}
            disabled={isReadOnly || isMaterialEditing || isGlobalMaterialDeleting}
            {...submitOnBlurAndEnterProps(submit)}
            {...dataTestId('material_name_input')}
          />
        )}
      />
      {isMaterialToGlobalMaterialMappingInputEnabled && (
        <Box sx={isMobile ? {width: '100%', mb: 2} : {width: ({spacing}) => spacing(28), ml: 2}}>
          <ControlledAutocomplete
            control={control}
            formDataName="globalMaterial"
            options={globalMaterialOptions}
            onSubmit={submit}
            label={t('materialsSettings.globalMaterial')}
            disableClearable={false}
            disabled={isReadOnly || isMaterialEditing || isGlobalMaterialDeleting}
            sx={{
              '& .MuiInputBase-root': {
                height: '56px',
                borderRadius: '8px',
                background: '#f5f6f7',
                boxShadow: 'none'
              }
            }}
            {...dataTestId('material_global_material_input')}
          />
        </Box>
      )}
      <Controller
        control={control}
        name="source"
        rules={requiredValidator(t)}
        render={({field: {value, onChange}}) => (
          <SegmentedButton
            value={value}
            onChange={(value) => {
              onChange(value)
              handleChangeSource(value)
            }}
            isDisabled={isReadOnly || isMaterialEditing || isGlobalMaterialDeleting}
            options={[
              {
                id: 'materials-source-button',
                value: MaterialSource.ProducedInPlant,
                icon:
                  value === MaterialSource.ProducedInPlant ? <Apartment /> : <ApartmentOutlined />,
                title: t('materialsSettings.producedInPlant'),
                dataTestId: 'material_source_produced_in_plant'
              },
              {
                value: MaterialSource.BoughtFromVendor,
                icon:
                  value === MaterialSource.BoughtFromVendor ? (
                    <LocalShipping />
                  ) : (
                    <LocalShippingOutlined />
                  ),
                title: t('materialsSettings.boughtFromVendor'),
                dataTestId: 'material_source_bought_from_vendor'
              }
            ]}
            {...dataTestId('material_source_toggle_group')}
          />
        )}
      />
    </Box>
  )
}
