import {NotificationProvider} from '@hconnect/uikit/src/common'
import {ErrorBoundary} from '@hconnect/uikit/src/lib2'
import {CssBaseline} from '@mui/material'
import React, {Suspense} from 'react'
import {HelmetProvider} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {getUrl, ROUTES} from '../../routing'
import {PageSkeleton} from '../components/skeletons'
import {logger} from '../utils/logger'

import {ConfirmDialogProvider} from './ConfirmDialogProvider'
import {LoginProvider} from './LoginProvider'
import {QueryProvider} from './QueryProvider'

export const GlobalProvider = ({children}: {children: React.ReactNode}) => {
  const navigate = useNavigate()

  const {t} = useTranslation()
  const handleResetError = () => {
    navigate(getUrl(ROUTES.BASE.path, {}))
    logger.context('GlobalProvider', 'Error Boundary').info('Error Boundary Reset Click')
  }

  return (
    <Suspense fallback={<PageSkeleton />}>
      <ErrorBoundary
        apology={t('error.apology')}
        resetText={t('error.startOver')}
        onReset={handleResetError}
      >
        <CssBaseline />
        <HelmetProvider>
          <QueryProvider>
            <NotificationProvider>
              <LoginProvider>
                <ConfirmDialogProvider>{children}</ConfirmDialogProvider>
              </LoginProvider>
            </NotificationProvider>
          </QueryProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </Suspense>
  )
}
