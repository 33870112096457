import {useIsMutating} from 'react-query'

import {useSettingsQuery} from '../../../common/providers'
import {useUrlParam} from '../../../routing'

export const useMaterialWithRecipesQuery = (materialId?: number) => {
  const plantCode = useUrlParam('plantCode')
  // work around to disable fetching material details when it's already deleted
  const mutating = useIsMutating(['materials'])
  const isMutating = Boolean(mutating)
  return useSettingsQuery('materialWithRecipes', [plantCode, materialId], {
    enabled: materialId !== undefined && !isMutating
  })
}
