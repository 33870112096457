import {dataTestId} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {
  SettingsBackupRestoreOutlined as SettingsBackupRestoreOutlinedIcon,
  CheckOutlined as CheckOutlinedIcon
} from '@mui/icons-material'
import {Box, Button, Stack, Tab, Tabs, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useSelectedItem} from '../SelectedItemProvider'

enum ItemEditorTabs {
  MasterData = 'masterData',
  Layout = 'layout',
  Sensors = 'sensors',
  KPIs = 'kpis',
  OperationModes = 'operationModes',
  StandardTimes = 'standardTimes'
}

export const ItemDetails = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const {t} = useTranslation()
  const {selectedItem} = useSelectedItem()

  const handleChange = (_, newValue: number) => {
    setSelectedTab(newValue)
  }

  if (!selectedItem) {
    return null
  }

  return (
    <CardBox {...dataTestId('janus-plant-structure-details-card')}>
      <Stack spacing={3}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h4">{selectedItem.name}</Typography>
          <Box
            sx={{
              flexDirection: 'row',
              display: 'flex'
            }}
            gap={1}
          >
            <Button
              {...dataTestId('plant-structure-details-revert-button')}
              startIcon={<SettingsBackupRestoreOutlinedIcon />}
            >
              {t('janusConfig.action.revert')}
            </Button>
            <Button
              {...dataTestId('plant-structure-details-save-button')}
              variant="contained"
              startIcon={<CheckOutlinedIcon />}
            >
              {t('janusConfig.action.save')}
            </Button>
          </Box>
        </Box>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          allowScrollButtonsMobile
          variant="scrollable"
          sx={{paddingBottom: 1}}
        >
          {Object.values(ItemEditorTabs).map((tab) => (
            <Tab
              {...dataTestId(`item-editor-tab${tab}`)}
              key={tab}
              label={<Typography>{t(`janusConfig.${tab}`)}</Typography>}
            />
          ))}
        </Tabs>
        <Box>
          <Typography variant="h5">
            {t(`janusConfig.${Object.values(ItemEditorTabs)[selectedTab]}`)}
          </Typography>
        </Box>
      </Stack>
    </CardBox>
  )
}
