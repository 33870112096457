import {useSettingsQuery} from '../../../common/providers/ApiInterface'
import {useUrlParam} from '../../../routing'

export const useAssetTransitionsQuery = (assetId?: number) => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('assetsTransitions', [plantCode], {
    select: assetId !== undefined ? (allTransitions) => allTransitions[assetId] : undefined,
    enabled: assetId !== undefined
  })
}
