import {MaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {MenuItem, TextField} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useSearchParam} from '../../../routing'
import {useSelectedStorage} from '../SelectedStorageProvider'

export const StorageTypeSelect = () => {
  const [currentMaterialType, setMaterialType] = useSearchParam('materialType')
  const {setSelectedStorage} = useSelectedStorage()

  const {t} = useTranslation()
  return (
    <TextField
      variant="outlined"
      fullWidth
      select
      label={t('storagesSettings.materialType')}
      value={currentMaterialType}
      onChange={(e) => {
        const newStorageMaterialType = e.target.value as MaterialType
        setMaterialType(newStorageMaterialType)
        setSelectedStorage(undefined)
      }}
      {...dataTestId('storage_material_type_select')}
    >
      {Object.values(MaterialType).map((type) => (
        <MenuItem key={type} value={type} {...dataTestId(`${type}_select_item`)}>
          {t(`materialsSettings.materialTypes.${type}`)}
        </MenuItem>
      ))}
    </TextField>
  )
}
