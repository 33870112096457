import {AssetType} from '@hconnect/common/types'

import {useSettingsQuery} from '../../../common/providers/ApiInterface'
import {useUrlParam} from '../../../routing'

export const useMaterialsRecipes = (assetType?: AssetType) => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('materialsRecipes', [{plantCode, assetType}], {
    enabled: Boolean(assetType)
  })
}
