import {useConstraintsQuery} from './useConstraintsQuery'

export const useGetOperationModeConstraint = (operationModeId: number | undefined) => {
  const {data: constraints} = useConstraintsQuery()

  if (operationModeId === undefined) {
    return undefined
  }

  return constraints?.operation_modes[operationModeId]?.markdown
}
