import {Stack, Typography, Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import SVGImage from './images/noComments.svg'

export const EmptyCommentsList: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Stack alignItems="center" pt={3} gap={2} fontSize={'16px'} color={'#52667A'}>
      <Box>
        <img src={SVGImage} alt={t('janusConfig.dailyEntry.noComments')} />
      </Box>
      <Box>
        <Typography align="center">
          {t('janusConfig.dailyEntry.currentlyThereAreNoComments')}
        </Typography>
        <Typography align="center">{t('janusConfig.dailyEntry.startTheConversation')}</Typography>
      </Box>
    </Stack>
  )
}
