import {LayersOutlined as LayersOutlinedIcon} from '@mui/icons-material'
import {Box, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

export const EventVersion = ({version, value}: {version: string; value: string}) => {
  const {t} = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 1,
        gap: 1,
        color: '#016AD4',
        background: '#F2F8FD',
        border: '1px solid #D9E9F9',
        borderRadius: '999px',
        justifyContent: 'center',
        height: '32px',
        width: 'fit-content'
      }}
    >
      <LayersOutlinedIcon />
      <Typography>{t('janusConfig.dailyEntry.version')}</Typography>
      <Typography>{version}:</Typography>
      <Typography fontWeight="bold">{value}</Typography>
    </Box>
  )
}
