import {LimsMeasurementType} from '../../types/LimsMeasurementType'

import {Api} from '@settings/api'

export const LIMS_MEASUREMENT_TYPES_QUERY_KEY = 'limsMeasurementTypes'

export async function getLimsMeasurementTypes(plantCode: string): Promise<LimsMeasurementType[]> {
  const path = `/plants/${plantCode}/materials/lims/measurement-types`
  const response = await Api.axiosInstance.get<LimsMeasurementType[]>(path)
  return response.data
}
