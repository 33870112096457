import {dataTestId} from '@hconnect/uikit'
import {MoreVert} from '@mui/icons-material'
import {Box, BoxProps, MenuItem, IconButton} from '@mui/material'
import React, {FC} from 'react'

import {IconWithPopover} from './IconWithPopover'

export interface MenuAction {
  icon: React.ReactNode
  title?: string
  testId?: string
  onClick?: () => void
  isDisabled?: boolean
}

interface KebabMenuProps extends Pick<BoxProps, 'sx' | 'id'> {
  actions: MenuAction[]
}

export const KebabMenu: FC<KebabMenuProps> = ({actions, sx, id}) => {
  if (actions.length === 0) return null
  const firstAction = actions[0]
  return (
    <Box ml={2} sx={sx} id={id}>
      {actions.length === 1 ? (
        <IconButton
          size="small"
          sx={{ml: 2}}
          onClick={firstAction.onClick}
          disabled={firstAction.isDisabled}
          {...dataTestId(firstAction.testId ?? 'single_action')}
        >
          {firstAction.icon}
        </IconButton>
      ) : (
        <IconWithPopover
          closeOnClick
          icon={<MoreVert />}
          iconSize="small"
          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          transformOrigin={{vertical: 'top', horizontal: 'right'}}
          {...dataTestId('more_actions_button')}
        >
          {actions.map(({icon, onClick, title, testId, isDisabled}, index) => {
            return (
              <MenuItem
                key={index}
                onClick={onClick}
                {...dataTestId(testId ?? `action_${index}`)}
                disabled={isDisabled}
              >
                <Box sx={{mr: 1, display: 'flex'}}>{icon}</Box>
                {title}
              </MenuItem>
            )
          })}
        </IconWithPopover>
      )}
    </Box>
  )
}
