import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {sortBy} from 'lodash'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {UseQueryResult} from 'react-query'

import {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'
import {LIMS_MEASUREMENT_TYPES_QUERY_KEY} from '../../api/queries/getLimsMeasurementTypes'
import {LimsMeasurementType} from '../../types/LimsMeasurementType'

import {useSettingsQuery} from '@settings/modules/common/providers'
import {useUrlParam} from '@settings/modules/routing'

export function useLimsMeasurementTypesQuery(): UseQueryResult<LimsMeasurementType[]> {
  const plantCode = useUrlParam('plantCode')
  const {t} = useTranslation()
  const {notify} = useNotification()
  const raiseError = useErrorHandler()

  return useSettingsQuery(LIMS_MEASUREMENT_TYPES_QUERY_KEY, [plantCode], {
    select: (limsMeasurementTypes) => sortBy(limsMeasurementTypes, ['code']),
    onError: (error) => {
      const axiosError = error as AxiosError<ErrorResponse>
      const errorDetails =
        axiosError?.response?.data.detail ?? axiosError.message ?? axiosError.response?.status
      notifyIfErrorMessage(
        t('measurementsSettings.queryError', {
          entity: 'Lims Measurement Types',
          details: errorDetails
        }),
        raiseError,
        notify
      )
    }
  })
}
