import {GlobalMeasurementTypesCategory} from '../../types/GlobalMeasurementTypesCategory'

import {Api} from '@settings/api'

export const GLOBAL_MEASUREMENT_TYPES_CATEGORIES_QUERY_KEY = 'globalMeasurementTypesCategories'

export async function getGlobalMeasurementTypesCategories(): Promise<
  GlobalMeasurementTypesCategory[]
> {
  const path = '/plants/materials/global-measurement-types/categories'
  const response = await Api.axiosInstance.get<GlobalMeasurementTypesCategory[]>(path)
  return response.data
}
