import {dataTestId} from '@hconnect/uikit'
import {Typography, Avatar, Box, Stack} from '@mui/material'
import moment from 'moment'

import {getStringAvatar} from '../../helpers'
import {Comment} from '../../types'

export const ListComment = ({
  comment,
  onClick
}: {
  comment: Comment
  onClick: (id: string) => void
}) => {
  const {userName: user, kpiId, value, kpiName, commentDate, id} = comment

  return (
    <Stack
      onClick={() => onClick(kpiId)}
      gap={1}
      sx={{cursor: 'pointer'}}
      {...dataTestId(`kpi-comment-${id}`)}
    >
      <Box display="flex" flexDirection="row" gap={1} alignItems="center">
        <Avatar {...getStringAvatar(user)} alt={user} />
        <Typography variant="caption">{`${user} \u00b7 ${moment
          .utc(commentDate)
          .local()
          .format('YYYY-MM-DD HH:mm')}`}</Typography>
      </Box>
      <Stack gap={2} sx={{paddingLeft: (theme) => theme.spacing(4)}}>
        <Typography variant="h3">{kpiName}</Typography>
        <Typography variant="body1">{value}</Typography>
      </Stack>
    </Stack>
  )
}
