import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'

import {useSettingsQuery} from '../../../common/providers/ApiInterface'
import {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'
import {useUrlParam} from '../../../routing'

export const useDefaultOperationTimesQuery = ({
  assetsIds,
  isDisabled = assetsIds.length === 0
}: {
  assetsIds: number[]
  isDisabled?: boolean
}) => {
  const plantCode = useUrlParam('plantCode')
  const {notify} = useNotification()
  const raiseError = useErrorHandler()

  return useSettingsQuery('defaultAssetsOperationTimes', [{assetsIds, plantCode}], {
    enabled: !isDisabled,
    onError: (error) =>
      notifyIfErrorMessage(
        (error as AxiosError<ErrorResponse>)?.response?.data.detail,
        raiseError,
        notify
      )
  })
}
