import {Status} from '@hconnect/common/types'
import React, {useContext, useState, useMemo} from 'react'

type SelectedStorage = {status: Status.New} | {status: Status.Created | Status.Edited; id: number}
interface SelectedStorageState {
  selectedStorage: SelectedStorage | undefined
  setSelectedStorage: React.Dispatch<React.SetStateAction<SelectedStorage | undefined>>
}

const SelectedStorageContext = React.createContext<SelectedStorageState | undefined>(undefined)

export const useSelectedStorage = () => {
  const context = useContext(SelectedStorageContext)
  if (!context) throw new Error('Cannot use selected storage context outside of a context provider')
  return context
}

export const SelectedStorageProvider = ({children}: {children: React.ReactNode}) => {
  const [selectedStorage, setSelectedStorage] = useState<SelectedStorage>()

  const memoizedState = useMemo(() => ({selectedStorage, setSelectedStorage}), [selectedStorage])

  return (
    <SelectedStorageContext.Provider value={memoizedState}>
      {children}
    </SelectedStorageContext.Provider>
  )
}
