import {isUndefined} from 'lodash'
import {createRef} from 'react'

import {LimsSamplingPoint, SamplingPoint} from '../types'

export const getEnrichedLimsSamplingPoints = (
  limsSamplingPoints?: LimsSamplingPoint[],
  samplingPoints?: SamplingPoint[]
): LimsSamplingPoint[] => {
  if (isUndefined(samplingPoints) || isUndefined(limsSamplingPoints)) return []

  const limsSamplingPointIdToSamplingPointMapping = samplingPoints.reduce(
    (mapping, {limsSamplingPointId, ...rest}) => {
      mapping[limsSamplingPointId] = rest
      return mapping
    },
    {}
  )
  const enrichedLimsSamplingPoints = limsSamplingPoints.map((limsSamplingPoint) => {
    const limsSamplingPointMapInfo = limsSamplingPointIdToSamplingPointMapping[limsSamplingPoint.id]

    // If lims sampling point is already mapped -> assign the existing mapping, otherwise -> delete
    !isUndefined(limsSamplingPointMapInfo)
      ? (limsSamplingPoint['samplingPoint'] = limsSamplingPointMapInfo)
      : delete limsSamplingPoint['samplingPoint']

    limsSamplingPoint['limsSamplingPointRef'] = createRef()

    return limsSamplingPoint
  })
  return enrichedLimsSamplingPoints
}
