import {AssetType} from '@hconnect/common/types'
import {Stack} from '@mui/material'

import {useAssetData} from '../hooks/useAssetData'

import {AttachedSilosCard} from './attachedStorages'
import {AssetCard} from './general'
import {OperationModesCard} from './operationModes'
import {StandardOperationTimesCard} from './standardOperationTimes'
import {SwitchMatrixCard} from './switchMatrix'

export const AssetDetails = () => {
  const selectedAsset = useAssetData()

  // handling special case of having baseload in assets
  const isBaseload = selectedAsset.type === AssetType.BaseLoad
  const isCementMill = selectedAsset.type === AssetType.CementMill

  return (
    <Stack spacing={2}>
      <AssetCard />
      {!isBaseload && (
        <>
          <OperationModesCard />
          <StandardOperationTimesCard asset={selectedAsset} />
          {isCementMill && <SwitchMatrixCard asset={selectedAsset} />}
          <AttachedSilosCard />
        </>
      )}
    </Stack>
  )
}
