import type {MaterialStorage} from '@hconnect/common/types'
import {useNotification} from '@hconnect/uikit'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../api/mutations'
import {getSettingsQueryData, setSettingsQueryData} from '../../../common/providers/ApiInterface'
import type {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'

export const useAddStorage = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()
  const {addMaterialStorage} = mutations

  return useMutation<
    MaterialStorage,
    AxiosError<ErrorResponse>,
    Parameters<typeof addMaterialStorage>[0]
  >(addMaterialStorage, {
    onError: (error) => {
      const data = error.response?.data
      notifyIfErrorMessage(data?.message, raiseError, notify)
    },
    onSuccess: (newStorage, variables) => {
      notify('success', t('success.addStorage'))
      const prevStorages = getSettingsQueryData('materialStorage', [variables.plantCode])
      if (!prevStorages) {
        throw new Error('Bug: storage query should be loaded to add a new storage')
      }
      setSettingsQueryData('materialStorage', [variables.plantCode], [...prevStorages, newStorage])
    }
  })
}
