import {useSettingsQuery} from '../../common/providers/ApiInterface'
import {useUrlParam} from '../../routing'

export const useComments = (date?: string) => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('comments', [plantCode, date], {
    enabled: !!date,
    cacheTime: 0,
    staleTime: 0
  })
}
