import {useNotification} from '@hconnect/uikit'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../api/mutations'
import {getSettingsQueryData, setSettingsQueryData} from '../../../common/providers/ApiInterface'
import type {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'

export const useDeleteStorage = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()
  const {deleteMaterialStorage} = mutations

  return useMutation<void, AxiosError<ErrorResponse>, Parameters<typeof deleteMaterialStorage>[0]>(
    deleteMaterialStorage,
    {
      onError: (error) => {
        const data = error.response?.data
        notifyIfErrorMessage(data?.message, raiseError, notify)
      },
      onSuccess: (data, variables) => {
        notify('success', t('success.deleteStorage'))
        const prevStorages = getSettingsQueryData('materialStorage', [variables.plantCode])
        if (!prevStorages) {
          throw new Error('Bug: storage query should be loaded to add a new storage')
        }
        setSettingsQueryData(
          'materialStorage',
          [variables.plantCode],
          prevStorages.filter((storage) => storage.id !== variables.id)
        )
      }
    }
  )
}
