import {AssetType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Card} from '@hconnect/uikit/src/lib2'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../../permissions'
import {useAssetData} from '../../hooks/useAssetData'

import {BaseloadForm} from './BaseloadForm'
import {EditAssetForm} from './EditAssetForm'

export const AssetCard = () => {
  const {t} = useTranslation()
  const selectedAsset = useAssetData()
  const canChangeAssets = usePermission('CHANGE_ASSETS')
  if (!selectedAsset) return null

  const isBaseLoad = selectedAsset.type === AssetType.BaseLoad

  return (
    <Card
      headerContent={t(`assetsSettings.assetTypes.${selectedAsset.type}`)}
      {...dataTestId('asset_card')}
    >
      {isBaseLoad ? (
        <BaseloadForm key="baseload" baseloadAsset={selectedAsset} isReadOnly={!canChangeAssets} />
      ) : (
        <EditAssetForm asset={selectedAsset} key={selectedAsset.id} isReadOnly={!canChangeAssets} />
      )}
    </Card>
  )
}
