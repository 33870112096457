import {useQuery} from 'react-query'

import {Api} from '../../../api'
import {StructureNode} from '../types'

import {useUrlParam} from '@settings/modules/routing'

export const QueryKey = 'structure'

export const useGetPlantStructure = () => {
  const plantCode = useUrlParam('plantCode')
  return useQuery(
    [QueryKey, plantCode],
    async () => {
      const path = `upm/structure/plant/${plantCode}?includeEquipment=true`
      return (await Api.axiosInstance.get<StructureNode>(path)).data
    },
    {
      staleTime: Infinity
    }
  )
}
