import {dataTestId} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {Add} from '@mui/icons-material'
import {Box, Button, Stack} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../../../permissions'
import {useLimsMaterialsQuery} from '../../../hooks/queries/useLimsMaterialsQuery'
import {Material, LimsMaterial, NewLimsMaterial} from '../../../types'
import {getLimsMaterialOptions} from '../helpers'
import {LimsMaterialForm} from '../LimsMaterialForm/LimsMaterialForm'

interface LimsMaterialsCardProps {
  material: Material
}

export const LimsMaterialsCard: React.FC<LimsMaterialsCardProps> = ({material}) => {
  const {t} = useTranslation()
  const canChangeLimsMateialsMapping = usePermission('ASSIGN_MASTER_DATA_PROCESS')
  const [newLimsMaterial, setNewLimsMaterial] = useState<NewLimsMaterial>()
  const {data: plantLimsMaterials} = useLimsMaterialsQuery()

  const shouldLimsMaterialFormsBeDisplayed = !!(
    plantLimsMaterials && plantLimsMaterials.length !== 0
  )

  const cardTitle = shouldLimsMaterialFormsBeDisplayed
    ? `${t('materialsSettings.limsMaterials')} (${material.limsMaterials.length})`
    : t('materialsSettings.limsMaterials')

  const isAddLimsCodeButtonEnabled =
    shouldLimsMaterialFormsBeDisplayed && canChangeLimsMateialsMapping && !newLimsMaterial

  const existingLimsMaterials: (LimsMaterial | NewLimsMaterial)[] = [
    ...material.limsMaterials,
    ...(newLimsMaterial ? [newLimsMaterial] : [])
  ]
  return (
    <Card
      headerContent={
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <CardTitle sx={{mb: 0}}> {cardTitle} </CardTitle>
          <Button
            variant="text"
            startIcon={<Add />}
            disabled={!isAddLimsCodeButtonEnabled}
            onClick={() => {
              setNewLimsMaterial({code: '', name: ''})
            }}
            {...dataTestId('lims_materials_add_button')}
          >
            {t('materialsSettings.addCode')}
          </Button>
        </Box>
      }
      {...dataTestId('lims_materials_card')}
    >
      {shouldLimsMaterialFormsBeDisplayed && (
        <Stack spacing={3}>
          {existingLimsMaterials.map((existingLimsMaterial, index) => (
            <LimsMaterialForm
              key={existingLimsMaterial.id ?? 'new'}
              index={index}
              limsMaterial={existingLimsMaterial}
              setNewLimsMaterial={setNewLimsMaterial}
              limsMaterialOptions={getLimsMaterialOptions(
                plantLimsMaterials,
                material.limsMaterials,
                existingLimsMaterial
              )}
              material={material}
            />
          ))}
        </Stack>
      )}
    </Card>
  )
}
