import type {MaterialStorage} from '@hconnect/common/types'
import {useNotification} from '@hconnect/uikit'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../api/mutations'
import {getSettingsQueryData, setSettingsQueryData} from '../../../common/providers/ApiInterface'
import type {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'

export const useEditStorage = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()
  const {editMaterialStorage} = mutations

  return useMutation<
    MaterialStorage,
    AxiosError<ErrorResponse>,
    Parameters<typeof editMaterialStorage>[0]
  >(editMaterialStorage, {
    onError: (error) => {
      const data = error.response?.data
      notifyIfErrorMessage(data?.message, raiseError, notify)
    },
    onSuccess: (editedStorage, variables) => {
      notify('success', t('success.editStorage'))
      const prevStorages = getSettingsQueryData('materialStorage', [variables.plantCode])
      if (!prevStorages) {
        throw new Error('Bug: storage query should be loaded to add a new storage')
      }
      const prevStorage = prevStorages.find((storage) => storage.id === variables.id)
      if (!prevStorage) {
        throw new Error('Bug: storage should be available in query before editing')
      }
      const newStorages = prevStorages.map((storage) =>
        storage.id === variables.id ? editedStorage : storage
      )
      setSettingsQueryData('materialStorage', [variables.plantCode], newStorages)
    }
  })
}
