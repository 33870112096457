import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../api/mutations'
import {settingsQueryClient} from '../../../../modules/common/providers/QueryProvider'
import {
  getSettingsQueryData,
  invalidateSettingsQuery,
  removeSettingsQuery
} from '../../../../modules/common/providers/ApiInterface'
import {notifyIfErrorMessage} from '../../../../modules/common/utils/errorHandling'
import type {ErrorResponse} from '../../../common/types'

export const useDeleteMaterial = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.deleteMaterial>[0]
  >(mutations.deleteMaterial, {
    mutationKey: 'materials',
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSuccess: (data, variables) => {
      notify('success', t('success.deleteMaterial'))
      const prevMaterials = getSettingsQueryData('materials', [variables.plantCode])
      if (prevMaterials) {
        void settingsQueryClient.setQueryData(
          ['materials', variables.plantCode],
          prevMaterials.filter((material) => material.id !== variables.materialId)
        )
      }
      // invalidating all materials with recipes, because we need remove the material from all recipe ingredients
      invalidateSettingsQuery('materialWithRecipes')
      invalidateSettingsQuery('materialsRecipes')
      // assets could have attached recipe which belong to deleted material
      invalidateSettingsQuery('assets')
      removeSettingsQuery('schedule')
    }
  })
}
