import {dataTestId, useBreakPoints} from '@hconnect/uikit'
import {Box} from '@mui/material'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'

import {PageLayout} from '../../common/components'
import {usePermission} from '../../permissions'
import {RouteName, getUrl, useUrlParam} from '../../routing'
import {SelectedView} from '../enums'

import {HeaderTab, HeaderTabs, generateTabsList} from './Header/HeaderTabs'
import {SelectedItemProvider} from './SelectedItemProvider'

export const PageJanusConfig = ({selectedView}: {selectedView?: SelectedView}) => {
  const canReadConfig = usePermission('READ_JANUS_PLANT_CONFIG')
  const canReadData = usePermission('READ_JANUS_PLANT_DATA')

  const tabs = useMemo(
    () => generateTabsList(canReadConfig, canReadData),
    [canReadConfig, canReadData]
  )

  const [selectedValue, setSelectedValue] = useState<HeaderTab>(tabs[0])
  const {t} = useTranslation()
  const navigate = useNavigate()

  const plantCode = useUrlParam('plantCode')

  const screenSizes = useBreakPoints()
  const isMobileOrTablet = ['xs', 'sm', 'md'].includes(screenSizes)

  useEffect(() => {
    const selectedTab = tabs.find((tab) => tab.id === selectedView?.valueOf())
    if (!selectedTab) {
      navigate(getUrl(tabs[0].url, {plantCode}))
    }

    setSelectedValue(selectedTab ?? tabs[0])
  }, [navigate, plantCode, selectedView, tabs])

  return (
    <PageLayout
      title={
        <Box
          display="flex"
          flexDirection={isMobileOrTablet ? 'column' : 'row'}
          gap={2}
          alignItems="start"
        >
          {t('janusConfig.pageTitle')}
          <HeaderTabs
            selectedValue={selectedValue}
            onChange={(selected) => {
              setSelectedValue(selected)
              navigate(getUrl(selected.url, {plantCode}))
            }}
          />
        </Box>
      }
      nameHeaderSx={{pb: 2}}
      {...dataTestId(`page_${RouteName.JANUS_CONFIG}`)}
    >
      <SelectedItemProvider>{selectedValue.view}</SelectedItemProvider>
    </PageLayout>
  )
}
