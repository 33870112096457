import {ContentCopy} from '@mui/icons-material'
import {IconButton, InputAdornment, TextField, Tooltip} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

const CopyableTextField = ({label, value}: {label: string; value: string}) => {
  const [openTooltip, setOpenTooltip] = useState(false)

  const handleCopy = () => {
    void navigator.clipboard.writeText(value).then(() => {
      setOpenTooltip(true) // Show the tooltip
      setTimeout(() => {
        setOpenTooltip(false) // Hide the tooltip after 3 seconds
      }, 3000)
    })
  }
  const {t} = useTranslation()

  return (
    <TextField
      label={label}
      variant="outlined"
      value={value}
      sx={{
        '& .MuiOutlinedInput-root': {pointerEvents: 'none', cursor: 'default'},
        '& .MuiInputAdornment-root': {
          cursor: 'pointer',
          pointerEvents: 'auto' // Make the adornment clickable
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip
              title={t('success.copySuccess')}
              open={openTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="top"
            >
              <IconButton onClick={handleCopy}>
                <ContentCopy />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
        readOnly: true
      }}
      onClick={(e) => e.preventDefault()}
    />
  )
}

export {CopyableTextField}
