/**
 * @author Martin Petran
 */
import {TextField, TextFieldProps} from '@mui/material'

export const BaseTextField = (props: TextFieldProps) => (
  <TextField
    variant="filled"
    size="small"
    fullWidth
    {...props}
    SelectProps={{
      sx: {
        display: 'flex',
        background: '#fbfbfc',
        borderRadius: '4px',
        boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.06)',
        '&.Mui-focused': {
          background: '#fbfbfc'
        }
      },
      ...props.SelectProps
    }}
    InputProps={{
      sx: {
        display: 'flex',
        background: '#fbfbfc',
        borderRadius: '4px',
        boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.06)',
        '&.Mui-focused': {
          background: '#fbfbfc'
        }
      },
      ...props.InputProps
    }}
  />
)
