import {dataTestId} from '@hconnect/uikit'
import {Add, KeyboardArrowDown} from '@material-ui/icons'
import {Button, Menu, MenuItem} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {RecipeComponentType} from '@settings/modules/common/enums'

interface RecipeComponentAddDropdownProps {
  disabled: boolean
  componentType: RecipeComponentType | undefined
  onAddOutput: () => void
  onAddIngredient: () => void
}

export const RecipeComponentAddDropdown: React.FC<RecipeComponentAddDropdownProps> = ({
  disabled,
  onAddIngredient,
  onAddOutput,
  componentType
}) => {
  const {t} = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        sx={{ml: 2.5}}
        variant="text"
        startIcon={<Add />}
        endIcon={<KeyboardArrowDown />}
        disabled={disabled}
        onClick={handleClick}
        {...dataTestId('add_recipe_component_button')}
      >
        {t('common.add')}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            onAddIngredient()
          }}
          disabled={componentType === RecipeComponentType.Output}
          disableRipple
          {...dataTestId('add_ingredient_button')}
        >
          {t('materialsSettings.ingredient')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            onAddOutput()
          }}
          disabled={componentType === RecipeComponentType.Input}
          disableRipple
          {...dataTestId('add_output_button')}
        >
          {t('materialsSettings.output')}
        </MenuItem>
      </Menu>
    </div>
  )
}
