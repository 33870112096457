import {isUndefined} from 'lodash'

import {LimsMeasurementType} from '../types/LimsMeasurementType'
import {MeasurementTypesMapping} from '../types/MeasurementTypesMapping'

export function getUnmappedLimsMeasurementTypes(
  limsMeasurementTypes?: LimsMeasurementType[],
  measurementTypesMappings?: MeasurementTypesMapping[]
) {
  if (isUndefined(limsMeasurementTypes) || isUndefined(measurementTypesMappings)) return []
  const mappedLimsMeasurementTypesIds = measurementTypesMappings.map(
    (mapping) => mapping.limsMeasurementTypeId
  )
  return limsMeasurementTypes.filter(
    (limsType) => !mappedLimsMeasurementTypesIds.includes(limsType.id)
  )
}
