import {appUrl} from '@hconnect/common/hproduce/config'
import {MAINTAIN_HOST} from '@hconnect/common/hproduce/config/maintain'
import {CardBox, Loader} from '@hconnect/uikit/src/lib2'
import {LoadingButton} from '@mui/lab'
import {Stack, Typography, Alert, Button, Link} from '@mui/material'
import {useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'

import {ControlledTextField, PageLayout} from '../../../common/components'
import {useUrlParam} from '../../../routing'
import {AssetList} from '../AssetList/AssetList'

import {KilnConfigDto} from './AssetConfig.types'
import {useCreateKilnConfig} from './useCreateKilnConfig'
import {useGetAssetConfig} from './useGetAssetConfig'
import {useUpdateKilnConfig} from './useUpdateKilnConfig'

export const PageAssetConfig = () => {
  const [searchParams] = useSearchParams()
  const selectedAssetId = searchParams.get('id')
  const {t} = useTranslation()

  return (
    <PageLayout title={t('assetConfig.pageTitle')} nameHeaderSx={{pb: 2}}>
      <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
        <AssetList />
        {selectedAssetId && <AssetDetails assetId={selectedAssetId} />}
      </Stack>
    </PageLayout>
  )
}

const AssetDetails = ({assetId}: {assetId: string}) => {
  const {data: kilnConfig, isLoading, error} = useGetAssetConfig(assetId)
  const {mutateAsync: createKilnConfig} = useCreateKilnConfig()
  const {mutateAsync: updateKilnConfig} = useUpdateKilnConfig()

  const configNotCreated = !isLoading && (error?.response?.status === 404 || !kilnConfig)

  const plantCode = useUrlParam('plantCode')

  const initialKilnConfig: KilnConfigDto = {
    meta: {
      plantId: plantCode,
      type: 'Department',
      typeId: assetId
    },
    layout: {},
    configuration: {
      name: '',
      dimensions: {
        length: '',
        diameter: ''
      },
      isRotationClockwise: true,
      feedSignals: [],
      speedSignals: []
    },
    signalMappings: null
  }

  const defaultValues = useMemo(
    () => ({
      name: kilnConfig?.configuration?.name ?? '',
      kilnLength: kilnConfig?.configuration?.dimensions?.length ?? '',
      kilnDiameter: kilnConfig?.configuration?.dimensions?.diameter ?? '',
      bestDemonstratedProduction: ''
    }),
    [kilnConfig]
  )

  const layoutEmpty = Object.keys(kilnConfig?.layout ?? {}).length === 0

  return (
    <CardBox display="flex" flexDirection="column" gap={3} flexGrow={1} maxWidth="1200px">
      <Typography variant="h3">Kiln</Typography>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Alert
            elevation={4}
            severity={layoutEmpty ? 'error' : 'info'}
            sx={{
              borderRadius: '4px'
            }}
            action={
              <Button
                variant="contained"
                color="primary"
                size="small"
                component={Link}
                href={`${appUrl(MAINTAIN_HOST)}/plant/${plantCode}/kms/${assetId}/config`}
              >
                Setup kiln
              </Button>
            }
          >
            <Typography variant="body1">
              {layoutEmpty
                ? 'You need to provide your kiln layout in order to be able to monitor this asset.'
                : 'This asset has a kiln layout configured. You can update it anytime.'}
            </Typography>
          </Alert>
          <AssetDetailsForm
            defaultValues={defaultValues}
            // situation when kilnConfig is not yet created
            kilnConfig={kilnConfig || initialKilnConfig}
            onSubmit={configNotCreated ? createKilnConfig : updateKilnConfig}
          />
        </>
      )}
    </CardBox>
  )
}

const AssetDetailsForm = ({
  defaultValues,
  kilnConfig,
  onSubmit
}: {
  defaultValues: {
    name: string
    kilnLength: string | number | null
    kilnDiameter: string | number | null
    bestDemonstratedProduction: string
  }
  kilnConfig: KilnConfigDto
  onSubmit: (data: KilnConfigDto) => Promise<KilnConfigDto>
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: {isDirty, isSubmitSuccessful, isSubmitting}
  } = useForm({
    defaultValues,
    shouldFocusError: false
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues)
    }
  }, [isSubmitSuccessful, defaultValues, reset])

  const submit = async (data) => {
    if (!isDirty) return
    const newKilnConfig = structuredClone(kilnConfig)
    newKilnConfig.configuration.name = data.name
    newKilnConfig.configuration.dimensions.length = data.kilnLength || null
    newKilnConfig.configuration.dimensions.diameter = data.kilnDiameter || null
    await onSubmit(newKilnConfig)
  }

  return (
    <Stack maxWidth="400px" spacing={2} component="form" onSubmit={handleSubmit(submit)}>
      <ControlledTextField formDataName="name" label="Asset name" control={control} />
      <Stack direction="row" spacing={2}>
        <ControlledTextField
          formDataName="kilnLength"
          label="Kiln length (m)"
          TextFieldProps={{type: 'number'}}
          control={control}
        />
        <ControlledTextField
          formDataName="kilnDiameter"
          label="Kiln diameter (m)"
          TextFieldProps={{type: 'number'}}
          control={control}
        />
      </Stack>
      <LoadingButton
        variant="contained"
        type="submit"
        loading={isSubmitting}
        disabled={isSubmitting || !isDirty}
      >
        <span>Save</span>
      </LoadingButton>
      {/* Not implemented on BE yet */}
      {/* <ControlledTextField
        label="Best demonstrated production"
        formDataName="bestDemonstratedProduction"
        TextFieldProps={{type: 'number'}}
        control={control}
        onSubmit={handleSubmit(submit)}
      /> */}
    </Stack>
  )
}
