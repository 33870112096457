import type {KpiRangeBreachSeverity} from '@hconnect/common/types'

export enum Frequency {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Yearly = 'yearly'
}

export enum ThresholdsName {
  HighHigh = 'highHigh',
  High = 'high',
  Low = 'low',
  LowLow = 'lowLow'
}

type KpiThreshold = {
  value: number
  severity?: KpiRangeBreachSeverity
}

export type Thresholds = Record<ThresholdsName, KpiThreshold>

export type Kpi = Thresholds & {
  kpiDefinitionId: number
  formula: string | null
  tagname: string
  displayName: string
  unit: string | null
  frequency: Frequency
  plantEntryPointVisibility: boolean
}

export type ThresholdsData = {
  thresholds: Thresholds
}

export type KpiWithValue = {
  definition: Kpi
  lastResult?: LastResult
}

export type ProcessStage = {
  stageName: string
  stageCode: string
  definitionsWithResults: KpiWithValue[]
}

export type KpiResponse = {
  processStages: ProcessStage[]
}

export type SensorsResponse = {
  plantSensors: Sensor[]
}

export type Sensor = {
  sensorName: string
  description: string
}

export type FunctionsResponse = {
  functionDefinitions: FunctionDefinition[]
}

export type LastResult = {
  value: number | string
  startTime: string
  endTime: string
}

export type LastResultsResponse = {
  results: LastResult[]
}

type Argument = {
  name: string
  description: string
  type: string
  data: string
  isMandatory: boolean
  argumentOrder: number
}

export type FunctionDefinition = {
  name: string
  description: string
  arguments: Argument[]
  maxArguments: number
}

export type UnitsResponse = string[]

export enum ThresholdSign {
  LessThan = 'lessThan',
  MoreThan = 'moreThan'
}

export type Threshold = {
  name: string
  value: number
  severity: KpiRangeBreachSeverity
  sign?: ThresholdSign
  unit?: string
}

export enum ThresholdPreviewType {
  LessThan = 'lessThan',
  MoreThan = 'moreThan',
  Between = 'between'
}

export type ThresholdPreview = {
  severity: KpiRangeBreachSeverity
  unit?: string
} & (
  | {
      type: ThresholdPreviewType.LessThan | ThresholdPreviewType.MoreThan
      value: number
    }
  | {
      type: ThresholdPreviewType.Between
      from: number
      to: number
    }
)
