import {NavigateNext} from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Slide,
  Stack,
  Breadcrumbs,
  Link
} from '@mui/material'
import {useRef, useMemo, Fragment} from 'react'
import {useNavigate} from 'react-router'
import {useSearchParams} from 'react-router-dom'

import {findEntityPath} from '../../../common/helpers'
import {useGetPlantStructure} from '../../../common/hooks/useGetPlantStructure'
import {UpmEntityType} from '../../../common/types'
import {useUrlParam} from '../../../routing'

export const EquipmentMenu = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const plantCode = useUrlParam('plantCode')

  const {data: plantStructure, isLoading} = useGetPlantStructure()
  const containerRef = useRef(null)

  const selectedEntityId = searchParams.get('entityId') ?? plantCode
  const selectedEntityType = (searchParams.get('entityType') ?? 'Plant') as UpmEntityType

  const {node: selectedEntity, path: entityPath} = useMemo(() => {
    if (!plantStructure || !selectedEntityId) return {node: null, path: []}
    return findEntityPath(plantStructure, selectedEntityId, selectedEntityType)
  }, [plantStructure, selectedEntityId, selectedEntityType])

  return (
    <Stack ref={containerRef} sx={{overflow: 'hidden'}}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{px: 3}}>
        <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
          {entityPath.map((entity, index) => {
            const isLast = index === entityPath.length - 1
            return (
              <Link
                key={entity.id}
                variant="body2"
                fontWeight={isLast ? 'bold' : 'regular'}
                underline={isLast ? 'none' : 'hover'}
                color="inherit"
                onClick={() => {
                  if (isLast) return
                  setSearchParams((params) => {
                    params.set('entityType', entity.entityType)
                    params.set('entityId', entity.id)
                    return params
                  })
                }}
              >
                {entity.name} {isLast ? `(${entity.signalCount ?? 0})` : null}
              </Link>
            )
          })}
        </Breadcrumbs>
        <IconButton
          data-test-id={'upm-add-sensor-button'}
          color="primary"
          onClick={() =>
            navigate({pathname: 'plantModel/signals/new', search: searchParams.toString()})
          }
        >
          <AddIcon />
        </IconButton>
      </Stack>
      <List component="nav" sx={{'& hr:last-of-type': {display: 'none'}}}>
        <Divider variant="middle" />
        {isLoading && <Skeleton variant="rounded" sx={{height: '30px', mx: 2, mb: 2}} />}
        {!isLoading &&
          selectedEntity?.children?.map((subEntity) => {
            return (
              <Fragment key={subEntity.id}>
                <Slide direction="left" in container={containerRef.current}>
                  <ListItemButton
                    key={subEntity.id}
                    sx={{'& .MuiListItemIcon-root': {justifyContent: 'flex-end'}}}
                    onClick={() =>
                      setSearchParams((params) => {
                        params.set('entityType', subEntity.entityType)
                        params.set('entityId', subEntity.id)
                        return params
                      })
                    }
                  >
                    <ListItemText
                      primary={`${subEntity.name} (${subEntity.signalCount ?? 0})`}
                      primaryTypographyProps={{variant: 'body1'}}
                    />
                    <ListItemIcon>
                      <ChevronRightIcon />
                    </ListItemIcon>
                  </ListItemButton>
                </Slide>
                <Divider variant="middle" />
              </Fragment>
            )
          })}
      </List>
    </Stack>
  )
}
